import React from 'react';
import PropTypes from 'prop-types';
import { ApAddon, ApInput, ApInputStack } from 'common/ApInput/ApInput';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import { tr } from 'services/Helpers/Helpers';
import ApSelect from 'common/ApSelect/ApSelect';

const InstallationRow = (props) => {
    const renderProjectStorageChecker = () => {
        if (props.installation.type.includes('expenses')) return null;

        if (!props.projectSettings.project_storage_enabled) {
            return null
        }

        const balanceFree = !isNaN(props.installation.component_stock?.balance)
            ? parseFloat(props.installation.component_stock?.balance) - (parseFloat(props.installation.count) || 0)
            : 0;

        if (!props.projectSettings.project_storage_check_balance_strict) {
            return (
                <div>
                    <div>{tr('project_storage')}</div>
                    <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        {tr('allocated_quantity')}: {props.installation.alloc_count ? props.installation.alloc_count : "-"} {props.installation.unit} <br />
                        {tr('available')}: {balanceFree} {props.installation.unit} / {tr('project_storage_balance')}: {props.installation.component_stock?.balance} {props.installation.unit}
                    </div>
                    <hr />
                </div>
            )
        }

        return (
            <div>
                <div>
                    <div>{tr('project_storage')}</div>
                    <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        {tr('available')}: {balanceFree} {props.installation.unit} / {tr('project_storage_balance')}: {props.installation.component_stock?.balance} {props.installation.unit}
                        {balanceFree < 0 ?
                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" fill='red' />
                                {tr('project_storage_note')}
                            </div> : null}
                    </div>
                </div>
                <hr />
            </div>
        )

    }

    const renderFromStorageInput = () => {
        return (
            <ApSelect
                label={tr('search_storage_component')}
                value={props.installation.selectedValue}
                optionRenderer="component_detail"
                onChange={(e) => {
                    props.editRow('selectedValue', e);
                }}
                objKeyId="id"
                objKeyValue="name"
                apiUrl="storage/components"
                apiData={{
                    instanceOptions: {},
                    include: [
                        // 'locations', // TODO: add back if needed
                        'identifiers',
                        // 'trackingunits'
                    ],
                    exludeIds: Array.isArray(props.excludeIds) ? props.excludeIds : [],
                    formatter: 'instance',
                    type: props.installation.type?.includes('components') ? 'item' : 'other',
                }}
            />
        )
    }

    const renderInstallation = () => {
        return <>
            {props.fromStorage && renderFromStorageInput()}
            <ApInputStack gap="0" timetrackingMobileResponsive={true}>
                {!props.fromStorage && (
                    <ApInput
                        gapRight="10"
                        type="select"
                        id={"id" + props.index}
                        name={"id" + props.index}
                        value={props.installation.id}
                        options={props.installationOptions}
                        onChange={(e) => props.changeRow(e.target.value)}
                    />
                )}

                <ApInput
                    width={props.installation.unit ? 100 : 150}
                    type="number"
                    id={"value" + props.index}
                    name={"value" + props.index}
                    value={props.installation.count}
                    align="right"
                    onChange={(e) => props.editRow('count', e.target.value)}
                    tooltip={tr('amount_of_progress')}
                    validationState={!parseFloat(props.installation.count) ? 'error' : ''}
                    placeholder={tr('installed')}
                />
                {props.installation.unit &&
                    <ApAddon noLeftBorder width="50">
                        {props.installation.unit}
                    </ApAddon>
                }

                <ApInput
                    gapLeft="10"
                    width={75}
                    type="number"
                    id={"time" + props.index}
                    name={"time" + props.index}
                    value={props.installation.time || ''}
                    placeholder={props.installation.automaticTime || ''}
                    className={"installationTime " + (props.installation.time ? "fixed" : "auto")}
                    align="right"
                    onChange={(e) => props.editRow('time', e.target.value)}
                    tooltip={tr('time_spent_in_minutes')}
                />
                <ApAddon noLeftBorder width="50">min</ApAddon>

                <ApAddon custom width="50">
                    <SvgIcon
                        className="removeRow"
                        icon="times-circle"
                        type="solid"
                        onClick={props.removeRow}
                    />
                </ApAddon>
            </ApInputStack>
            {renderProjectStorageChecker()}
        </>
    }

    return <>
        {renderInstallation()}
    </>;
}

InstallationRow.propTypes = {
    installation: PropTypes.object,
    fromStorage: PropTypes.bool,
    projectSettings: PropTypes.object,
    installationOptions: PropTypes.array,
    excludeIds: PropTypes.array,
    index: PropTypes.number,
    editRow: PropTypes.func,
    changeRow: PropTypes.func,
    removeRow: PropTypes.func,
    selectedValue: PropTypes.object,
}

export default InstallationRow;