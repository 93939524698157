import { tr } from "services/Helpers/Helpers";

export const READ_ONLY_TYPES = ['holiday', 'assignment', 'crm_event', 'time_attendance_save_point'];
export const PRIVATE_ICON = 'user-lock';
export const DEFAULT_COLOR = '#0099cc'
export const BACKGROUND_SHIFT_PERCENT = 15;

export const defaultEvent = {
	title: '',
	start: '',
	end: '',
	description: '',
	all_day: false,
	color: DEFAULT_COLOR,
	type: '',
	company_event_type_id: null,
	is_private: false,
	project: null,
	project_work_id: null,
	entry_type: 'hour',
	distance: null,
	route: null,
	vehicle_id: null,
	frequency: null,
	recurrence_start: null,
	recurrence_end: null,
	interval: 1,
	days: [],
	installations_components: [],
	installations_components_storage: [],
	installations_expenses: [],
	installations_expenses_storage: [],
}

export const entryTypeOptions = [
	{ label: tr('work'), value: 'hour' },
	{ label: tr('travel'), value: 'travel' },
];

export const recurrenceOptions = [
	{ label: tr('no_recurrence'), value: '' },
	{ label: tr('daily'), value: 'daily' },
	{ label: tr('weekly'), value: 'weekly' },
	{ label: tr('monthly'), value: 'monthly' },
]


/**
 * Validates the entry data for errors. Returns an array of error messages.
 *
 * @param {Object} entryData - The data of the entry to be validated.
 * @returns {Array} - An array of error messages. 
 */
export function getEventDataErrors(entryData) {
	const errors = [];

	if (!entryData.title) {
		errors.push(tr('title_required'));
	}
	if (!entryData.start) {
		errors.push(tr('start_date_mandatory'));
	}
	if (!entryData.end) {
		errors.push(tr('end_date_mandatory'));
	}
	if (entryData.start && entryData.end && new Date(entryData.start) > new Date(entryData.end)) {
		errors.push(tr('start_date_after_end_date_error'));
	}

	// Recurring event validation
	if (entryData.frequency) {
		if (!entryData.recurrence_start) {
			errors.push(tr('recurrence_start_date_mandatory'));
		}
		if (!entryData.recurrence_end) {
			errors.push(tr('recurrence_end_date_mandatory'));
		}
		if (entryData.recurrence_start && entryData.recurrence_end && new Date(entryData.recurrence_start) > new Date(entryData.recurrence_end)) {
			errors.push(tr('start_date_after_end_date_error'));
		}
	}

	return errors;
}