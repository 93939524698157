import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import './NotificationSettings.css';

import { errorPopper, tr } from 'services/Helpers/Helpers.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';

import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import ApButton from 'common/ApButton/ApButton.js';
import { matchSorter } from 'match-sorter';
import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import AddNotificationRecipient from './AddNotificationRecipient';
import { ApAddon, ApInputStack, ApInput } from 'common/ApInput/ApInput'
import ApSwitch from 'common/ApSwitch/ApSwitch';


class NotificationSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            settings: [],
            showNewRecipientModal: false,
            columns: [],
            users: [],
            unsavedChanges: false,
        };

        autoBind(this);
    }

    componentDidMount() {
        this.getColumns();
        this.getUsers();
        this.getNotificationSettings();
    }

    getUsers() {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'crm/settings/getCrmNotificationRecipients',
        }).then((response) => {
            this.setState({
                users: response.users,
                loading: false,
            });
        }).catch((error) => {
            errorPopper(error, tr('get_error'));
            this.setState({ loading: false });
        });
    }

    getNotificationSettings() {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'company/settings/getNotificationSettings',
        }).then((response) => {
            this.setState({
                settings: response,
                loading: false,
            });
        }).catch((error) => {
            errorPopper(error, tr('get_error'));
            this.setState({ loading: false });
        });
    }


    saveSettings() {
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'company/settings/saveNotificationSettings',
            data: this.state.settings,
        }).then((response) => {
            this.setState({
                settings: response,
                loading: false,
                unsavedChanges: false,
            });
        }).catch((error) => {
            errorPopper(error, tr('get_error'));
            this.setState({ loading: false });
        });
    }



    getColumns() {

        const columns = [
            {
                id: 'name',
                Header: tr('name'),
                accessor: (props) => {
                    return props.person_detail.last_name + " " + props.person_detail.first_name
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        '_original.person_detail.last_name',
                        '_original.person_detail.first_name'
                    ]
                }),
                filterAll: true
            }
        ];

        this.setState({ columns: columns });

    }

    switchStatus(id) {
        api({
            method: 'post',
            url: 'crm/settings/switchSendCrmNotificationStatus',
            data: { userId: id }
        }).then((response) => {
            this.getUsers();
        }).catch((error) => {
            console.log(error);
        });

    }

    toggleRecipientModal() {
        this.setState(prevState => ({
            showNewRecipientModal: !prevState.showNewRecipientModal
        }));
    }

    changeSettings(value, key) {
        const settings = this.state.settings;
        settings[key] = value;
        this.setState({ settings, unsavedChanges: true });
    }

    render() {

        return (
            <div className="apBox" id="pageNotificationSettings">
                <ApFormPage
                    onSave={this.saveSettings}
                    loading={this.state.loading}
                    unsaved={this.state.unsavedChanges}
                    error={this.state.error}
                >
                    <AddNotificationRecipient
                        show={this.state.showNewRecipientModal}
                        onClose={this.toggleRecipientModal}
                        refreshList={this.getUsers}
                    />
                    <div className="apBoxHeader">
                        <h1>{tr('notification_settings')}</h1>
                        <p>{tr('notification_settings_info')}</p>
                    </div>

                    <div className="clear">
                        <div className="apColumn w50">
                            <div className="padding">
                                <h2 className="">{tr('new_crm_entries')}</h2>
                                <h4 className="formHeader">{tr('timed_email_notification')}</h4>
                                <p>
                                    {tr('send_email_in_between_times')}
                                </p>

                                <ApInputStack gap='0'>
                                    <ApAddon width="30%"> {tr('begins')} </ApAddon>
                                    <ApInput
                                        width='70%'
                                        type="time"
                                        name="start"
                                        id="start"
                                        timeFormat="HH:mm:ss"
                                        clearable
                                        value={this.state.settings.crm_email_start_sending}
                                        onChange={(e) => {
                                            this.changeSettings(e, 'crm_email_start_sending');
                                        }}
                                        loading={this.state.loading}
                                    />
                                </ApInputStack>
                                <ApInputStack gap='0'>
                                    <ApAddon width="30%"> {tr('ends')} </ApAddon>
                                    <ApInput
                                        width='70%'
                                        type="time"
                                        name="stop"
                                        id="stop"
                                        timeFormat="HH:mm:ss"
                                        clearable
                                        value={this.state.settings.crm_email_stop_sending}
                                        onChange={(e) => {
                                            this.changeSettings(e, 'crm_email_stop_sending');
                                        }}
                                        loading={this.state.loading}
                                    />
                                </ApInputStack>
                                <div className="apFormGroup">
                                    <div className="apSwitchBlock">
                                        <label htmlFor={`no_weekend_notifications-switch`} className="info">
                                            {tr('no_weekend_notifications')}
                                        </label>
                                        <ApSwitch
                                            id={`no_weekend_notifications-switch`}
                                            on={this.state.settings.no_weekend_notifications}
                                            onChange={(e) => {
                                                this.changeSettings(e.target.checked, 'no_weekend_notifications');
                                            }}
                                            disabled={this.state.loading}
                                        />
                                    </div>
                                </div>

                                <h4 className="formHeader">{tr('crm_entries')}</h4>
                                <p>
                                    {tr('new_crm_entries_info')}
                                </p>
                                <div className="padding">
                                    <ApButton color="green" size="small" onClick={this.toggleRecipientModal}>
                                        <SvgIcon icon="plus" type="solid" />
                                        {tr('add_recipient')}
                                    </ApButton>
                                    <ApReactTable
                                        showFiltersInitially
                                        data={this.state.users}
                                        columns={this.state.columns}
                                        rowActions={[
                                            {
                                                icon: "trash",
                                                label: tr('delete'),
                                                action: this.switchStatus,
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="apColumn w50">
                            <div className="padding">

                            </div>
                        </div>
                    </div>
                </ApFormPage>
            </div>
        );
    }
}


NotificationSettings.propTypes = {
    // number:       PropTypes.number.isRequired,
    // string:       PropTypes.string,
    // multiple:     PropTypes.oneOfType([ PropTypes.array, PropTypes.func ]),
    // bool:         PropTypes.bool,
};


export default NotificationSettings;
