import ApButton from 'common/ApButton/ApButton';
import ApFormPage from 'common/ApFormPage/ApFormPage';
import { ApInput } from 'common/ApInput/ApInput';
import ApList from 'common/ApList/ApList';
import ApModal from 'common/ApModal/ApModal';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import api from 'services/Api/Api.js';
import { errorPopper, getLuminance, tr } from 'services/Helpers/Helpers';

const DEFAULT_COLOR = '#0099cc';

const CalendarSettings = (props) => {
	const [loading, setLoading] = useState(false);
	const [eventTypes, setEventTypes] = useState([]);
	const [showEditModal, setShowEditModal] = useState(false);
	const [editEventData, setEditEventData] = useState({
		name: '',
		color: DEFAULT_COLOR,
	});
	const [hasChanges, setHasChanges] = useState(false);
	const [idCounter, setIdCounter] = useState(0);

	const closeModal = () => {
		setEditEventData({
			name: '',
			color: DEFAULT_COLOR,
		});
		setShowEditModal(false);
	}

	// Get calendar events
	useEffect(() => {
		setLoading(true);
		api({
			url: 'company/settings/calendar'
		}).then(response => {
			setEventTypes(response.eventTypes);
		}).catch(error => {
			errorPopper(error, tr('get_error'));
		}).finally(() => {
			setLoading(false);
		})
	}, []);

	const renderHeader = () => {
		return (
			<div className='padding'>
				<h3>{tr('event')}</h3>
			</div>
		)
	}

	const renderBody = () => {
		return (
			<div className='padding'>
				<ApInput
					type="text"
					name="name"
					id="name"
					label={tr('name')}
					value={editEventData.is_static ? tr(editEventData.name) : editEventData.name}
					onChange={(e) => setEditEventData({ ...editEventData, name: e.target.value })}
					readOnly={editEventData.is_static}
					disabled={editEventData.is_static}
				/>
				<ApInput
					type="color"
					name="color"
					id="color"
					label={tr('choose_color')}
					value={editEventData.color}
					onChange={(e) => setEditEventData({ ...editEventData, color: e.target.value })}
				/>
			</div>
		)
	}

	const renderFooter = () => {
		const addChanges = () => {
			const index = eventTypes.findIndex(item => item.id === editEventData.id);
			if (index > -1) {
				eventTypes[index] = { ...editEventData };
			} else {
				setEventTypes([...eventTypes, { ...editEventData, order: eventTypes.length, id: 'new' + idCounter }]);
				setIdCounter(idCounter + 1);
			}
			setHasChanges(true);
			closeModal();
		}

		return (
			<div className='padding justify-space-between'>
				<ApButton onClick={closeModal}><SvgIcon icon="times" type="solid" /> {tr('cancel')}</ApButton>
				<ApButton color="green" onClick={addChanges}><SvgIcon icon="save" type="solid" /> {tr('continue')}</ApButton>
			</div>
		)
	}

	const saveSettings = () => {
		setLoading(true);
		api({
			url: 'company/settings/calendar',
			method: 'POST',
			data: {
				eventTypes
			}
		}).then(response => {
			setEventTypes(response);
			setHasChanges(false);
			errorPopper(null, tr('saved'), 2000, 'success');
			props.saveGeneralSettings({calendarEventTypes: response})
		}).catch(error => {
			errorPopper(error, tr('save_error'));
		}).finally(() => {
			setLoading(false);
		});
	}

	return (
		<ApFormPage
			unsaved={hasChanges}
			onSave={saveSettings}
			loading={loading}
		>
			<div id="calendarSettings" className="apBox">
				<div className='apBoxHeader'>
					<h1>{tr('calendar_settings')}</h1>
				</div>
				<div className='padding'>
					<ApList
						loading={loading}
						items={eventTypes}
						itemRenderer={(item) => {
							const backgroundColor = item.color || '#fff';
							const luminance = getLuminance(backgroundColor);
							const color = 'var(--clr-font-' + (luminance > 0.5 ? 'dark' : 'light') + ')';
							return (
								<div
									onClick={() => {
										setEditEventData(item);
										setShowEditModal(true);
									}}
									style={{ backgroundColor, color, padding: '0.5em', borderRadius: '3px', cursor: 'pointer' }}
								>
									{item.is_static ? tr(item.name) : item.name}
								</div>
							)
						}}
						// columns={[
						// 	{ value: (item) => <small>{item.entries_count || 0} {tr('pluralize_entries')}</small> },
						// ]}
						// onClick={this.openPaymentCardModal}
						actions={[
							{
								label: tr('edit'),
								icon: "edit",
								action: (item, closeFunc) => {
									setEditEventData(item);
									setShowEditModal(true);
									closeFunc();
								}
							},
							{
								label: tr('delete'),
								disabled: (item) => item.is_static,
								icon: "trash",
								action: (item, closeFunc) => {
									const foundIndex = eventTypes.findIndex(i => i.id === item.id);
									if (foundIndex > -1) {
										setHasChanges(true);
										const eventTypesCopy = [...eventTypes];
										eventTypesCopy[foundIndex].removed = !eventTypesCopy[foundIndex].removed;
										setEventTypes(eventTypesCopy);
									}
									closeFunc();
								}
							},
						]}
						validationState={(item) =>
							item.removed ? "removed" :
								item.unsaved ? "warning" :
									""
						}
						icon="calendar-day"
						sortable
						onSort={(items) => {
							console.log("items", items);
							
							items = items.map((item, index) => {
								item.order = index;
								return item;
							});
							setEventTypes(items);
							setHasChanges(true);
						}}
						addNewItem={() => setShowEditModal(true)}
					/>
				</div>

				<ApModal
					show={showEditModal}
					className='narrow'
					handleClose={() => closeModal()}
					closeFromBg
					header={renderHeader()}
					body={renderBody()}
					footer={renderFooter()}
				/>
			</div>
		</ApFormPage>
	)
}

const mapDispatchToProps = dispatch => ({
	saveGeneralSettings: (settings) => dispatch({ type: 'SAVE_GENERAL_SETTINGS', payload: settings }),
});

export default connect(null, mapDispatchToProps)(CalendarSettings);