import React from 'react';
import moment from 'moment';
import autoBind from 'react-autobind';

import Measure from 'react-measure';
import ApStickyBar from 'common/ApStickyBar/ApStickyBar.js';

import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';

import ApModal from 'common/ApModal/ApModal.js';
import { ApInput } from 'common/ApInput/ApInput.js';
import ApSelect from 'common/ApSelect/ApSelect.js';

import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApValidate from 'services/ApValidate/ApValidate.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApUserImage from 'common/ApUserImage/ApUserImage.js';
import { connect } from 'react-redux';

import {
    getTypeIcon
    , getTypeName
} from 'modules/Storage/common/StorageHelpers.js';

import {
    sqlToDateInput
    , formatMoney
    , errorPopper
    , tr,
    currentLang,
    timeSince,
    renderNetvisorAttachment
} from 'services/Helpers/Helpers.js';
// import ComponentTable      from './ComponentTable/ComponentTable.js';

import ReceiverModal from 'modules/Storage/PurchaseOrders/EditPurchaseOrder/ReceiverModal/ReceiverModal.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';

import ComponentInstanceTable from 'modules/Storage/common/ComponentInstanceTable/Cit.js';

import ApConfirm from 'common/ApConfirm/ApConfirm.js';
import './ImportPO.css';
import { Collapse } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { color } from 'd3';

import ImportPOComponentList from './ImportPOComponentList';
import ApModalInput from 'common/ApModalInput/ApModalInput';

const types = [
    { id: 1, value: "item", label: tr("component"), type: 'component' },
    { id: 2, value: "work", label: tr("work"), type: 'work' },
    { id: 3, value: "other", label: tr("cost"), type: 'cost' }
];

const actions = [
    { value: "new", label: tr('new') },
    { value: "linked", label: tr('purchase_order') },
];







class ImportPO extends React.Component {

    constructor(props) {
        super(props);

        let relatedId = null;
        let type = null;
        const params = this.props.match.params;

        if (params.id)
            relatedId = parseInt(params.id, 10);

        // const getItems = count =>
        //     Array.from({ length: count }, (v, k) => k).map(k => ({
        //         id: `item-${k}`,
        //         content: `item ${k}`
        //     }));


        this.state = {
            // items: getItems(10),


            loading: false,
            attachmentLoading: false,

            errorMessage: null,
            loadingCounter: 0,

            editShow: true,

            creatorName: auth.getName(),
            currencySign: auth.getCurrencySign(),

            date: moment(),

            import_components: [],
            components: [],

            relatedNumber: null,
            relatedType: null,
            relatedId: relatedId,
            import_id: null,
            project_id: null,
            po_number: null,
            type: type,

            supplier_name: null,
            supplier_taxnumber: null,
            supplier_id: null,

            sum: null,
            total_price_without_vat: null,
            date: null,
            due_date: null,
            need_date: null,
            delivery_date: null,
            reference_our: null,
            reference_your: null,
            price: null,
            details: null,

            edit_type_show: false,
            edit_linked_show: false,
            edit_component: [],
            selectedComponent: null,

            action: 'new',
            linked_po: [],
            linked_po_id: null,

            receiver_id: null,
            receiver_type: false,
            receiver_name: null,
            receiver_address: '',
            receiver_zipcode: '',
            receiver_city: '',
            receiver_country: '',
            receiver_contact_name: '',

            linked_po_edit: [],

            receiverEditShow: false,
            edit_action_show: false,

            showAttachments: localStorage.getItem('showPOAttachments') && localStorage.getItem('showPOAttachments') == 'true' ? true : false,
            invoice_attachments: [],
            componentTables: [this.getNewComponentTableData()],
            receiverEditShowData: {},
            nextId: 0,
            usingSavedState: false,
            selected: [],

            showImportConfirmModal: false,
            importConfirmModalData: {},
            importPurchaseOrder: false,
            saveAsConfirmed: false,
            acceptors: [],
            events: [],
            approvers: [],

        };
        this.nextId = 0;

        this.validator = new ApValidate(this, {

        });
        this.eventIcons = {
            "accepted": "check",
            "rejected": "times",
            "further_approver": "glasses",
            "-": "question",
        }
        this.eventIconColors = {
            "accepted": "green",
            "rejected": "red",
            "further_approver": "blue",
            "-": "question",
        }
        autoBind(this);
    }
    getNewComponentTableData() {
        if (!this.state) {
            return {
                components: [],
                action: "new",
                linked_po: [],
                supplier_name: null,
                supplier_taxnumber: null,
                supplier_id: null,
                index: 0,
            }
        }
        else {
            return {
                components: [],
                action: "new",
                linked_po: [],
                supplier_name: this.state.supplier_name,
                supplier_taxnumber: this.state.supplier_taxnumber,
                supplier_id: this.state.supplier_id,
                date: this.state.date,
                index: this.state.componentTables.length,
                reference_our: this.state.reference_our,
                reference_your: this.state.reference_your,
                details: this.state.details,
                due_date: this.state.due_date,
                delivery_date: this.state.delivery_date,
                need_date: this.state.need_date,
                receiver: {},
            };
        }
    }

    saveState(reset = false) {
        const id = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : null;
        const state = reset ? null : (JSON.parse(JSON.stringify(this.state)));
        if (state != null) {
            delete state.loadingCounter;
        }
        this.setState({ loading: true });
        if (id) {
            api({
                method: 'POST',
                url: `storage/po/import/savestate`,
                data: { id, state }
            }).then(response => {
                this.setState({ loading: false, usingSavedState: true });
                window.emitter.emit('popper', {
                    type: 'success',
                    content: <strong>{tr('saved')}</strong>,
                });
                if (reset) {
                    this.setState({ usingSavedState: false });

                    this.getRelated(this.state.relatedId);
                }
            }).catch(error => {
                this.setState({ loading: false });
                errorPopper(error, tr('save_error'));
            });
        }
    }

    componentDidMount() {
        this.getRelated(this.state.relatedId);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showAttachments != this.state.showAttachments) {
            localStorage.setItem('showPOAttachments', this.state.showAttachments);
        }
    }
    getNextId() {
        let id = this.nextId++;
        this.setState({ nextId: id + 1 });
        return "new" + id;
    }

    getRelated(id) {
        this.setState({ loadingCounter: this.state.loadingCounter + 1 });

        api({
            method: 'get',
            url: `storage/po/import/get/${id}`,
        }).then((response) => {

            let componentTables = [];
            if (response.saved_state != null) {
                let approvers = response.approvers;
                let events = response.events;
                let canIAprove = response.canIAprove;
                let allAproved = response.allAproved;
                let status = response.status;
                let mode = allAproved ? 'import' : 'approval';

                let savedState = JSON.parse(response.saved_state);
                let usingSavedState=savedState.componentTables.length>0?true:false;
                //Override the default values for state
                delete savedState.loadingCounter;
                delete savedState.loading;
                savedState.events = events;
                savedState.approvers = approvers;
                savedState.canIAprove = canIAprove;
                savedState.mode = mode;
                savedState.importPurchaseOrder = false;
                savedState.setAcceptor = false;
                savedState.importConfirmModalData = {};

                // let saveToState = {};
                // saveToState.componentTables = savedState.componentTables;

                if (response.action == 'linked' && !usingSavedState) {
                    this.getExistingPOs(response.linked_po_ids);
                }

                this.setState({ loadingCounter: 0, ...savedState, usingSavedState: true }, this.getAttachments);
                // this.setState({
                //     events: events,
                //     approvers: approvers,
                //     canIAprove: canIAprove,
                //     mode: mode,
                //     importPurchaseOrder: false,
                //     setAcceptor: false,
                //     // loadingCounter: 0,

                // });
                return null;
            }
            else {
                response.components.map((c, index) => {
                    if (!id) {
                        c.id = this.getNextId();
                    }
                    c.multiselectId = this.getNextId();
                    c.tableId = 0;
                    c.tableIndex = index;
                    c.unmodifiedCount = c.count;
                    c.maxCount = c.count;
                    return c;
                });
                

                let componentTable = {
                    import_id: response.import_id,
                    po_number: response.po_number,
                    components: response.components,
                    supplier_name: response.supplier,
                    supplier_id: response.supplier_id,
                    supplier_taxnumber: response.supplier_taxnumber,
                    sum: response.sum,
                    total_price_without_vat: response.total_price_without_vat,
                    date: response.date,
                    reference_our: response.reference_our,
                    reference_your: response.reference_your,
                    delivery_date: response.delivery_date,
                    due_date: response.due_date,
                    details: response.details,
                    price: response.price,
                    type: response.type,
                    action: response.action,
                    linked_po_id: response.linked_po_id,
                    receiver: response.receiver != null ? response.receiver : {},
                    linked_po: [],
                    index: 0,

                };
                componentTables[0] = componentTable;

                this.setState({
                    import_id: response.import_id,
                    po_number: response.po_number,
                    components: response.components,
                    supplier_name: response.supplier,
                    supplier_id: response.supplier_id,
                    supplier_taxnumber: response.supplier_taxnumber,
                    sum: response.sum,
                    total_price_without_vat: response.total_price_without_vat,
                    date: response.date,
                    reference_our: response.reference_our,
                    reference_your: response.reference_your,
                    delivery_date: response.delivery_date,
                    due_date: response.due_date,
                    details: response.details,
                    price: response.price,
                    type: response.type,
                    action: response.action,
                    linked_po_id: response.linked_po_id,
                    componentTables: componentTables,
                    linkedProjects: response.linkedProjects,
                    mode: response.allAproved ? 'import' : 'approval',
                    events: response.events,
                    approvers: response.approvers,
                    canIAprove: response.canIAprove,
                    importPurchaseOrder: false,
                    setAcceptor: false,
                }, () => {
                    // let componentTables = this.state.componentTables;
                    // // componentTables.push(this.getNewComponentTableData());
                    // this.setState({ componentTables });
                    this.getAttachments();
                });
            }
            if (response.action == 'linked') {
                this.getExistingPOs(response.linked_po_ids);
            }
            if (response.linkedProjects.length) {
                componentTables= this.linkProjects(response.linkedProjects,componentTables);
            }
            if (response.action != 'linked' && !response.linkedProjects.length) {
                // let componentTables = this.state.componentTables;
                // componentTables.push(this.getNewComponentTableData());
                this.setState({ componentTables }, () => {
                    this.setState({componentTables: [...componentTables, this.getNewComponentTableData()]});
                });
            }



            // console.log(response);

            this.setState({ loadingCounter: this.state.loadingCounter - 1 });

        }).catch((error) => {
            console.error(error);
            errorPopper(error, tr('get_error'));
            this.setState({
                errorMessage: tr('get_error'),
            });
            this.setState({ loadingCounter: this.state.loadingCounter - 1 });
        });
    }

    formatComponents(table) {
        let components = [...table.components];
        // let notLinkedComponents = [];
        // for (let c of table.components) {
        //     if (!c.linkedComponent) {
        //         notLinkedComponents.push(c);
        //     }
        // }
        // if (table.linked_po_id != null && table.linked_po.components !== undefined ) {
        //     components = [...notLinkedComponents, ...table.linked_po.components];
        // }
        // else {
        //     components = notLinkedComponents;
        // }


        let out_components = [];

        components.forEach(c => {
            out_components.push({
                id: null,
                type_id: c.type_id,
                name: c.name,
                component_id: c.component_id != null ? c.component_id : null,
                save_as_new: c.save_as_new,
                count: c.count,
                purchase_order_price: c.price,
                supplier_id: this.state.supplier_id,
                target_final_id: c.type_id == 1 ? table.receiver_id : null,
                target_final_type: c.type_id == 1 ? 'location' : null,
                linked: c.linkedComponent ? c.linkedComponent : null,
            });
        });

        return out_components;
    }
    isLastComponentTableEmpty(tables = this.state.componentTables) {
        let table = tables[tables.length - 1];
        if (table.components.length == 0 && !Number.isInteger(table.linked_po_id)) {
            return true;
        }
        else {
            return false;
        }
    }

    changeType(data) {
        this.setState({
            edit_type_show: true,
            edit_component: { ...data },
        });
    }

    linkComponent(data) {
        this.setState({
            edit_linked_show: true,
            edit_component: data
        });
    }
    componentsChange(components) {
        components = components.filter(c => !c.linkedComponent);
        this.setState({ components: components });

    }
    changeState(data) {
        this.setState(data);
    }

    saveAsNew(component) {
        let componentTables = this.state.componentTables;
        for (let table of componentTables) {
            table.components = table.components.map(c => {
                if (c.multiselectId === component.multiselectId || this.state.selected.includes(c.multiselectId)) {
                    c.component_id = null;
                    c.name = c.import_name;
                    c.save_as_new = true;
                    c.code = null;
                }
                return c;
            });
        }

        this.setState({ componentTables: componentTables, edit_type_show: false, edit_type: [] });
    }

    saveAsOutside(component) {
        let componentTables = this.state.componentTables;
        for (let table of componentTables) {
            table.components = table.components.map(c => {
                if (c.multiselectId === component.multiselectId || this.state.selected.includes(c.multiselectId)) {
                    c.component_id = null;
                    c.name = c.import_name;
                    c.save_as_new = null;
                    c.code = null;
                }
                return c;
            });
        }



        this.setState({ componentTables: componentTables, edit_type_show: false, edit_type: [] });
    }

    // removeComponent(data) {
    //     let linked_po = this.state.linked_po;

    //     let components = linked_po.components;
    //     components = components.filter( c => c.id !== data.id );
    //     linked_po.components=components;
    //     this.setState({linked_po: linked_po});
    // }

    removeComponent(component) {
        let componentTables = this.state.componentTables;

        let table = componentTables[component.tableId]


        
        for (let table of componentTables) {
            //component
            table.components = table.components.filter(c => {
                if (!(c.multiselectId === component.multiselectId || this.state.selected.includes(c.multiselectId))) {
                    // c.component_id = null;
                    // c.name = c.import_name;
                    // c.save_as_new = null;
                    // c.code = null;

                    return c;
                }
            });
        }



        componentTables = this.calculateTableIdsAndIndexes(componentTables);
        this.setState({ componentTables: componentTables, edit_type_show: false, edit_type: [] });

    }

    typeEditModalOpen() {
        this.setState({
            edit_type_show: true
        });
    }

    renderTypeEditModal() {
        return <ApModal
            closeFromBg
            show={Boolean(this.state.edit_type_show)}
            handleClose={() => this.setState({ edit_type_show: false, edit_component: [] })}
            className="narrow overflow"

            header={
                <div className="padding">
                    {tr('change_type_of_component')}
                </div>
            }
            body={<div className="padding">
                <ApInput
                    options={types}
                    type="select"
                    id="type_edit"
                    name="type_edit"
                    label={tr('type')}
                    value={this.state.edit_component.type_name ? this.state.edit_component.type_name : ''}
                    onChange={(e) => this.setType(e.target.value)}
                />
            </div>}
            footer={<div className="padding text-right">

                <ApButton onClick={() => this.setState({ edit_type_show: false, edit_component: [] })}>
                    <SvgIcon icon="times" type="solid" />
                    {tr('cancel')}
                </ApButton>

                <ApButton color="green" onClick={this.typeEditSave}>
                    <SvgIcon icon="check" type="solid" />
                    {tr('continue')}
                </ApButton>

            </div>}
        />
    }

    setType(new_type) {
        //let components = this.state.components.slice( 0 );
        let selected = this.state.edit_component;
        selected.type_name = new_type;
        types.map(t => {
            if (t.value === selected.type_name) {
                selected.type_name = t.value;
                selected.type_text = t.type;
                selected.type_id = t.id;
            }
        });
        this.setState({ edit_component: selected });
    }

    isApproval() {
        return this.state.mode == 'approval';
    }

    renderCommentModal() {
        return <ApModalInput
            show={this.state.showCommentModal}
            onClose={() => this.setState({ showCommentModal: false, comment: "", })}
            value={this.state.saveAsConfirmed}
            onSave={(value) => this.setState({ comment: value, showCommentModal: false }, () => this.approv(this.state.commentModalAproved, value))}
            title={tr('comment')}
            label={tr('comment')}
            // labelDescription={tr('import_as_confirmed_info')}
            type='textarea'
        />
    }

    showCommentModal(approved = false) {
        this.setState({ showCommentModal: true, commentModalAproved: approved });
    }

    approv(approved = false, comment = "") {
        let data = {
            approved: approved,
            id: this.state.relatedId,
            comment: comment,
        };
        if (approved) {
            this.saveState();
        }
        api({
            method: 'post',
            url: 'storage/po/import/approval',
            data: data,
        }).then((response) => {
            //console.log(response);
            
            if (response.nextAprov!=null) {
                window.emitter.emit('goTo', { pathname: '/storage/purchase/order/import/'+response.nextAprov });
                this.setState({
                    relatedId: response.nextAprov
                });
                this.getRelated(response.nextAprov);
            }
            else {
                
                window.emitter.emit('goTo', { pathname: `/storage/purchase/orders/import` });
            }

            // this.setState({ loadingCounter: this.state.loadingCounter - 1, mode: 'approval' });

        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({ loadingCounter: this.state.loadingCounter - 1, mode: 'import' });
        });
    }

    allowImport() {
        let allowImport = true;

        let settings = this.props.apPurchaseSettings;
        if (settings.po_import_need_approval) {
            let atleastOne = this.state.approvers.find(approver => approver.approved);
            if (this.state.approvers.length == 0 || !atleastOne) {
                allowImport = false;
            }
        }
        if (settings.po_import_everyone_need_approv) {
            let hasOneNotApproved = this.state.approvers.find(approver => !approver.approved);
            if (hasOneNotApproved) {
                allowImport = false;
            }
        }

        return allowImport;
    }

    canEdit() {

        if (this.state.approvers.length == 0) {
            return true;
        }
        return false;

    }
    saveImport(params = {}) {

        this.setState({ loadingCounter: this.state.loadingCounter + 1 });
        let state = JSON.parse(JSON.stringify(this.state));
        state.mode = this.state.acceptors.length > 0 ? 'import' : 'approval';
        let componentTables = JSON.parse(JSON.stringify(this.state.componentTables));
        let tables = [];
        for (let i = 0; i < componentTables.length - 1; i++) {
            let table = componentTables[i];
            let sum = 0;
            table.components.forEach((c) => {
                sum += c.price;
            });
            tables.push({
                supplier_id: this.state.supplier_id,
                supplier_name: this.state.supplier_name,
                supplier_taxnumber: this.state.supplier_taxnumber,
                sum: sum,
                date: table.date,
                reference_our: table.reference_our,
                reference_your: table.reference_your,
                type: table.type,
                details: table.details,
                delivery_date: table.delivery_date,
                due_date: table.due_date,
                need_date: table.need_date || null,
                price: table.price,

                receiver_id: table.receiver_id,
                receiver_type: table.receiver_type,
                receiver_name: table.receiver_name,
                receiver_address: table.receiver_address,
                receiver_zipcode: table.receiver_zipcode,
                receiver_city: table.receiver_city,
                receiver_country: table.receiver_country,
                receiver_contact_name: table.receiver_contact_name,

                project_code: table.receiver ? (table.receiver.project_code ? table.receiver.project_code : table.receiver.code) : null,

                sub_project_code: table.receiverSubProjectCode,

                target_project_id: table.receiverSubProjectId, //tämä on kesken ei käytetä vielä mihinkään

                components: this.formatComponents(table),
                action: table.action,
                linked_po_id: table.linked_po_id,
                project_id: table.project_id
            });
        }

        let data = {
            importTables: tables,
            state: state,
            import_id: this.state.relatedId,
            // supplier_id: this.state.supplier_id,
            // supplier_name: this.state.supplier_name,
            // supplier_taxnumber: this.state.supplier_taxnumber,
            // sum: this.state.sum,
            // date: this.state.date,
            // reference_our: this.state.reference_our,
            // reference_your: this.state.reference_your,
            // type: this.state.type,
            // details: this.state.details,
            // delivery_date: this.state.delivery_date,
            // due_date: this.state.due_date,
            // need_date: this.state.need_date,
            // price: this.state.price,

            // receiver_id: this.state.receiver_id,
            // receiver_type: this.state.receiver_type,
            // receiver_name: this.state.receiver_name,
            // receiver_address: this.state.receiver_address,
            // receiver_zipcode: this.state.receiver_zipcode,
            // receiver_city: this.state.receiver_city,
            // receiver_country: this.state.receiver_country,
            // receiver_contact_name: this.state.receiver_contact_name, 

            // components: this.formatComponents(), 
            // action: this.state.action,
            // linked_po_id: this.state.linked_po_id,
            // project_id: this.state.project_id
            saveAsConfirmed: this.state.saveAsConfirmed,
            comment: this.state.comment,
            acceptors: this.state.acceptors.map(user => user.id),
            importPurchaseOrder: this.state.importPurchaseOrder,
            reapprov: params.reapprov,
        };

        api({
            method: 'post',
            url: 'storage/po/import/save',
            data: data,
        }).then((response) => {
            //console.log(response);
            if (response.po) {
                sessionStorage.setItem('import', 'true');
                window.emitter.emit('goTo', { pathname: `/storage/purchase/order/id/${response.po.id}` });
            }
            this.setState({ loadingCounter: this.state.loadingCounter - 1, mode: 'approval', events: response.importPO.events });
            this.saveState();
            this.getRelated(this.state.relatedId);


        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({ loadingCounter: this.state.loadingCounter - 1, mode: 'import' });
        });
    }
    noImportClick() {
        this.setState({ confirmDelete: true });
    }

    noImport() {
        this.setState({ loadingCounter: this.state.loadingCounter + 1 });

        let data = {
            import_id: this.state.relatedId,
            remove_from_supplier: this.state.remove_from_supplier,
        };

        api({
            method: 'post',
            url: 'storage/po/import/delete',
            data: data,
        }).then((response) => {
            //console.log(response);
            window.emitter.emit('goTo', { pathname: `/storage/purchase/orders/import` });
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({ loadingCounter: this.state.loadingCounter - 1 });
        });
    }

    tryLinkComponents(linkedComponents, tableComponents = [], POIndex) {
        let poComponents = [...this.state.componentTables[0].components];
        let poTableComponents = [...tableComponents];
        for (let linkedComponent of linkedComponents) {
            let breaked = false;
            //yritetään etsiä table index 0 linkityksiä
            for (let tableComponentIndex in poComponents) {
                if (poComponents[tableComponentIndex].linkedComponent != true) {
                    if (linkedComponent.component_id == poComponents[tableComponentIndex].id) {
                        if (poComponents[tableComponentIndex].count <= linkedComponent.count) {
                            let [removed] = poComponents.splice(tableComponentIndex, 1); //poistaa komponentin
                            linkedComponent.linkedPoComponent = removed;
                        }
                        else {
                            let clone = { ...poComponents[tableComponentIndex] };
                            poComponents[tableComponentIndex].count = poComponents[tableComponentIndex].count - linkedComponent.count;
                            poComponents[tableComponentIndex].maxCount = poComponents[tableComponentIndex].count;
                            poComponents[tableComponentIndex].unmodifiedCount = poComponents[tableComponentIndex].count;
                            poComponents[tableComponentIndex].multiselectId = this.getNextId();


                            clone.count = linkedComponent.count;
                            clone.maxCount = linkedComponent.count;
                            clone.unmodifiedCount = linkedComponent.count;
                            clone.multiselectId = this.getNextId();
                            linkedComponent.linkedPoComponent = clone;
                        }
                        breaked = true;
                        break;
                    }
                }
            }
            if (!breaked) {
                //yritetään etsiä linkityksiä taulusta johon liitetään tilaus

                for (let tableComponentIndex in poTableComponents) {
                    if (poTableComponents[tableComponentIndex].linkedComponent != true) {
                        if (linkedComponent.component_id == poTableComponents[tableComponentIndex].id) {
                            if (poTableComponents[tableComponentIndex].count <= linkedComponent.count) {
                                let [removed] = poTableComponents.splice(tableComponentIndex, 1); //poistaa komponentin
                                linkedComponent.linkedPoComponent = removed;
                            }
                            else {
                                let clone = { ...poTableComponents[tableComponentIndex] };
                                poTableComponents[tableComponentIndex].count = poTableComponents[tableComponentIndex].count - linkedComponent.count;
                                poTableComponents[tableComponentIndex].maxCount = poTableComponents[tableComponentIndex].count;
                                poTableComponents[tableComponentIndex].unmodifiedCount = poTableComponents[tableComponentIndex].count;
                                poTableComponents[tableComponentIndex].multiselectId = this.getNextId();


                                clone.count = linkedComponent.count;
                                clone.maxCount = linkedComponent.count;
                                clone.unmodifiedCount = linkedComponent.count;
                                clone.multiselectId = this.getNextId();
                                linkedComponent.linkedPoComponent = clone;
                            }
                            break;
                        }
                    }
                }
            }
        }

        let componentTables = this.state.componentTables;
        componentTables[0].components = poComponents;
        this.setState({ componentTables })
        let returnData = POIndex == 0 ? [...poComponents, ...poTableComponents, ...linkedComponents] : [...poTableComponents, ...linkedComponents];
        return returnData;
    }

    getExistingPO(id, POIndex = 0) {
        this.setState({ loadingCounter: this.state.loadingCounter + 1 });
        api({
            method: 'get',
            url: `storage/po/id/${id}`,
        }).then((response) => {
            // console.log("GET EXISTING");
            // console.log(response);
            let components = [];
            if (response.components !== undefined) {
                let linkedPOComponents = response.components.map(c => {
                    c.linkedComponent = true;
                    c.multiselectId = this.getNextId();
                    c.unmodifiedCount = c.count;
                    c.maxCount = c.count;
                    return c;
                })
                linkedPOComponents = this.tryLinkComponents(linkedPOComponents, POIndex == 0 ? [] : this.state.componentTables[POIndex].components, POIndex);
                components = [...linkedPOComponents];
            }
            else {
                components = this.state.componentTables[POIndex].components;
            }



            let tables = this.state.componentTables;
            let table = tables[POIndex];
            table.components = components;
            table.linked_po = response;
            table.receiver_id = response.receiver_id;
            table.action = "linked";
            tables[POIndex] = table;

            if (!this.isLastComponentTableEmpty(tables)) {
                tables.push(this.getNewComponentTableData());
            }

            tables = this.calculateTableIdsAndIndexes(tables);
            this.setState({
                linked_po: response,
                receiver_id: response.receiver_id,
                loadingCounter: this.state.loadingCounter - 1,
                componentTables: tables,
            });
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({ loadingCounter: this.state.loadingCounter - 1 });
        });
    }

    setComponentstoTable(data) {

    }

    addEmptyTableToEndIfNeeded(tables) {
        if (!this.isLastComponentTableEmpty(tables)) {
            tables.push(this.getNewComponentTableData());
        }
        return tables;
    }


    calculateTableIdsAndIndexes(table) {
        table = this.removeEmptyTables(table);
        table = this.addEmptyTableToEndIfNeeded(table);
        for (let tableId in table) {
            table[tableId].index = tableId;
            for (let tableIndex in table[tableId].components) {
                table[tableId].components[tableIndex].tableId = tableId;
                table[tableId].components[tableIndex].tableIndex = tableIndex;
                if (table[tableId].components[tableIndex].linkedPoComponent) {
                    table[tableId].components[tableIndex].linkedPoComponent.tableId = tableId;
                    table[tableId].components[tableIndex].linkedPoComponent.tableIndex = tableIndex;
                }
                if (table[tableId].components[tableIndex].multiselectId == null) {
                    table[tableId].components[tableIndex].multiselectId = this.getNextId();
                }
            }
        }
        return table;
    }

    removeEmptyTables(tables) {

        tables = tables.filter((table, index) => {
            if (table.components.length > 0 || table.action != 'new' || (table.receiver && table.receiver.length) || table.project_id || tables.length == (index + 1)) {
                return table;
            }
        });
        // console.log(tables);
        return tables;
    }

    getExistingPOs(ids, POIndex = 0) {
        this.setState({ loadingCounter: this.state.loadingCounter + 1 });
        api({
            method: 'post',
            url: `storage/po/id/getExistingPOs`,
            data: { ids: ids }
        }).then((response) => {
            // console.log("GET EXISTINGS");
            // console.log(response);

            let components = [];
            let tables = this.state.componentTables;
            let poComponents = [...this.state.componentTables[POIndex].components.filter(c => !c.linkedComponent)];
            if (this.state.usingSavedState) {
                poComponents = [...this.state.components];
            }

            // if (response.length > 1) {
            for (let index in response) {
                let linkedComponents = response[index].components;
                linkedComponents = linkedComponents.map(c => {
                    c.linkedComponent = true;
                    c.multiselectId = this.getNextId();
                    c.unmodifiedCount = c.count;
                    c.maxCount = c.count;
                    return c;
                })


                for (let linkedComponent of linkedComponents) {
                    for (let tableComponentIndex in poComponents) {
                        if (linkedComponent.component_id == poComponents[tableComponentIndex].id) {
                            if (poComponents[tableComponentIndex].count <= linkedComponent.count) {
                                let [removed] = poComponents.splice(tableComponentIndex, 1); //poistaa komponentin
                                
                                // Remove unnecessary data
                                removed.linkedPoComponent = undefined;
                                removed.linkedComponent = undefined;

                                linkedComponent.price = removed.price;

                                linkedComponent.linkedPoComponent = removed;
                            }
                            else {
                                let clone = { ...poComponents[tableComponentIndex] };
                                poComponents[tableComponentIndex].count = poComponents[tableComponentIndex].count - linkedComponent.count;
                                poComponents[tableComponentIndex].maxCount = poComponents[tableComponentIndex].count;
                                poComponents[tableComponentIndex].unmodifiedCount = poComponents[tableComponentIndex].count;
                                poComponents[tableComponentIndex].multiselectId = this.getNextId();

                                clone.count = linkedComponent.count;
                                clone.maxCount = linkedComponent.count;
                                clone.unmodifiedCount = linkedComponent.count;
                                clone.multiselectId = this.getNextId();
                                linkedComponent.linkedPoComponent = clone;
                            }
                            break;
                        }
                    }
                }

                if (index == 0) {
                    tables[0].components = [...linkedComponents];
                    tables[index].linked_po = response[index];
                    tables[index].receiver_id = response[index].receiver_id;
                    tables[index].linked_po_id = response[index].id;
                    tables[index].receiver = response[index].receiver != null ? response[index].receiver : {};
                }
                else {
                    const oldComponents = Array.isArray(tables[index].components) ? [...tables[index].components] : [];
                    tables[index].components = [...oldComponents, ...linkedComponents];
                    tables[index].linked_po = response[index];
                    tables[index].receiver_id = response[index].receiver_id;
                    tables[index].linked_po_id = response[index].id;
                    tables[index].receiver = response[index].receiver != null ? response[index].receiver : {};
                    tables[index].action = "linked";


                }
                tables.push(this.getNewComponentTableData());
                tables = this.calculateTableIdsAndIndexes(tables);
                
            }
            //lisätään kaikki loput komponentit
            tables[0].components = [...poComponents, ...tables[0].components];
            this.setState({
                // linked_po: response[0],
                // receiver_id: response[0].receiver_id,
                loadingCounter: this.state.loadingCounter - 1,
                componentTables: tables,
            });

        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({ loadingCounter: this.state.loadingCounter - 1 });
        });
    }

    linkProjects(destinationProjects,tables) {
        // let tables = this.state.componentTables;
        for (let destinationProject of destinationProjects) {

            tables[tables.length - 1] = { ...tables[tables.length - 1], ...destinationProject };
            tables.push(this.getNewComponentTableData());
        }
        return tables;
        // this.setState({ componentTables: tables })
    }

    typeEditSave() {
        let tables = this.state.componentTables;
        let selected = this.state.edit_component;
        for (let table of tables) {
            table.components = table.components.map(c => {

                if (c.multiselectId === selected.multiselectId || this.state.selected.includes(c.multiselectId)) {
                    c.type_name = selected.type_name;
                    c.type_text = selected.type_text;
                    c.type_id = selected.type_id;
                }
                return c;
            });
        }



        this.setState({ componentTables: tables, edit_type_show: false, edit_type: [], selected: [] });
    }

    renderLinkedComponentModal() {
        return <ApModal
            closeFromBg
            show={Boolean(this.state.edit_linked_show)}
            handleClose={() => this.setState({ edit_linked_show: false, edit_component: [] })}
            className="narrow overflow"

            header={
                <div className="padding">
                    {tr('reassing_linked_component')}
                </div>
            }
            body={
                <div className="padding">
                    {tr('name_in_po')} {this.state.edit_component.import_name}<br />
                    <ApSelect
                        label={tr('search_storage_component')}
                        loading={this.state.loading}
                        value={this.state.selectedComponent}
                        optionRenderer="component_detail"
                        onChange={(s) => this.setState({ selectedComponent: s })}
                        clearable

                        objKeyId="id"
                        objKeyValue="name"
                        apiUrl="storage/components"
                        apiData={{
                            formatter: 'instance',
                            type: this.state.edit_component.type_id,
                            status: 'all',
                        }}
                    />
                </div>}
            footer={<div className="padding text-right">

                <ApButton onClick={() => this.setState({ edit_linked_show: false, edit_component: [] })}>
                    <SvgIcon icon="times" type="solid" />
                    {tr('cancel')}
                </ApButton>

                <ApButton color="green" onClick={this.linkedSave}>
                    <SvgIcon icon="check" type="solid" />
                    {tr('continue')}
                </ApButton>

            </div>}
        />
    }

    renderLinkComponentNamesConfirm() {
        let components = [];
        for (let table of this.state.componentTables) {
            for (let component of table.components) {
                if (component.linkedPoComponent && component.id != component.linkComponent.id) {
                    component.linkedPoComponent.linkName = true;
                    components.push(component.linkedPoComponent);
                }
            }
        }
        return <ApModal
            closeFromBg
            show={Boolean(this.state.showLinkComponentNamesConfirm)}
            handleClose={() => this.setState({ showLinkComponentNamesConfirm: false, })}
            className="narrow overflow"

            header={
                <div className="padding">
                    yhdistä nimet
                    {/* {tr('reassing_linked_component')} */}
                </div>
            }
            body={
                <div className="padding">
                    {components}
                </div>}
            footer={<div className="padding text-right">

                <ApButton onClick={() => this.setState({ edit_linked_show: false, edit_component: [] })}>
                    <SvgIcon icon="times" type="solid" />
                    {tr('cancel')}
                </ApButton>

                <ApButton color="green" onClick={this.linkedSave}>
                    <SvgIcon icon="check" type="solid" />
                    {tr('continue')}
                </ApButton>

            </div>}
        />
    }

    linkedSave() {
        let components = this.state.components.slice(0);
        let selected = this.state.edit_component;
        let linked = this.state.selectedComponent;
        components = components.map(c => {
            if (c.netvisor_id === selected.netvisor_id && c.fivaldi_id === selected.fivaldi_id) {
                c.component_id = linked.id;
                c.name = linked.name;
                c.code = linked.code;
            }
            return c;
        });

        this.setState({ components: components, edit_linked_show: false, selectedComponent: null, edit_component: [] });
    }

    receiverEditSave(data = null) {
        if (data) {
            if (data.receiver_type === 'project') {
                if (data.receiver !== undefined) {
                    data.project_id = data.receiver.id;
                }
            }
            let state = this.state.componentTables;
            state[this.state.receiverEditShowData.index] = { ...this.state.receiverEditShowData, ...data };
            if (state[state.length - 1].components != 0) {
                state.push(this.props.getNewComponentTableData());
                state = this.props.calculateTableIdsAndIndexes(state);
            }
            this.setState({ componentTables: state });
        }

        this.setState({ receiverEditShow: false, receiverEditShowData: {} });
    }

    showReceiverEdit() {
        this.setState({ receiverEditShow: true })
    }

    renderReceiverEdit() {
        return <ReceiverModal
            show={this.state.receiverEditShow}
            handleClose={this.receiverEditSave}

            receiver={this.state.receiverEditShowData.receiver}
            receiver_type={this.state.receiverEditShowData.receiver_type}
            receiver_id={this.state.receiverEditShowData.receiver_id}
            receiver_name={this.state.receiverEditShowData.receiver_name || ""}
            receiver_address={this.state.receiverEditShowData.receiver_address || ""}
            receiver_zipcode={this.state.receiverEditShowData.receiver_zipcode || ""}
            receiver_city={this.state.receiverEditShowData.receiver_city || ""}
            receiver_country={this.state.receiverEditShowData.receiver_country || ""}
            receiver_contact_name={this.state.receiverEditShowData.receiver_contact_name || ""}
            receiver_title={this.state.receiverEditShowData.receiver_title || ""}
            showSaveReceiverAddress={true}
        />

    }

    ActionEditModalOpen() {
        this.setState({
            action_edit: this.state.ActionEditModalOpenData.action, edit_action_show: true, linked_po_edit: this.state.linked_po
        });
    }

    renderActionEditModal() {
        let disabled = false;
        if (this.state.action_edit == 'linked' && this.state.linked_po_edit.length == 0) {
            disabled = true;
        }

        return <ApModal
            closeFromBg
            show={Boolean(this.state.edit_action_show)}
            handleClose={() => {
                let state = this.state.componentTables;
                state[this.state.ActionEditModalOpenData.index].linked_po = [];
                // console.log(state);
                this.setState({ edit_action_show: false, componentTables: state });
                // this.setState({ edit_action_show: false, linked_po: [] })
            }}
            className="narrow overflow"

            header={
                <div className="padding">
                    {tr('select_what_to_do_with_po_import')}
                </div>
            }
            body={<div className="padding">
                <ApInput
                    options={actions}
                    type="select"
                    id="action_edit"
                    name="action_edit"
                    label={tr('prosessing')}
                    value={this.state.action_edit ? this.state.action_edit : ''}
                    onChange={(e) => this.setState({ action_edit: e.target.value })}
                />

                {this.state.action_edit == 'linked' && <ApSelect
                    label={tr('search_order')}
                    value={this.state.linked_po_edit}
                    optionRenderer={(item) => {
                        return (
                            <div className="searchOption pos">
                                <SvgIcon className="typeIcon" icon="clipboard-check" type="solid" />
                                <div className="itemnumber">
                                    <strong>{item.number}</strong>
                                </div>
                                <div className="iteminfo">
                                    <strong> {item.name}</strong><br />
                                    <small> {item.project_code} {sqlToDateInput(item.date)}</small>
                                </div>
                            </div>
                        );
                    }}
                    onChange={(item) => this.setState({ linked_po_edit: item })}
                    objKeyId="id"
                    objKeyValue="number"
                    apiUrl="search/po"
                    apiData={{
                        paid: false,
                        archived: false,
                        sortBy: "number",
                        reverseOrder: true,
                    }}
                />}
            </div>}
            footer={<div className="padding text-right">

                <ApButton onClick={() => this.setState({ edit_action_show: false, linked_po_edit: [] })}>
                    <SvgIcon icon="times" type="solid" />
                    {tr('cancel')}
                </ApButton>

                <ApButton color="green" onClick={this.actionSave} disabled={disabled}>
                    <SvgIcon icon="check" type="solid" />
                    {tr('continue')}
                </ApButton>

            </div>}
        />
    }

    renderAcceptorSelect() {

        let reapprov = this.state.importConfirmModalData.reapprov || false;
        let userInput = null;
        if (this.state.setAcceptor) {
            userInput = <ApSelect
                label={tr('further_approver')}
                value={this.state.acceptors}
                // objKeyValue={(item) => { return `${item.name}`; }}
                valueRenderer="user"
                optionRenderer="user"
                onChange={(selected) => { this.setState({ acceptors: selected }) }}
                objKeyId="id"
                clearable
                apiUrl="storage/po/acceptors"
                multiselect
                objKeySearchable="name"
            />
        }

        let description = null;
        if (this.state.setAcceptor) {
            description = <ApInput
                type="textarea"
                id="comment"
                name="comment"
                label={tr('comment_to_event_log')}
                autoComplete="off"
                value={this.state.comment}
                onChange={(e) => this.setState({ comment: e.target.value })}
            />

        }

        return <div className="commentDialog">


            <div className="apSwitchBlock">
                <label htmlFor="add-acceptor-switch" className="info">
                    {reapprov ? tr('add_new_approver') :tr('add_further_approver')}
                    <small>{tr('po_import_add_further_approver_info')}</small>
                </label>
                <ApSwitch
                    id="add-acceptor-switch"
                    on={this.state.setAcceptor}
                    onChange={() => {
                        this.setState({
                            setAcceptor: !this.state.setAcceptor,
                            importPurchaseOrder: false,
                        });
                    }}
                />
            </div>
            {description}
            {userInput}
        </div>
    }

    renderImportConfirmModal() {

        let receiverIdCheck = true;
        if (this.state.componentTables.length > 1) {
            for (let i = 0; i < this.state.componentTables.length - 1; i++) {
                if (this.state.componentTables[i].receiver_id == null) {
                    receiverIdCheck = false;
                }
            }

        }
        else {
            receiverIdCheck = false;
        }

        let allowImport = this.allowImport();

        let reapprov = this.state.importConfirmModalData.reapprov || false;
        let data = {};
        data.reapprov = reapprov;

        return <ApModal
            show={this.state.showImportConfirmModal}
            handleClose={() => this.setState({ showImportConfirmModal: false, importConfirmModalData:{} })}
            className="narrow"
            onOpen={this.resetState}
            header={
                <div className="padding-small">
                    <h4>
                        {tr('import_or_approv')}
                    </h4>
                </div>
            }
            body={
                <div className="padding">

                    {!reapprov && allowImport && <div className="apSwitchBlock">
                        <label htmlFor="import-purchase-order-switch" className="info">
                            {tr('import_po')}
                            {/* <small>{tr('po_import_add_further_approver_info')}</small> */}
                        </label>
                        <ApSwitch
                            id="import-purchase-order-switch"
                            on={this.state.importPurchaseOrder}
                            onChange={() => {
                                this.setState({
                                    importPurchaseOrder: !this.state.importPurchaseOrder,
                                    setAcceptor: false,
                                });
                            }}
                        />
                    </div>}
                    {!this.state.setAcceptor && receiverIdCheck && allowImport &&
                        <div className="apSwitchBlock">
                            <label htmlFor="import-as-accepted-switch" className="info">
                                {tr('import_as_confirmed')}
                                <small>{tr('import_as_confirmed_info')}</small>
                            </label>
                            <ApSwitch
                                id="import-as-accepted-switch"
                                on={this.state.saveAsConfirmed}
                                onChange={() => this.setState({ saveAsConfirmed: !this.state.saveAsConfirmed })}
                                label={tr('import_as_confirmed')}
                            />
                        </div>}

                    


                    {this.renderAcceptorSelect()}
                    {/* {this.props.meta.action == 'save' ? this.renderSkipOptions() : ''} */}
                </div>
            }
            footer={
                <div className="footer padding">
                    <ApButton onClick={() => this.setState({ showImportConfirmModal: false })}>
                        <SvgIcon icon="times" type="solid" />
                        {tr('cancel')}
                    </ApButton>

                    <ApButton
                        className="comment-submit"
                        color={"green"}
                        onClick={() => {
                            this.setState({ showImportConfirmModal: false }, () => this.saveImport(data))
                        }}

                        disabled={!((this.state.setAcceptor && this.state.acceptors.length) || this.state.importPurchaseOrder) || !receiverIdCheck}
                    >
                        <SvgIcon icon="check" type="solid" />
                        {tr('continue')}
                    </ApButton>

                </div>
            }
        />


       
    }

    removeLinkedPoComponents(table) {
        let components = [];
        for (let component of table.components) {
            if (component.linkedComponent != true) {
                components.push(component);
            }
            else {
                if (component.linkedPoComponent) {

                    components.push(component.linkedPoComponent);
                }
            }
        }
        table.components = components;
        return table;
    }

    actionSave() {
        let action = this.state.action_edit;
        let linked_po = this.state.linked_po_edit;
        let linked_po_id = this.state.linked_po_id;
        let state = this.state.componentTables;
        let table = state[this.state.ActionEditModalOpenData.index];

        table = this.removeLinkedPoComponents(table);

        if (action == 'new') {
            table.linked_po = [];
            table.linked_po_id = null;
        }
        if (action == 'linked' && linked_po != []) {
            table.linked_po_id = linked_po.id;
            linked_po_id = linked_po.id;
            this.getExistingPO(linked_po_id, this.state.ActionEditModalOpenData.index);
        }

        //table.linked_po_edit = [];
        table.action = action;
        state[this.state.ActionEditModalOpenData.index] = table;
        this.setState({
            edit_action_show: false, componentTables: state, ActionEditModalOpenData: {},
            linked_po_edit: [],
        });
        
    }

    getAttachments() {
        if (this.state.invoice_attachments.length == 0) {
            this.setState({ attachmentLoading: true });
            api({
                method: 'get',
                url: `storage/po/import/attachments/${this.state.import_id}/get`
            }).then(response => {
                // console.log("response", response);
                if (Array.isArray(response)) {
                    this.setState({ invoice_attachments: response, attachmentLoading: false });
                }
            }).catch(error => {
                console.error("error", error)
                this.setState({ attachmentLoading: false });
            })
        }
    }

    openAttachmentsWindow() {
        if (this.state.attachmentLoading) return;
        const attachmentsContainer = document.getElementById('attachments-container').outerHTML;
        const attachmentsWindow = window.open('', '', 'width=500', 'height=700');
        const doc = attachmentsWindow.document;
        doc.open();
        doc.write(attachmentsContainer);
        doc.close();
    }




    //--------------------------------------------------------------------------------------------------



    onDragEnd(result) {
        const reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        };
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items
        });
    }






    renderDescrtions() {

        return this.state.events.map((event, key) => {

            let classes = ["event", event.action];

            

            return (
                <div className={classes.join(" ")} key={key}>
                    <SvgIcon className="eventIcon" icon={this.eventIcons[event.action]} type="solid" />
                    <div className="header" >
                        <div className="user">
                            <ApUserImage user={event.user.person_detail} block size="small" tooltip={event.user.person_detail.full_name} />
                        </div>
                        <strong className="title">{event.description}</strong><br />
                        <small className="time">{moment(event.created_at).locale(currentLang()).format('LLL')} ({timeSince(event.created_at)})</small>

                        {event.unsaved && <SvgIcon className="unsavedIcon" icon="exclamation-circle" type="solid" />}

                    </div>


                </div>
            );
        })

    }



    renderApproversList() {
        let list = this.state.approvers.map((approver) => {
            let color = approver.approved ? 'green' : 'gray';
            let name = approver.user ? approver.user.person_detail.full_name : "";
            return (
                <div className="approvedStatus">
                    <ApTooltip text={approver.approved ? tr('accepted') : tr('waiting')}>
                        <SvgIcon className="approvedStatusIcon" icon={approver.approved ? 'check' : 'times'} type="solid" fill={color} />
                    </ApTooltip>
                    {name}
                </div>
            );
        });

        if (this.state.approvers.length > 0) {

            return <div className='padding'>
                <h4>{tr('in_approval')}</h4>
                {list}
            </div>;
        }

        return null;
    }

    updateFilesCount(count) {
        // alert("WW" + count);
        this.setState({ files_count: count  });
       
    }



    //--------------------------------------------------------------------------------------------------

    render() {

      


        const getItems = count =>
            Array.from({ length: count }, (v, k) => k).map(k => ({
                id: `item-${k}`,
                content: `item ${k}`
            }));

        // a little function to help us with reordering the result
        const reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        };

        const grid = 8;

        const getItemStyle = (isDragging, draggableStyle) => ({
            // some basic styles to make the items look a bit nicer
            userSelect: "none",
            padding: grid * 2,
            margin: `0 0 ${grid}px 0`,

            // change background colour if dragging
            background: isDragging ? "lightgreen" : "grey",

            // styles we need to apply on draggables
            ...draggableStyle
        });

        const getListStyle = isDraggingOver => ({
            background: isDraggingOver ? "lightblue" : "lightgrey",
            padding: grid,
            width: 250
        });



        if (this.state.loadingCounter > 0)
            return <div className="apLoader"></div>;

        const editSupplier = (field) => {
            return <dd onClick={this.showReceiverEdit}>{this.state[field]}</dd>
        }

        let poAction = <div className="apFormColumn">
            <dl className="dl-horizontal  pointer" onClick={this.ActionEditModalOpen}>
                <dt>{tr('status')}</dt>
                <dd>{tr('new_purchase_order')}</dd>
            </dl>
            <dl className="dl-horizontal pointer">
                <dt>{tr('recipient')}</dt>
                <dd onClick={this.showReceiverEdit}>
                    {Boolean(this.state.receiver_type) && <ApTooltip text={getTypeName(this.state.receiver_type)}>
                        <SvgIcon icon={getTypeIcon(this.state.receiver_type)} type="solid" />
                    </ApTooltip>}
                    {this.state.receiver_name == null ? <div style={{ color: '#ff4444' }}>{tr('storage_location_not_defined')}</div> : this.state.receiver_name}
                </dd>
                <dt>{tr('shipping_address')}</dt>
                <dt></dt>{editSupplier('receiver_title')}
                <dt></dt>{editSupplier('receiver_address')}
                <dt></dt>{editSupplier('receiver_zipcode')}
                <dt></dt>{editSupplier('receiver_city')}
                <dt></dt>{editSupplier('receiver_country')}
            </dl>
            <dl className="dl-horizontal">
                <dt>{tr('supplier')}</dt>
                {this.state.supplier_id == null && <dd>{tr('new_supplier')}</dd>}

                <dd>{this.state.supplier_name}</dd>
                <dd>{this.state.supplier_taxnumber}</dd>
            </dl>
        </div>

        if (this.state.action != 'new') {
            poAction =
                <div className="apFormColumn">
                    <dl className="dl-horizontal pointer" onClick={this.ActionEditModalOpen}>
                        <dt>{tr('status')}</dt>
                        <dd>{tr('existing_purchase_order')}</dd>
                    </dl>
                    <dl className="dl-horizontal">
                        <dt>{tr('number')}</dt>
                        <dd>{this.state.linked_po.number}</dd>
                        <dd>{this.state.linked_po.receiver_title}</dd>
                        <dd>{this.state.linked_po.receiver_name}</dd>
                    </dl>
                    <dl className="dl-horizontal">
                        <dt>{tr('project')}</dt>
                        <dd>{this.state.linked_po.project_full_name || "-"}</dd>
                    </dl>
                    <dl className="dl-horizontal">
                        <dt>{tr('supplier')}</dt>
                        <dd>{this.state.linked_po.supplier_name}</dd>
                    </dl>
                </div>

        }

        const getButton = (file, title, type) => {//console.log(this.state.relatedId);console.log(this.state.relatedNumber)
            const icon = (type === 'docx') ? 'file-word' : 'file-pdf';
            return <ApButton color="white"
                loading={this.state.loading}
                disabled={this.state.loading}
                onClick={() => this.downloadFile(file, title, type)}
                size="tiny"
            >
                <SvgIcon icon={icon} type="solid" />
            </ApButton>
        }


        let dataEdit = <div>
        </div>

        let dataSummary = <div>
            {/* {poAction} */}
            <div className="apFormColumn">
                <dl className="dl-horizontal">
                    <dt>{tr('po_number')}</dt>
                    <dd>{this.state.po_number}</dd>

                    <dt>{tr('price_total')}</dt>
                    <dd> {formatMoney(this.state.sum)} {this.state.currencySign} {this.state.linked_po_id > 0 && <span>( {tr('purchase_order')} {this.state.linked_po.price} {this.state.currencySign})</span>}</dd>
                    <dd> ({formatMoney(this.state.total_price_without_vat)} {this.state.currencySign}  {tr('vat')} 0% )</dd>




                </dl>
            </div>
            <div className="apFormColumn">
                <dl className="dl-horizontal">
                    <dt>{tr('reference_number_your')}</dt>
                    <dd>{this.state.reference_your}</dd>

                    <dt>{tr('reference_number_our')}</dt>
                    <dd>{this.state.reference_our}</dd>

                    <dt>{tr('date')}</dt>
                    <dd>{sqlToDateInput(this.state.date)}</dd>
                    <dd>
                        <ApButton
                            onClick={this.openAttachmentsWindow}
                            color='green'
                            className='size-tiny'
                            disabled={this.state.attachmentLoading}
                        >
                            {tr('get_attachments')}    
                        </ApButton>
                     
                    </dd>
                  
                </dl>
            </div>

        </div>

        const validatorMessage = this.validator.getStatusAll();
        const type = this.state.type;

        if (this.state.loadingCounter > 0)
            return <div className="apLoader"></div>;

        if (this.state.errorMessage)
            return <div> {this.state.errorMessage} </div>

        const saveBtn = (
            <ApButton className={"save"} color="blue" onClick={() => this.saveState()} disabled={this.state.loading || !this.canEdit()}>
                {tr('save')}
                <SvgIcon icon="save" type="solid" />
            </ApButton>
        );
        const resetBtn = (
            <ApTooltip text={tr("purchase_order_clear_tooltip")}>
                <ApButton className={"save"} color="green" style={{ marginLeft: 10 }} onClick={() => { this.saveState(true) }} disabled={this.state.loading || !this.state.usingSavedState || !this.canEdit()}>
                    <SvgIcon icon="brush" type="solid" />
                    {tr('clear')}
                </ApButton>
            </ApTooltip>
        );

        // let importButton = <ApButton color="blue" onClick={ this.saveImport } >
        //     <SvgIcon icon="save" type="solid" />
        //     { tr('import') }
        // </ApButton>
        let receiverIdCheck = true;
        if (this.state.componentTables.length > 1) {
            for (let i = 0; i < this.state.componentTables.length - 1; i++) {
                if (this.state.componentTables[i].receiver_id == null) {
                    receiverIdCheck = false;
                }
            }

        }
        else {
            receiverIdCheck = false;
        }

        let saveBox = null;
        // if ((type === 'netvisor-po' || type === 'fivaldi-po') && this.state.receiver_id != null)

        if (this.isApproval()) {
            saveBox = <ApStickyBar zIndex={10} bottomMode ref={node => this.stickyBar = node}>
                <div className="saveBar">
                    <div className="left">
                        <ApButton color="red" onClick={() => this.showCommentModal(false)} disabled={!this.state.canIAprove}>
                            <SvgIcon icon="times" type="solid" />
                            {tr('reject')}
                        </ApButton>

                    </div>
                    <div className="right">
                        <ApButton style={{ marginRight: '10px' }} className={"save" + (this.state.senderTitle && this.state.receiverTitle ? " highlight" : "")} color="blue" onClick={
                            () => this.setState({
                                showImportConfirmModal: true,
                                comment: "",
                                acceptors: [],
                                importConfirmModalData: { reapprov: true },
                                setAcceptor: true,
                                importPurchaseOrder: false,
                            }
                            )} disabled={!this.state.canIAprove} >
                            {tr('send_to_reapproval')}
                            <SvgIcon icon="arrow-right" type="solid" />
                        </ApButton>
                        <ApButton color="green" onClick={() => this.showCommentModal(true)} disabled={!this.state.canIAprove}>
                            <SvgIcon icon="check" type="solid" />
                            {tr('accept')}
                        </ApButton>
                    </div>
                </div>
            </ApStickyBar>
        }
        else {
            if ((type === 'netvisor-po' || type === 'fivaldi-po') && receiverIdCheck) {
                saveBox = <ApStickyBar zIndex={10} bottomMode ref={node => this.stickyBar = node}>
                    <div className="saveBar">
                        <div className="left">
                            <ApButton className={"save" + (this.state.senderTitle && this.state.receiverTitle ? " highlight" : "")} color="red" onClick={this.noImportClick} disabled={false}>
                                <SvgIcon icon="trash" type="solid" />
                                {tr('delete')}
                            </ApButton>
                            {resetBtn}
                        </div>
                        <div className="right">                           
                            {saveBtn}
                            <ApTooltip text={validatorMessage}>
                                <ApButton style={{ marginLeft: '10px' }} className={"save" + (this.state.senderTitle && this.state.receiverTitle ? " highlight" : "")} color="blue" onClick={() => this.setState({ showImportConfirmModal: true, comment: "", acceptors: [] })} >
                                    {receiverIdCheck ? tr('import_or_approv') : tr('to_approval')}
                                    <SvgIcon icon="arrow-right" type="solid" />
                                </ApButton>
                            </ApTooltip>                          
                        </div>
                    </div>
                </ApStickyBar>
            } else {
                saveBox = <ApStickyBar zIndex={10} bottomMode ref={node => this.stickyBar = node}>
                    <div className="saveBar">
                        <div className="left">
                            <ApButton className={"save" + (this.state.senderTitle && this.state.receiverTitle ? " highlight" : "")} color="red" onClick={this.noImportClick}>
                                <SvgIcon icon="trash" type="solid" />
                                {tr('delete')}
                            </ApButton>
                            {resetBtn}
                        </div>
                        {/* {this.state.usingSavedState && <div className="middle">
                       { tr("saved")}
                    </div>} */}
                        <div className="right">                            
                            {saveBtn}                                                         
                        </div>
                    </div>
                </ApStickyBar>
            }
        }
        
        if (this.state.loadingCounter > 0 || !Object.keys(this.props.apPurchaseSettings).length) {
            saveBox = null;
        }

        let components = [];
        if (this.state.linked_po_id != null && this.state.linked_po.components !== undefined) {
            const linkedPOComponents = this.state.linked_po.components.map(c => {
                c.linkedComponent = true;
                return c;
            })
            components = [...this.state.components, ...linkedPOComponents];
        }
        else {
            components = this.state.components;
        }

        return <Measure
            onResize={() => {
                // if (this.stickyBar)
                //     this.stickyBar.updateBounds();
            }}
        >
            {({ measureRef }) => <div ref={measureRef} className="apBox" id="pageDeliveryEdit">
                <div className="pageBackButton" onClick={() => this.props.history.push('/storage/purchase/orders/import')}>
                    <SvgIcon icon="arrow-left" type="solid" size="small" />
                    {tr('back')}
                </div>
                <div className="apBoxHeader">
                    <h1>
                        {tr('po_import')}
                    </h1>
                    <p>{tr('po_import_info')}</p>
                </div>
                {this.renderApproversList()}
                <div className="padding">

                    <div className="apForm">
                        <div className="apFormColumn fullColumn">
                            {dataEdit}
                            {dataSummary}
                            {this.state.events.length > 0 && this.state.events[0].description && <h5 style={{ color: 'orange' }}>{tr('previous_user_added_comment')}</h5>}
                        </div>
                    </div>

                    <div className="apForm">
                        {/* <div className="apFormColumn fullColumn">
                        <ApButton disabled={true} color="white" onClick={ this.getPdf }>
                            TODO lataa PDF
                        </ApButton>
                    </div> */}
                        <div className="fileContainer">
                            {(this.state.relatedId && this.state.relatedType === 'order') && (
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="fileContainerText">{tr('download_referral')}</td>
                                            <td>{getButton('dispatch_note', tr('referral'), 'pdf')}</td>
                                            <td>{getButton('dispatch_note', tr('referral'), 'docx')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>

                    {this.renderTypeEditModal()}
                    {this.renderLinkedComponentModal()}
                    {this.renderReceiverEdit()}
                    {this.renderActionEditModal()}

                    {this.renderImportConfirmModal()}
                    {this.renderCommentModal()}

                    <ApConfirm
                        show={Boolean(this.state.confirmDelete)}
                        onClose={() => this.setState({ confirmDelete: false })}
                        onConfirm={this.noImport}
                        header={tr('confirm_delete_import_po')}
                        body={
                            <div>
                                {/* {tr('confirm_delete_import_po_info')} */}
                                <div className="apSwitchBlock" style={{marginTop:20}}>
                                    <label htmlFor="remove-from-supplier-switch" className="info">
                                        {tr('dont_import_po_invoices')}
                                        <small>{tr('dont_import_po_invoices_info')}</small>
                                    </label>
                                    <ApSwitch
                                        id="remove-from-supplier-switch"
                                        on={this.state.remove_from_supplier}
                                        onChange={() => {
                                            this.setState({
                                                remove_from_supplier: !this.state.remove_from_supplier,
                                            });
                                        }}
                                    />
                                </div>
                                

                            </div>
                        }
                    />

                    <div className="apForm">
                        <div className="apFormColumn fullColumn">
                            <ImportPOComponentList
                                changeState={this.changeState}
                                data={this.state.componentTables}
                                componentsChange={this.componentsChange}
                                instance={'po-import'}
                                removeComponent={this.removeComponent}
                                changeType={this.changeType}
                                linkComponent={this.linkComponent}
                                saveAsNew={this.saveAsNew}
                                saveAsOutside={this.saveAsOutside}
                                removeComponentLocked={true}
                                addComponentLocked={true}
                                getNextId={this.getNextId}
                                getNewComponentTableData={this.getNewComponentTableData}
                                calculateTableIdsAndIndexes={this.calculateTableIdsAndIndexes}
                                selected={this.state.selected}
                                supplier_id={this.state.supplier_id}
                                disabled={!this.canEdit()}
                            // tableActions={poAction}
                            />
                            {/* <ComponentInstanceTable
                                componentsChange={this.componentsChange}
                                components={components}

                                instance={'po-import'}
                                removeComponent={this.removeComponent}
                                changeType={this.changeType}
                                linkComponent={this.linkComponent}
                                saveAsNew={this.saveAsNew}
                                saveAsOutside={this.saveAsOutside}

                                removeComponentLocked={true}
                                addComponentLocked={true}
                            />  */}


                        </div>
                    </div>
                    {saveBox}
                </div>
                <div className='padding events' id='poEvents'>
                    {this.renderDescrtions()}
                </div>
                <div className='padding'>
                    <div onClick={() => this.setState({ showAttachments: !this.state.showAttachments })} className='pointer'>
                        <h3 style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {tr('attachments')}({this.state.invoice_attachments.length})
                            <SvgIcon type='solid' icon='chevron-left' className={`${this.state.showAttachments ? 'rotate-anti-clockwise-90' : null}`} style={{ transition: '0.3s' }} />
                        </h3>
                    </div>


                    <Collapse in={this.state.showAttachments}>
                        {this.state.attachmentLoading
                            ? <div className='apInlineLoader' style={{ margin: '0 auto' }}></div>
                            : <div id='attachments-container' className="padding">
                                {this.state.loading && <div className='apLoader'></div>}
                                {!this.state.loading && this.state.invoice_attachments.length == 0 && <p>{tr('no_invoice_attachments')}</p>}
                                {!this.state.loading && this.state.invoice_attachments && this.state.invoice_attachments.map((file, index) => {
                                    if (file.netvisor) 
                                        return renderNetvisorAttachment(file, index);

                                    return (
                                        <iframe key={index} src={file.netvisor ? 'data:application/pdf;base64,' + file.base64Data : file} width="100%" height="1000" type="application/pdf"></iframe>
                                    );
                                })}
                            </div>
                        }
                    </Collapse>
                </div>
            </div>
            }
        </Measure>
    }
}

const mapStateToProps = state => ({
    apPurchaseSettings: state.apPurchaseSettings
});
export default connect(mapStateToProps)(ImportPO);
