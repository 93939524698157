import ApButton from "common/ApButton/ApButton";
import ApModal from "common/ApModal/ApModal";
import ApSelect from "common/ApSelect/ApSelect";
import SvgIcon from "common/SvgIcon/SvgIcon";
import React from "react";
import autoBind from "react-autobind";
import { getWeekdays, tr } from "services/Helpers/Helpers";
import _ from "lodash";
import { ApAddon, ApInput, ApInputStack } from "common/ApInput/ApInput";
import ApSwitch from "common/ApSwitch/ApSwitch";

class HourTypeExtraRuleModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            start_time: null,
            end_time: null,
            days: [],
            id: null,
            useHourPrice: false,
            hour_price: null,
            salary_multiplier: null,
            salary_category: null,
            overtime_threshold: null,
        };

        this.counter = 1;

        this.dayOptions = getWeekdays();

        autoBind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && this.props.data) {
            this.setState({
                start_time: this.props.data.start_time || null,
                end_time: this.props.data.end_time || null,
                days: this.props.data.days ? this.parseDays(this.props.data.days) : [],
                id: this.props.data.id || null,
                useHourPrice: !!this.props.data.hour_price,
                hour_price: this.props.data.hour_price || null,
                salary_multiplier: this.props.data.salary_multiplier || null,
                salary_category: this.props.data.salary_category || null,
                overtime_threshold: this.props.data.overtime_threshold || null,
            });
        }
    }

    parseDays(days) {
        return days.map(day => {
            const foundDay = this.dayOptions.find(d => d.value === day.day);
            foundDay.day = foundDay.value;
            return foundDay;
        });
    }

    resetState() {
        this.setState({
            start_time: null,
            end_time: null,
            days: [],
            useHourPrice: false,
            hour_price: null,
            salary_multiplier: null,
            salary_category: null,
            overtime_threshold: null,
        });
    }

    handleSave() {
        this.props.onSave({
            start_time: this.state.start_time,
            end_time: this.state.end_time,
            days: this.state.days,
            id: this.props.data ? this.props.data.id : ('new' + this.counter),
            hour_price: this.state.hour_price ? this.state.hour_price : null,
            salary_multiplier: this.state.salary_multiplier,
            salary_category: this.state.salary_category,
            overtime_threshold: this.state.overtime_threshold,
        });
        this.resetState();
        this.counter++;
    }

    handleClose() {
        this.resetState();
        if (typeof this.props.handleClose === 'function') {
            this.props.handleClose();
        }
    }

    handleDaysChange(days) {
        days = days.map(day => {
            day.day = day.value
            return day;
        });
        const orderedDays = this.handleDaysOrder(days);
        this.setState({ days: orderedDays });
    }

    handleDaysOrder(days) {
        let sunday = null
        const sundayIndex = days.findIndex(day => day.value === 0);
        if (sundayIndex > -1) {
            sunday = days.splice(sundayIndex, 1)[0];
        }
        const orderedDays = _.orderBy(days, ['value'], ['asc']);
        if (sunday) {
            orderedDays.push(sunday);
        }
        return orderedDays;
    }

    handleToggle() {
        this.setState({ useHourPrice: !this.state.useHourPrice }, () => {
            if (!this.state.useHourPrice) {
                this.setState({ hour_price: null });
            } else {
                this.setState({ salary_multiplier: null });
            }
        });
    }

    renderHeader() {
        return (
            <div className="padding">
                <h3>{tr('special_rules')}</h3>
            </div>
        );
    }

    renderBody() {
        return (
            <div className="padding">
                <ApSelect
                    multiselect
                    clearable
                    options={this.dayOptions}
                    optionRenderer="label"
                    objKeyId="value"
                    objKeySearchable="label"
                    valueRenderer="label"
                    value={this.state.days || []}
                    onChange={this.handleDaysChange}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                    label={tr('select_days')}
                    keepFocusOnSelect={true}
                />
                <div className="apInfo small">
                    <SvgIcon icon="info-circle" type="solid" /> {tr('hour_type_rule_day_info')}
                </div>
                <ApInputStack>
                    <ApInput
                        type="time"
                        id="start_time"
                        name="start_time"
                        label={tr('start_time')}
                        onChange={time => this.setState({ start_time: time })}
                        value={this.state.start_time}
                        clearable
                    />
                    <ApInput
                        type="time"
                        id="end_time"
                        name="end_time"
                        label={tr('end_time')}
                        onChange={time => this.setState({ end_time: time })}
                        value={this.state.end_time}
                        clearable
                    />
                </ApInputStack>
                {this.props.type === 'overtime' &&
                <>
                    <ApInputStack gap='0'>
                        <ApAddon>
                            {tr('overtime_threshold')}
                        </ApAddon>
                        <ApInput
                            type="number"
                            width="200"
                            id="overtime_threshold"
                            name="overtime_threshold"
                            label={tr('overtime_threshold')}
                            value={this.state.overtime_threshold}
                            onChange={e => this.setState({ overtime_threshold: e.target.value})}
                        />
                    </ApInputStack>
                    <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" /> {tr('overtime_threshold_info')}
                    </div>
                </>}
                <div className={"apFormGroup" + this.state.useHourPrice ? " success" : ""}>
                    <div className="apSwitchBlock">
                        <label htmlFor="extra-rule-use-hour-price-switch" className="info">
                            {tr('use_hour_price')}
                            <small>{tr('expense_tracking_salary_use_hour_price_info')}</small>
                        </label>
                        <ApSwitch
                            id="extra-rule-use-hour-price-switch"
                            name="useHourPrice"
                            on={this.state.useHourPrice}
                            onChange={() => this.handleToggle()}
                        />
                    </div>
                </div>
                {this.state.useHourPrice &&
                    <ApInputStack gap="0">
                        <ApAddon noRightBorder>
                            {tr('hour_price')}
                        </ApAddon>
                        <ApInput
                            width="200"
                            type="number"
                            id="hour_price"
                            name="hour_price"
                            align="right"
                            value={this.state.hour_price}
                            onChange={e => this.setState({ hour_price: e.target.value })}
                        />
                    </ApInputStack>
                }
                {!this.state.useHourPrice &&
                    <ApInputStack gap="0">
                        <ApAddon noRightBorder>
                            {tr('expense_tracking_salary_multiplier')}
                        </ApAddon>
                        <ApInput
                            width="200"
                            type="number"
                            id="salary_multiplier"
                            name="salary_multiplier"
                            align="right"
                            value={this.state.salary_multiplier}
                            onChange={e => this.setState({ salary_multiplier: e.target.value })}
                            tooltip={<span>{tr('expense_tracking_salary_multiplier_tooltip1')}<br />{tr('expense_tracking_salary_multiplier_tooltip2')}<br />{tr('expense_tracking_salary_multiplier_tooltip3')}</span>}
                        />
                    </ApInputStack>
                }
                <ApInputStack gap="0">
                    <ApAddon noRightBorder>
                        {tr('salary_type')}
                    </ApAddon>
                    <ApInput
                        width="200"
                        type="number"
                        id="salary_category"
                        name="salary_category"
                        align="right"
                        value={this.state.salary_category}
                        onChange={e => this.setState({ salary_category: e.target.value })}
                    // validationState={ this.state.salary_category ? "" : "error" }
                    />
                </ApInputStack>
            </div>
        );
    }

    renderFooter() {
        return (
            <div className="padding justify-space-between">
                <ApButton onClick={this.handleClose}>
                    <SvgIcon type="solid" icon="times" /> {tr('cancel')}
                </ApButton>
                <ApButton color="green" onClick={this.handleSave}>
                    <SvgIcon type="solid" icon="check" /> {tr('continue')}
                </ApButton>
            </div>
        );
    }

    render() {
        return (
            <ApModal
                className="narrow"
                show={this.props.show}
                handleClose={this.handleClose}
                header={this.renderHeader()}
                body={this.renderBody()}
                footer={this.renderFooter()}
            />
        );
    }
}

export default HourTypeExtraRuleModal;