import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';

import ApButton from 'common/ApButton/ApButton.js';
import ApModal from 'common/ApModal/ApModal.js';
import { ApInput } from 'common/ApInput/ApInput.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ComponentSelector from './ComponentSelector';
import {
    getTypeIcon
    , getTypeName
} from 'modules/Storage/common/StorageHelpers.js';
import {
    formatMoney
    , errorPopper
    , roundToDecimals
    , groupify
    , removeRoundOff
    , sqlToDateInput
    , onlyNumber
    , tr
    , capitalize
} from 'services/Helpers/Helpers.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';


import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import "./ImportPOComponentList.css"

class ImportPOComponentList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: [],
            splitModalComponent: {},
            editComponentModal: {},
        }
        autoBind(this);
    }

    // This is a workaround for a possible bug in react-beautiful-dnd causing scroll speed to slow down
    // https://github.com/atlassian/react-beautiful-dnd/issues/2441
    onDragStart() {
        document.documentElement.setAttribute("style", "scroll-behavior: auto");
    }

    onDragEnd(result) {
        document.documentElement.removeAttribute("style");
        // console.log(result);
        let items;

        const reorder = (tables, startIndex, endIndex) => {
            let sourceComponents = tables[parseInt(result.source.droppableId)].components;

            let [removed] = sourceComponents.splice(startIndex, 1);
            tables[parseInt(result.source.droppableId)].components = sourceComponents

            let destinationComponents = tables[parseInt(result.destination.droppableId)].components;

            //console.log(removed)
            destinationComponents.splice(endIndex, 0, removed);
            tables[parseInt(result.destination.droppableId)].components = destinationComponents

            return tables;
        };
        // dropped outside the list


        if (result.combine) {

            let tables = this.props.data;
            let sourceComponents = tables[parseInt(result.source.droppableId)].components;
            let itemIndex = result.source.index;
            // console.log("result.combine.draggableId", result.combine.draggableId)
            let destinationComponents = tables[parseInt(result.combine.droppableId)].components
            let index = parseInt(result.combine.draggableId.split("-")[1]);
            let destinationComponent = destinationComponents[index];
            let oldComponent = destinationComponent.linkedPoComponent ? { ...destinationComponent.linkedPoComponent } : null;

            if (destinationComponent && !destinationComponent.linkedComponent) {
                return;
            }

            let [removed] = sourceComponents.splice(itemIndex, 1);


            if (oldComponent && destinationComponent.id == oldComponent.id) {
                destinationComponent.count += removed.count;
                destinationComponent.unmodifiedCount += removed.unmodifiedCount;
            }
            else {
                if (oldComponent) {
                    destinationComponents.splice(itemIndex + 1, 0, oldComponent);
                }
                destinationComponent.linkedPoComponent = removed;
            }
            items = tables;
        }
        else if (!result.destination) {
            return;
        }
        else {
            items = reorder(
                this.props.data,
                result.source.index,
                result.destination.index
            );
            for (let i = 0; i < (items.length - 1); i++) {

                if (items[i].components.length == 0 && !Number.isInteger(items[i].linked_po_id) && !items[i].receiver) {
                    items.splice(i, 1);
                }
            }
            if (items[items.length - 1].components != 0) {
                items.push(this.props.getNewComponentTableData());
            }


        }

        items = this.props.calculateTableIdsAndIndexes(items);
        this.props.changeState({
            componentTables: items,
        });
    }
    calculateComponentIndexes(table) {
        this.props.data.map((table, tableId) => {
            table.components.map((c, tableIndex) => {
                c.tableId = tableId;
                c.tableIndex = tableIndex;
            });
        });
    }
    // addComponentToList(table,compnent,index) {
    //     table
    // }
    // removeComponentFromList(table,index) {

    // }
    removeLinkedComponent(component) {
        let linkedComponent = { ...component.linkedPoComponent };
        component.linkedPoComponent = null;
        let table = this.props.data[component.tableId];

        table.components.splice(component.tableIndex + 1, 0, linkedComponent);
        this.props.data[component.tableId] = table;
        this.calculateComponentIndexes(this.props.data);
        this.props.changeState({ componentTables: this.props.data });

    }

    splitComponent(component, count) {
        // console.log(component, count)
        // count = Math.round(count * 10000) / 10000       //0,9-0,1=0,7999999999...
        component.count = component.count;
        component.maxCount = component.maxCount - count;
        component.unmodifiedCount = component.maxCount;
        let componentClone = { ...component };
        componentClone.count = count;
        componentClone.maxCount = count;
        componentClone.unmodifiedCount = count;
        componentClone.multiselectId = this.props.getNextId();
        let table = this.props.data[component.tableId];
        table.components.splice(component.tableIndex + 1, 0, componentClone);
        this.props.data[component.tableId] = table;
        this.calculateComponentIndexes(this.props.data);
    }


    appendComponent(component, table) {
        let index;
        let found = false;
        for (let c of table.components) {
            if (!c.component_id) {
                continue;
            }
            if (c.component_id === component.component_id && c.tableIndex != component.tableIndex && !c.linkedComponent) {
                // console.log(component.count, c.count)
                c.count = parseFloat(component.count) + parseFloat(c.count);
                c.maxCount = parseFloat(c.count);
                c.unmodifiedCount = c.maxCount;
                // index = c.tableIndex;
                found = true;
                break;
            }
        }
        if (found) {
            table.components.splice(component.tableIndex, 1); //poistaa komponentin
            let tables = this.props.data;
            tables[table.index] = table;
            this.calculateComponentIndexes(tables);
            this.props.changeState({ componentTables: tables });

        }
    }

    editComponent(component, table) {
        component = { ...component }

        this.setState({ showEditComponent: true, editComponentModal: component });
    }

    saveEditComponent() {
        let component = this.state.editComponentModal;
        if (component.linkedPoComponent) {
            component.linkedPoComponent.price = component.price;
        }
        let tables = this.props.data;
        let table = tables[component.tableId];
        table.components[component.tableIndex] = component;
        tables[component.tableId] = table;
        this.props.changeState({ componentTables: tables });
        this.setState({ showEditComponent: false, editComponentModal: {} });
    }

    addComponentToTable(component, tableId, outside = false) {
        component.count = 1;
        component.maxCount = 1;
        component.unmodifiedCount = 1;

        this.changeCoponentInTable(component, tableId, null, outside);
    }

    changeCoponentInTable(component, tableId = null, tableIndex = null, outside = false) {

        if (component.tableId == null && tableId == null) {
            return null;
        }


        if (component.tableId != null) {
            tableId = component.tableId;
        }

        if (component.tableIndex != null) {
            tableIndex = component.tableIndex;
        }

        let tables = this.props.data;
        let table = tables[tableId];

        if (outside) {
            component.component_id = null;
            // component.name = c.import_name;
            component.save_as_new = null;
            component.code = null;
        }

        if (tableIndex == null) {
            table.components.push(component);
        }
        else {
            table.components[component.tableIndex] = component;
        }




        tables[component.tableId] = table;
        tables = this.props.calculateTableIdsAndIndexes(tables);
        this.props.changeState({ componentTables: tables });

    }

    setImportCount(component, value, blur = false) {
        value = onlyNumber(value);
        value = parseFloat(value);
        let maxCount = parseFloat(component.maxCount);
        let unmodifiedCount = parseFloat(component.unmodifiedCount);
        // console.log(component, value, component.maxCount);
        // console.log(value > component.maxCount);
        if (value > maxCount) {
            value = maxCount;
        }


        if (blur) {
            value = value.toFixed(8);
            value = parseFloat(value); // koska javascript matikka...
            // console.log(Number(value), value < component.unmodifiedCount, component.unmodifiedCount);
            if (value && value < unmodifiedCount) {
                let count = unmodifiedCount - value;
                count = parseFloat(count.toFixed(8));
                component.unmodifiedCount = value;
                this.splitComponent(component, count);
            }
        }
        component.count = value;
        this.props.changeState({ componentTables: this.props.data });

    }

    addNewRow(tableIndex) {

    }

    renderPOimportColumnsLinked(component, componentLinked, index, componentIndex) {
        //console.log(c);
        let receiverType = this.props.deliveryReceiverType;


        let replaceComponentExist = component.save_as_new ? tr('create_new_component') : tr('external_component');
        let replaceComponentLinkedExist = componentLinked.save_as_new ? tr('create_new_component') : tr('external_component');
        if (component.component_id != undefined && component.import_name != undefined) {
            replaceComponentExist = tr('existing_component');
        }
        else if (component.component_id != undefined && component.import_name == undefined) {
            replaceComponentExist = [tr('existing_component'), <br />, tr('defined_in_po')];

        }
        if (componentLinked.component_id != undefined && componentLinked.import_name != undefined) {
            replaceComponentLinkedExist = tr('existing_component');
        }
        else if (componentLinked.component_id != undefined && componentLinked.import_name == undefined) {
            replaceComponentLinkedExist = [tr('existing_component'), <br />, tr('defined_in_po')];
        }

        let componentExists = <td key="componentExists" className="fixed c-target">
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ paddingBottom: 20 }}>
                    {replaceComponentExist}
                </div>
                <div>
                    {replaceComponentLinkedExist}
                </div>
            </div>
        </td>;



        let className = ['componentRowInput'];
        let disabled = true;

        if (component.import_type == 'fivaldi-po') {
            disabled = false;
        }

        let vatPercent = component.vat_percent || 0;
        let vatPercentLinked = componentLinked.vat_percent || 0;
        let vat = (vatPercent / 100);
        let vatLinked = (vatPercentLinked / 100);
        const stockBalance = component.stocks && component.stocks.reduce((total, stock) => total + Number(stock.balance_available), 0) || 0;
        const stockBalanceLinked = componentLinked.stocks && componentLinked.stocks.reduce((total, stock) => total + Number(stock.balance_available), 0) || 0;
        let componentPrice = component.linkedComponent ? component.purchase_order_price : component.price;
        let componentPriceLinked = componentLinked.linkedComponent ? componentLinked.purchase_order_price : componentLinked.price;
        let componentPriceIncludeVat = componentPrice * (vat + 1);
        let componentPriceIncludeVatLinked = componentPriceLinked * (vatLinked + 1);
        let componentVatPrice = componentPrice * vat;
        let componentVatPriceLinked = componentPriceLinked * vatLinked;
        let componentCount = component.count;
        let componentCountLinked = componentLinked.count;

        let componentPriceDifference = Math.abs((componentPrice - componentPriceLinked));
        let componentPriceDifferenceTotal = Math.abs((componentPrice * componentCount - componentPriceLinked * componentCountLinked));

        let showPriceWarning = (componentPriceDifference / componentPrice) < 0.01 ? {} : { color: "red" }
        let data = [
            <td key="import_name" className="fixed c-target">
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ paddingBottom: 20 }}>
                        {component.import_name}
                    </div>
                    <div>
                        {componentLinked.import_name}
                    </div>
                </div>

            </td>,
            componentExists,
            <td key="count" className="fixed c-target">
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ paddingBottom: 20, position: "relative" }}>
                        <input
                            className={className.join(" ")}
                            type="text"
                            value={component.count ? component.count : ""}
                            onChange={(e) => {
                                this.setImportCount(component, e.target.value);
                            }}
                            onBlur={(e) => {
                                this.setImportCount(component, e.target.value, true);
                            }}
                            disabled={disabled}
                        />
                        <div className="unit">{component.unit}</div>
                    </div>
                    <div style={{ position: "relative" }}>
                        <input
                            className={className.join(" ")}
                            type="text"
                            value={componentLinked.count ? componentLinked.count : ""}
                            onChange={(e) => {
                                this.setImportCount(componentLinked, e.target.value, false);
                            }}
                            onBlur={(e) => {
                                this.setImportCount(componentLinked, e.target.value, true);
                            }}
                            onKeyDown={e => {
                                if (e.key == "Enter") {
                                    this.setImportCount(componentLinked, e.target.value, true);
                                }
                            }}
                        // disabled={disabled}
                        />
                        <div className="unit">{componentLinked.unit}</div>
                    </div>
                </div>

            </td>,
            <td
                className="fixed"
                style={{
                    color: stockBalance > 0 ? "var(--clr-warning-main)" : "inherit",
                }}
            >
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ paddingBottom: 20 }}>
                        <ApTooltip
                            text={
                                component.stocks &&
                                component.stocks.map((stock, index) => (
                                    <div key={index}>
                                        {stock.location.code} {stock.location.name}:{" "}
                                        {stock.balance_available} {component.unit}
                                    </div>
                                ))
                            }
                        >
                            {stockBalance} {component.unit}
                        </ApTooltip>
                    </div>
                    <div>
                        <ApTooltip
                            text={
                                componentLinked.stocks &&
                                componentLinked.stocks.map((stock, index) => (
                                    <div key={index}>
                                        {stock.location.code} {stock.location.name}:{" "}
                                        {stock.balance_available} {componentLinked.unit}
                                    </div>
                                ))
                            }
                        >
                            {stockBalanceLinked} {componentLinked.unit}
                        </ApTooltip>
                    </div>
                </div>

            </td>,

            <td key="price_single" className="fixed">
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ paddingBottom: 20, ...showPriceWarning }}>
                        {formatMoney(componentPrice)} {this.state.currencySign}
                        <br />
                        <small>
                            {formatMoney(componentPriceIncludeVat)} {this.state.currencySign}{" "}
                            ({tr("inc_vat")} {vatPercent}% {formatMoney(componentVatPrice)}{" "}
                            {this.state.currencySign})
                        </small>
                    </div>
                    <div style={{ ...showPriceWarning }}>
                        {formatMoney(componentPriceLinked)} {this.state.currencySign}
                        <br />
                        <small>
                            {formatMoney(componentPriceIncludeVatLinked)} {this.state.currencySign}{" "}
                            ({tr("inc_vat")} {vatPercentLinked}% {formatMoney(componentVatPriceLinked)}{" "}
                            {this.state.currencySign})
                        </small>
                    </div>
                </div>

            </td>,

            <td key="price_total" className="fixed">
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ paddingBottom: 20, ...showPriceWarning }}>
                        {formatMoney(componentPrice * componentCount)}{" "}
                        {this.state.currencySign}
                        <br />
                        <small>
                            {formatMoney(componentPriceIncludeVat * componentCount)}{" "}
                            {this.state.currencySign} ({tr("inc_vat")} {vatPercent}%{" "}
                            {formatMoney(componentVatPrice * componentCount)}{" "}
                            {this.state.currencySign})
                        </small>
                    </div>
                    <div style={{ ...showPriceWarning }}>
                        {formatMoney(componentPriceLinked * componentCountLinked)}{" "}
                        {this.state.currencySign}
                        <br />
                        <small>
                            {formatMoney(componentPriceIncludeVatLinked * componentCountLinked)}{" "}
                            {this.state.currencySign} ({tr("inc_vat")} {vatPercentLinked}%{" "}
                            {formatMoney(componentVatPriceLinked * componentCountLinked)}{" "}
                            {this.state.currencySign})
                        </small>
                    </div>
                </div>

            </td>,
        ];

        return data;
    }

    renderPOimportColumns(c, header = false) {
        //console.log(c);
        let receiverType = this.props.deliveryReceiverType;

        if (header) {
            let data = [
                tr('name_in_po'),
                tr('status'),
                tr('amount'),
                tr('in_other_storages'),
                tr('price'),
                tr('total_price'),
            ];

            return data.map((n, i) => <th key={i}>{n}</th>);
        }
        let componentExists = <td key="componentExists" className="fixed c-target">
            {c.save_as_new ? tr('create_new_component') : tr('external_component')}
        </td>;

        if (c.component_id != undefined && c.import_name != undefined) {
            if (c.temporary) {
                componentExists = <td key="existing_component" className="fixed c-target">
                    {tr('existing_temporary_component')}
                </td>
            }
            else {
                componentExists = <td key="existing_component" className="fixed c-target">
                    {tr('existing_component')}
                </td>
            }
        }
        else if (c.component_id != undefined && c.import_name == undefined) {
            if (c.temporary) {
                componentExists = <td key="existing_component" className="fixed c-target">
                    {tr('existing_temporary_component')}<br />{tr('defined_in_po')}
                </td>
            }
            else {
            componentExists = <td key="existing_component" className="fixed c-target">
                {tr('existing_component')}<br />{tr('defined_in_po')}
            </td>
            }
        }

        let className = ['componentRowInput'];
        let disabled = this.props.disabled;

        // if ( c.import_type == 'fivaldi-po' ) {
        //     disabled = false;
        // }
        if (c.linkedComponent) {
            disabled = true;
        }

        let vatPercent = c.vat_percent || 0;
        let vat = (vatPercent / 100);
        const stockBalance = c.stocks && c.stocks.reduce((total, stock) => total + Number(stock.balance_available), 0) || 0;
        let componentPrice = c.linkedComponent ? c.purchase_order_price : c.price;
        let componentPriceIncludeVat = componentPrice * (vat + 1);
        let componentVatPrice = componentPrice * vat;
        let componentCount = c.count;
        let data = [
            <td key="import_name" className="fixed c-target">
                {c.import_name}
            </td>,
            componentExists,
            <td key="count" className="fixed c-target">
                <input
                    className={className.join(" ")}
                    type="text"
                    value={c.count ? c.count : ""}
                    onChange={(e) => {
                        this.setImportCount(c, e.target.value);
                    }}
                    onBlur={(e) => {
                        this.setImportCount(c, e.target.value, true);
                    }}
                    onKeyDown={e => {
                        if (e.key == "Enter") {
                            this.setImportCount(c, e.target.value, true);
                        }
                    }}
                    disabled={disabled}
                />
                <div className="unit">{c.unit}</div>
            </td>,
            <td
                className="fixed"
                style={{
                    color: stockBalance > 0 ? "var(--clr-warning-main)" : "inherit",
                }}
            >
                <ApTooltip
                    text={
                        c.stocks &&
                        c.stocks.map((stock, index) => (
                            <div key={index}>
                                {stock.location.code} {stock.location.name}:{" "}
                                {stock.balance_available} {c.unit}
                            </div>
                        ))
                    }
                >
                    {stockBalance} {c.unit}
                </ApTooltip>
            </td>,

            <td key="price_single" className="fixed">
                {formatMoney(componentPrice)} {this.state.currencySign}
                <br />
                <small>
                    {formatMoney(componentPriceIncludeVat)} {this.state.currencySign}{" "}
                    ({tr("inc_vat")} {vatPercent}% {formatMoney(componentVatPrice)}{" "}
                    {this.state.currencySign})
                </small>
            </td>,

            <td key="price_total" className="fixed">
                {formatMoney(componentPrice * componentCount)}{" "}
                {this.state.currencySign}
                <br />
                <small>
                    {formatMoney(componentPriceIncludeVat * componentCount)}{" "}
                    {this.state.currencySign} ({tr("inc_vat")} {vatPercent}%{" "}
                    {formatMoney(componentVatPrice * componentCount)}{" "}
                    {this.state.currencySign})
                </small>
            </td>,
        ];

        return data;


    }
    selectRow(c) {
        let selected = this.props.selected.slice(0);
        if (selected.includes(c.multiselectId))
            selected = selected.filter(s => s !== c.multiselectId);
        else
            selected.push(c.multiselectId);
        this.props.changeState({ selected: selected });
    }

    renderSelect(c, selected) {
        //if (this.props.instance !== 'order') return null;

        let disabled = this.props.disabled
        if (this.props.nameLocked) disabled = true;
        if (disabled) return null;
        return <input
            type="checkbox"
            checked={selected}
            readOnly
        />
    }


    renderSelect(c, header = false) {
        if (header) {
            return <th></th>;
        }
        const selected = this.props.selected.includes(c.multiselectId);
        return <td className="selectTd" onClick={() => this.selectRow(c)}>
            <input
                type="checkbox"
                checked={selected}
                readOnly
            />
        </td>

        // <span style={{ position: 'absolute', left: '0', top: '0'  }} >{ c.id }</span>
    }

    renderPoIcon(c, header = false) {
        if (header) {
            return <th></th>;
        }

        return c.linkedComponent
            ? <ApTooltip text={tr('defined_in_po')} block position="topleft">
                <SvgIcon type='solid' icon='clipboard-check' fill='var(--clr-success-main)' />
            </ApTooltip>
            : null


        // <span style={{ position: 'absolute', left: '0', top: '0'  }} >{ c.id }</span>
    }
    renderType(c, header = false) {
        if (header) {
            return <th>{tr("type")}</th>;
        }
        let icon = null;
        if (c.type_name === 'item')
            icon = <SvgIcon icon="puzzle-piece" type="solid" />
        else if (c.type_name === 'work')
            icon = <SvgIcon icon="user-clock" type="solid" />
        else if (c.type_name === 'other')
            icon = <SvgIcon icon="money-bill-wave" type="solid" />

        return <ApTooltip position="topleft" text={c.type_text ? tr(c.type_text) : null}>
            {icon}
        </ApTooltip>

        // <span style={{ position: 'absolute', left: '0', top: '0'  }} >{ c.id }</span>
    }
    setNamePOimport(component, value) {
        let tables = this.props.data;
        let table = tables[component.tableId];
        let c = table.components[component.tableIndex];
        c.name = value;

        this.props.changeState({ componentTables: tables });

    }

    renderName(c, header = false) {
        if (header) {
            return <th className='componentName'>{tr("name")}</th>;
        }
        if (c.component_id) {
            if (this.props.instance === 'assignment') {
                return (
                    <ApTooltip position='topleft' text={<div>{c.name} <br /> {c.code ? c.code : ""}</div>}>
                        <div>
                            <span className="name noWordWrap">{c.name}</span>
                            <small className="noWordWrap">{c.code}</small>
                        </div>
                    </ApTooltip>
                );
            }
            if (this.props.instance === 'po-import') {
                return (
                    <ApTooltip position='topleft' text={<div>{c.name} <br /> {c.code ? c.code : ""}</div>}>
                        <div>
                            <small className="noWordWrap">{c.code}</small>
                            <input
                                className='componentNameInput noWordWrap'
                                type="text"
                                value={c.name ? c.name : ''}
                                onChange={(e) => { this.setNamePOimport(c, e.target.value) }}
                                disabled={c.linkedComponent || this.props.disabled}
                            />
                        </div>
                    </ApTooltip>
                );
            }

            return <div>
                <span title={c.name} className="name noWordWrap">{c.name}</span>
                <small title={c.code} className="noWordWrap">{c.code}</small>
            </div>
        }

        let disabled = this.props.disabled;
        if (this.props.nameLocked) disabled = true;

        let className = ['componentNameInput'];
        if (!c.name) className.push('input-error');

        if (disabled) return <div><span title={c.name} className="noWordWrap">{c.name}</span></div>

        if (this.props.instance == 'po-import') {
            return <input
                className={className.join(' ')}
                type="text"
                value={c.name ? c.name : ''}
                onChange={(e) => { this.setNamePOimport(c, e.target.value) }}
                disabled={disabled}
            />
        }

        return <input
            className={className.join(' ')}
            type="text"
            value={c.name ? c.name : ''}
            onChange={(e) => { this.setName(c, e.target.value) }}
            disabled={disabled}
        />
    }
    showSplitModal(component) {
        this.setState({ showSplitModal: true, splitModalComponent: component });
    }




    renderEditComponentModal() {
        return <ApModal
            closeFromBg
            show={Boolean(this.state.showEditComponent)}
            handleClose={() => this.setState({ showEditComponent: false, editComponentModal: {} })}
            className="narrow overflow"

            header={
                <div className="padding">
                    <h4>
                        {tr('edit_row')}
                    </h4>
                </div>
            }
            body={<div className="padding">
                <h5>
                    {tr('component') + ':'}

                </h5>
                <h4 style={{ marginBottom: "7px" }}>
                    {this.state.editComponentModal.name}
                </h4>

                <ApInput

                    //options={types}
                    type="number"
                    id="editCount"
                    name="editCount"
                    label={tr('amount')}
                    value={this.state.editComponentModal.count ? this.state.editComponentModal.count : 0}
                    onChange={(e) => {
                        let editComponentModal = this.state.editComponentModal;
                        editComponentModal.count = e.target.value;
                        editComponentModal.maxCount = e.target.value;
                        editComponentModal.unmodifiedCount = e.target.value;
                        this.setState({ editComponentModal });
                    }}
                />
                <ApInput
                    //options={types}
                    type="number"
                    id="editPrice"
                    name="editPrice"
                    label={tr('unit_price')}
                    value={this.state.editComponentModal.price ? this.state.editComponentModal.price : ''}
                    onChange={(e) => {
                        let editComponentModal = this.state.editComponentModal;
                        editComponentModal.price = e.target.value;

                        this.setState({ editComponentModal });
                    }}
                />
                <ApInput
                    //options={types}
                    type="text"
                    id="editUnit"
                    name="editUnit"
                    label={tr('unit')}
                    value={this.state.editComponentModal.unit ? this.state.editComponentModal.unit : ''}
                    onChange={(e) => {
                        let editComponentModal = this.state.editComponentModal;
                        editComponentModal.unit = e.target.value;

                        this.setState({ editComponentModal });
                    }}
                />
                
                <ApInput
                    //options={types}
                    type="number"
                    id="editVat"
                    name="editVat"
                    label={tr('vat_percent')}
                    value={this.state.editComponentModal.vat_percent ? this.state.editComponentModal.vat_percent : ''}
                    onChange={(e) => {
                        let editComponentModal = this.state.editComponentModal;
                        editComponentModal.vat_percent = e.target.value > 0 ? e.target.value : 0;

                        this.setState({ editComponentModal });
                    }}
                />
            </div>}
            footer={<div className="padding text-right">

                <ApButton onClick={() => this.setState({ showEditComponent: false, editComponentModal: {} })}>
                    <SvgIcon icon="times" type="solid" />
                    {tr('cancel')}
                </ApButton>

                <ApButton color="green" onClick={this.saveEditComponent}>
                    <SvgIcon icon="check" type="solid" />
                    {tr('continue')}
                </ApButton>

            </div>}
        />
    }

    renderComponentSplitModal() {
        return <ApModal
            closeFromBg
            show={Boolean(this.state.showSplitModal)}
            handleClose={() => this.setState({ showSplitModal: false, splitModalComponent: {} })}
            className="narrow overflow"

            header={
                <div className="padding">
                    <h4>
                        {tr('split_component_by_percent_or_count')}
                    </h4>
                </div>
            }
            body={<div className="padding">
                <ApInput
                    //options={types}
                    type="text"
                    id="slpitCount"
                    name="slpitCount"
                    label={tr('amount')}
                    value={this.state.splitModalComponent.splitCount ? this.state.splitModalComponent.splitCount : ''}
                    onChange={(e) => {
                        let splitModalComponent = this.state.splitModalComponent;
                        splitModalComponent.splitCount = e.target.value;
                        splitModalComponent.splitPercent = e.target.value / splitModalComponent.count * 100;
                        this.setState({ splitModalComponent })
                    }}
                />
                <ApInput
                    //options={types}
                    type="percent"
                    id="splitPercent"
                    name="splitPercent"
                    label={tr('percent')}
                    value={this.state.splitModalComponent.splitPercent ? this.state.splitModalComponent.splitPercent : ''}
                    onChange={(e) => {
                        let splitModalComponent = this.state.splitModalComponent;
                        splitModalComponent.splitPercent = e.target.value;
                        splitModalComponent.splitCount = (e.target.value / 100 * splitModalComponent.count);
                        this.setState({ splitModalComponent })
                    }}
                />
            </div>}
            footer={<div className="padding text-right">

                <ApButton onClick={() => this.setState({ showSplitModal: false, splitModalComponent: {} })}>
                    <SvgIcon icon="times" type="solid" />
                    {tr('cancel')}
                </ApButton>

                <ApButton color="green" onClick={this.splitAccept}>
                    <SvgIcon icon="check" type="solid" />
                    {tr('continue')}
                </ApButton>

            </div>}
        />
    }

    splitAccept() {
        let splitComponent = this.state.splitModalComponent;

        let table = this.props.data[splitComponent.tableId];
        let component = table.components[splitComponent.tableIndex];
        component.count = splitComponent.count - this.state.splitModalComponent.splitCount;
        component.maxCount = component.count;
        component.unmodifiedCount = component.count;

        table[splitComponent.tableIndex] = component;
        this.splitComponent(this.state.splitModalComponent, this.state.splitModalComponent.splitCount);
        this.calculateComponentIndexes(this.props.data);

        this.setState({ showSplitModal: false, splitModalComponent: {}, componentTables: table, })
    }


    renderMenu(c, header = false) {
        if (header) {
            return <th style={{ minWidth: '50px', width: '50px', maxWidth: '50px' }}></th> /* Actions cell */
        }
        if (!c.component_id && this.props.instance != 'po-import') {
            if (!this.props.removeComponentLocked && this.props.instance != 'assignment') {
                return <SvgIcon
                    icon="trash-alt"
                    onClick={() => this.removeComponent(c)}
                    className="icon-button"
                />
            }
            return;
        }

        let actions = [];

     

        if (this.props.instance == 'po-import') {
            if (c.linkedPoComponent) {
                actions.push({
                    label: tr("detach_component"),
                    icon: "minus",
                    action: (id, closeFunc) => {
                        this.removeLinkedComponent(c);
                        closeFunc();
                    },
                });

            }
            else {
                actions.push({
                    label: tr("split_component"),
                    icon: "plus",
                    action: (id, closeFunc) => {
                        this.showSplitModal(c);
                        closeFunc();
                    },
                });
            }
            if (c.import_name != null) {
                actions.push({
                    label: tr('link_to_previous'),
                    icon: "link",
                    action: (id, closeFunc) => {
                        this.props.linkComponent(c);
                        closeFunc();
                    },
                });

                actions.push({
                    label: tr("change_type"),
                    icon: "exchange-alt",
                    disabled: c.component_id != null,
                    action: (id, closeFunc) => {
                        this.props.changeType(c);
                        closeFunc();
                    },
                });

                actions.push({
                    label: tr("add_with_new_component"),
                    icon: "plus-circle",
                    action: (id, closeFunc) => {
                        this.props.saveAsNew(c);
                        closeFunc();
                    },
                });

                actions.push({
                    label: tr("add_with_outside_component"),
                    icon: "plus",
                    action: (id, closeFunc) => {
                        this.props.saveAsOutside(c);
                        closeFunc();
                    },
                });

                actions.push({
                    label: tr("attach_splitted_components"),
                    icon: "plus-square",
                    action: (id, closeFunc) => {
                        this.appendComponent(c, this.props.data[c.tableId]);
                        closeFunc();
                    },
                });

                actions.push({
                    label: tr("edit"),
                    icon: "edit",
                    action: (id, closeFunc) => {
                        this.editComponent(c, this.props.data[c.tableId]);
                        closeFunc();
                    },
                });
                actions.push({
                    label: tr('delete'),
                    icon: "trash-alt",
                    action: (id, closeFunc) => {
                        this.props.removeComponent(c);
                        closeFunc();
                    },
                });

            } else {
                actions.push({
                    label: tr("change_type"),
                    icon: "exchange-alt",
                    action: (id, closeFunc) => {
                        this.props.changeType(c);
                        closeFunc();
                    },
                });

                actions.push({
                    label: tr("add_with_new_component"),
                    icon: "plus-circle",
                    action: (id, closeFunc) => {
                        this.props.saveAsNew(c);
                        closeFunc();
                    },
                });
                actions.push({
                    label: tr("attach_splitted_components"),
                    icon: "plus-square",
                    action: (id, closeFunc) => {
                        this.appendComponent(c, this.props.data[c.tableId]);
                        closeFunc();
                    },
                });

                actions.push({
                    label: tr("edit"),
                    icon: "edit",
                    action: (id, closeFunc) => {
                        this.editComponent(c, this.props.data[c.tableId]);
                        closeFunc();
                    },
                });
                actions.push({
                    label: tr('delete'),
                    icon: "trash-alt",
                    action: (id, closeFunc) => {
                        this.props.removeComponent(c);
                        closeFunc();
                    },
                    disabled: c.linkedComponent,
                });
            }
        }

        return <ApDropdown
            actions={actions}
            disabled={this.props.disabled}
        />

    }
    showReceiverEdit(tableData, index) {
        tableData.index = index;
        this.props.changeState({ receiverEditShow: true, receiverEditShowData: tableData });
    }
    ActionEditModalOpen(tableData, index) {
        // console.log("index", index);
        // console.log("tableData", tableData);
        // tableData.index = index;
        this.props.changeState({
            action_edit: tableData.action, edit_action_show: true, linked_po_edit: tableData.linked_po,
            ActionEditModalOpenData: tableData,
        });
    }



    tableActions(tableData, index) {
        // console.log('tableData', tableData)
        const editSupplier = (field) => {
            return <dd onClick={() => this.showReceiverEdit(tableData, index)}>{tableData[field]}</dd>
        }
        let receiverTitle = null;
        if (tableData?.receiver?.full_name) {
            receiverTitle = tableData.receiver.full_name;
        }
        else if (tableData.receiver_title) {
            receiverTitle = tableData.receiver_title;
        }
        let poAction = <div style={{ display: "flex" }}>
            <div className="apFormColumn">
                <dl className="dl-horizontal  pointer" onClick={() => this.ActionEditModalOpen(tableData, index)}>
                    <dt>{tr('status')}</dt>
                    <dd>{tr('new_purchase_order')}</dd>
                </dl>

                <dl className="dl-horizontal pointer">
                    <dt onClick={() => this.showReceiverEdit(tableData, index)}>
                        {tr('recipient')}
                    </dt>
                    <dd onClick={() => this.showReceiverEdit(tableData, index)}>
                        {Boolean(tableData.receiver_type) && <ApTooltip text={getTypeName(tableData.receiver_type)}>
                            <SvgIcon icon={getTypeIcon(tableData.receiver_type)} type="solid" />
                        </ApTooltip>}
                        {tableData.receiver_name == null ? <div style={{ color: '#ff4444' }}>{tr('storage_location_not_defined')}</div> : tableData.receiver_name}
                    </dd>
                    <dd onClick={() => this.showReceiverEdit(tableData, index)}>
                        {(Boolean(tableData.receiver) && Boolean(tableData.receiver.project_code) || Boolean(tableData.receiverSubProjectCode))
                            && tableData.receiver_type == "project" && <div>
                                {tableData.projectFullName ? (tableData.projectFullName) : null} <br />
                                {/* {receiverTitle }<br /> */}
                                {tableData.receiverSubProjectCode == null ? tableData.receiver.project_code : tableData.receiverSubProjectCode}
                            </div>}
                    </dd>
                </dl>
            </div>
            <div className="apFormColumn">
                <dl className="dl-horizontal">
                    <dt>{tr('supplier')}</dt>
                    {tableData.supplier_id == null && <dd>{tr('new_supplier')}</dd>}

                    <dd>{tableData.supplier_name}</dd>
                    <dd>{tableData.supplier_taxnumber}</dd>
                </dl>
                <dl className="dl-horizontal pointer">
                    <dt onClick={() => this.showReceiverEdit(tableData, index)}>
                        {tr('shipping_address')}
                    </dt>
                    <dt></dt>{editSupplier('receiver_title')}
                    <dt></dt>{editSupplier('receiver_address')}
                    <dt></dt>{editSupplier('receiver_zipcode')}
                    <dt></dt>{editSupplier('receiver_city')}
                    <dt></dt>{editSupplier('receiver_country')}
                </dl>

            </div>
        </div>

        if (tableData.action != 'new') {
            poAction = <div>
                <div className="apFormColumn">
                    <dl className="dl-horizontal pointer" onClick={() => this.ActionEditModalOpen(tableData, index)}>
                        <dt>{tr('status')}</dt>
                        <dd>{tr('existing_purchase_order')}</dd>
                    </dl>

                    <dl className="dl-horizontal">
                        <dt>{tr('project')}</dt>
                        <dd>{tableData.linked_po.project_full_name || "-"}</dd>
                    </dl>
                    <dl className="dl-horizontal">
                        <dt>{tr('supplier')}</dt>
                        <dd>{tableData.linked_po.supplier_name}</dd>
                    </dl>
                </div>
                <div className="apFormColumn">
                    <dl className="dl-horizontal">
                        <dt>{tr('number')}</dt>
                        <dd>{tableData.linked_po.number}</dd>
                        <dd>{tableData.linked_po.receiver_title}</dd>
                        <dd>{tableData.linked_po.receiver_name}</dd>
                    </dl>
                </div>
            </div>


        }

        return poAction;
    }

    renderRow(component, index, componentIndex) {

        let row = [];
        if (component.linkedPoComponent) {
            row.push(<td>
                <div >{this.renderSelect(component)}</div>
                {/* <div>
                    <div style={{paddingBottom:20}}>{this.renderSelect(component)}</div>
                    <div>{this.renderSelect(component.linkedPoComponent)}</div>
                </div> */}
            </td>);
            row.push(<td className="groupTd">
                <div>
                    <div style={{ paddingBottom: 20 }}>{this.renderPoIcon(component)}</div>
                    <div>&nbsp;</div>
                </div>
            </td>);
            row.push(<td className="fixed c-type">
                <div>
                    <div style={{ paddingBottom: 20 }}>{this.renderType(component)}</div>
                    <div>{this.renderType(component.linkedPoComponent)}</div>
                </div>
            </td>);
            row.push(<td className="c-name">
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ paddingBottom: 20 }}>{this.renderName(component)}</div>
                    <div>{this.renderName(component.linkedPoComponent)}</div>
                </div>
            </td>);


            row.push(this.renderPOimportColumnsLinked(component, component.linkedPoComponent, index, componentIndex));
   
            row.push(<td className="fixed actions" style={{ display: "flex", minWidth: '50px', width: '50px', maxWidth: '50px' }}>
                {this.renderMenu(component)}
            </td>);
        }
        else {
            row.push(this.renderSelect(component));
            row.push(<td className="groupTd">{this.renderPoIcon(component)}</td>);
            row.push(<td className="fixed c-type">
                {this.renderType(component)}
            </td>);
            row.push(<td className="c-name">
                {this.renderName(component)}
            </td>);
            row.push(this.renderPOimportColumns(component, false));
            row.push(<td className="fixed actions" style={{ minWidth: '50px', width: '50px', maxWidth: '50px' }}>
                {this.renderMenu(component)}
            </td>);

        }




        return row;
    }


    render() {
        const getItems = count =>
            Array.from({ length: count }, (v, k) => k).map(k => ({
                id: `item-${k}`,
                content: `item ${k}`
            }));

        // a little function to help us with reordering the result
        const reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        };

        const grid = 8;

        const getItemStyle = (isDragging, snapshot, draggableStyle, component) => {
            // console.log(snapshot, snapshot.combineWith);
            let backgroundColor = "#fafafa";
            if (isDragging) {
                //"draggingOver" is always false. why?
                //"combineWith" Hovering over element combineWith !=null
                if (snapshot.combineWith) {
                    backgroundColor = "#a7dbf9";
                }
                else {
                    backgroundColor = "lightgreen"
                }
            }
            else {
                if (component.linkedComponent) {
                    backgroundColor = 'hsl(123deg 40% 92%)';
                }
            }
            return {


                // some basic styles to make the items look a bit nicer
                userSelect: "none",
                //padding: grid * 2,
                //margin: `0 0 ${grid}px 0`,

                // change background colour if dragging
                background: backgroundColor,
                height: component.linkedPoComponent ? 140 : 70,
                // styles we need to apply on draggables
                ...draggableStyle,


            }
        };

        const getListStyle = (isDraggingOver, table) => ({
            background: isDraggingOver ? "lightblue" : "#eeeeee",
            //padding: grid,
            //width: 250
            minHeight: table.linked_po_id ? 150 : 75,
            marginBottom: 15,
            border: "1px solid #aaa",
            //borderRadius: "25px",
        });



        return (
            <div id="ImportPOComponentList">

                <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
                    {this.props.data.map((dropBox, index) => {
                        // console.log(dropBox);
                        return <Droppable droppableId={index + ""} isCombineEnabled={true} key={index}>
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver, dropBox)}
                                >
                                    {this.tableActions(dropBox, index)}
                                    {dropBox.components.length > 0 && <table className="componentTable" style={{ width: "100%" }}>
                                        <thead className="table-head">
                                            <tr>

                                                {this.renderSelect({}, true)}
                                                {this.renderPoIcon({}, true)}
                                                {this.renderType({}, true)}
                                                {this.renderName({}, true)}
                                                {this.renderPOimportColumns({}, true)}
                                                {this.renderMenu({}, true)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dropBox.components.map((component, index2) => (
                                                <Draggable key={index + "-" + index2} draggableId={index + "-" + index2} index={index2}
                                                    isDragDisabled={component.linkedComponent || this.props.disabled}>
                                                    {(provided, snapshot2) => (
                                                        <tr
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot2.isDragging,
                                                                snapshot2,
                                                                provided.draggableProps.style,
                                                                component
                                                            )}
                                                        >
                                                            {this.renderRow(component, index, index2)}





                                                        </tr>
                                                    )}
                                                </Draggable>
                                            ))}



                                        </tbody>
                                    </table>}
                                    {provided.placeholder}
                                    <ComponentSelector
                                        key={index}
                                        id={index}
                                        instance='po'
                                        addComponent={(component, tableId, outside) => this.addComponentToTable(component, tableId, outside)}
                                        supplierId={this.props.supplier_id}
                                        getNextId={this.props.getNextId}
                                        disabled={this.props.disabled}
                                    />

                                </div>
                            )}
                        </Droppable>
                    })}
                </DragDropContext>
                {this.renderComponentSplitModal()}
                {this.renderEditComponentModal()}
            </div>
        );
    }
};

ImportPOComponentList.propTypes = {
    onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.string]), // Use string (url) if you wanna redirect
    size: PropTypes.string,
    color: PropTypes.string,
    ghost: PropTypes.bool,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    narrow: PropTypes.bool,
    loading: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

export default ImportPOComponentList;

