import ApButton from "common/ApButton/ApButton";
import ApFileUpload from "common/ApFileUpload/ApFileUpload";
import { ApAddon, ApInput, ApInputStack } from "common/ApInput/ApInput";
import ApModal from "common/ApModal/ApModal";
import SvgIcon from "common/SvgIcon/SvgIcon";
import React, { useRef, useState } from "react";
import { errorPopper, getFileById, hasPermissions, tr } from "services/Helpers/Helpers";
import api from 'services/Api/Api';
import ApTooltip from "common/ApTooltip/ApTooltip";

import './MaterialCertificates.css';
import ApSelect from "common/ApSelect/ApSelect";

const initialEntryData = {
    id: null,
    name: '',
    files: [],
    newFileIds: [],
    removeFileIds: [],
};

const MaterialCertificateEdit = (props) => {
    const [entryData, setEntryData] = useState(initialEntryData);
    const [loading, setLoading] = useState(false);
    const fileUploadRef = useRef(null);

    const onOpen = () => {
        if (props.selectedRow) {
            setEntryData(props.selectedRow);
        }
    }

    const onClose = (newEntry = null) => {
        setEntryData(initialEntryData);
        props.onClose(newEntry);
    }

    const renderHeader = () => {
        return (
            <div className="padding">
                <h4>{tr('material_certificate')}</h4>
            </div>
        );
    }

    const renderBody = () => {
        return (
            <div className='padding'>
                <ApInput
                    id='name'
                    name='name'
                    label={tr('identifier')}
                    value={entryData.name}
                    onChange={(e) => setEntryData({ ...entryData, name: e.target.value })}
                    disabled={loading}
                    validationState={entryData.name ? '' : 'error'}
                    readOnly={!hasPermissions('material_certificates.manage')}
                />

                {/* Storage component select */}
                <ApSelect
                    id='storage_component'
                    name='storage_component'
                    label={tr('storage_component')}
                    value={entryData.component}
                    onChange={(storageComponent) => setEntryData({ ...entryData, component: storageComponent })}
                    disabled={loading}
                    readOnly={!hasPermissions('material_certificates.manage')}
                    optionRenderer="component_detail"
                    objKeyId="id"
                    objKeyValue="name"
                    apiUrl='search/components'
                    apiData={{
                        formatter: 'select',
                        // addToSelect: ['id', 'name', 'code', 'unit'],
                        include: ['identifiers']
                    }}
                    clearable
                />

                <ApSelect 
                    id='project'
                    name='project'
                    label={tr('project')}
                    value={entryData.project}
                    onChange={(project) => setEntryData({ ...entryData, project: project })}
                    disabled={loading}
                    readOnly={!hasPermissions('material_certificates.manage')}
                    optionRenderer="project"
                    objKeyId="id"
                    objKeyValue="name"
                    apiUrl='report/projects/find'
                    apiData={{
                        formatter: 'select',
                        withOffers: true,
                        // withoutClosed: true,
                        addToSelect: ['id', 'name', 'project_code'],
                    }}
                    clearable
                />
                {entryData.files?.map((certFile, index) => {
                    if (entryData.removeFileIds?.includes(certFile.id)) return null;
                    return (
                        <ApInputStack gap="0" key={index}>
                            <ApTooltip text={tr('download_file')} block>
                                <ApAddon
                                    onClick={() => getFileById(
                                        certFile.file.id,
                                        certFile.file.original_name,
                                        setLoading,
                                    )}
                                    className="pointer"
                                >
                                    {certFile.file.original_name}
                                </ApAddon>
                            </ApTooltip>
                            {hasPermissions('material_certificates.manage') &&
                            <ApAddon width="50">
                                <ApTooltip text={tr('delete')}>
                                    <SvgIcon
                                        className="pointer deleteFileBtn"
                                        type="solid"
                                        icon="trash"
                                        onClick={() => setEntryData(
                                            {
                                                ...entryData,
                                                removeFileIds: [...(entryData.removeFileIds || []), certFile.id]
                                            })}
                                    />
                                </ApTooltip>
                            </ApAddon>}
                        </ApInputStack>
                    )
                })}
                {hasPermissions('material_certificates.manage') &&
                <ApFileUpload
                    // ref={ref => fileUploadRef = ref}
                    ref={fileUploadRef}
                    onChange={(newFileIds) => {
                        const fileIds = newFileIds.map(file => file.id);
                        setEntryData({ ...entryData, newFileIds: fileIds });
                    }}
                    // description={"File for assignment " + props.id}
                    maxSize={1024 * 1024 * 20} // 20 Mt
                    disabled={loading}
                    noFilesLabel={tr('add_files')}
                    noFilesSubLabel={tr('add_files')}
                    addButtonLabel={`${tr('add_file')}...`}
                />}

                <ApInput
                    type='textarea'
                    rows={3}
                    id='description'
                    name='description'
                    label={tr('description')}
                    value={entryData.description}
                    onChange={(e) => setEntryData({ ...entryData, description: e.target.value })}
                    disabled={loading}
                    readOnly={!hasPermissions('material_certificates.manage')}
                />
            </div>
        );
    }

    const renderFooter = () => {
        const hasFiles = entryData.newFileIds?.length > 0 || entryData.files?.length > 0;
        return (
            <div className="padding justify-space-between">
                <ApButton onClick={() => onClose()}><SvgIcon type="solid" icon="times" /> {tr('back')}</ApButton>
                {hasPermissions('material_certificates.manage') &&
                <ApButton onClick={saveData} color="blue" disabled={!entryData.name || !hasFiles}>
                    <SvgIcon type="solid" icon="save" /> {tr('save')}
                </ApButton>}
            </div>
        );
    }

    const saveData = () => {   
        if (!hasPermissions('material_certificates.manage')) {
            errorPopper(null, tr('not_enough_permissions'));
            return;
        }
        setLoading(true);
        api({
            method: 'post',
            url: 'materialcertificates/save',
            data: entryData,
        }).then(response => {
            onClose(response);
            errorPopper(null, tr('saved'), 4000, 'success');
        }).catch(error => {
            console.error(error);
            errorPopper(error, tr('save_error'));
        }).finally(() => {
            setLoading(false);
            fileUploadRef.current.resetFiles();
        });
    }

    return (
        <div id="materialCertificateEdit">
            <ApModal
                className="narrow"
                show={props.show}
                onOpen={onOpen}
                onClose={() => onClose()}
                handleClose={() => onClose()}
                header={renderHeader()}
                body={renderBody()}
                footer={renderFooter()}
                closeFromBg
            />
        </div>
    );
}

export default MaterialCertificateEdit;