/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import Datetime from 'react-datetime';

import ApDatePicker from 'common/ApDatePicker/ApDatePicker.js';
import moment from 'moment';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/l10n/index.js"
import "flatpickr/dist/themes/material_blue.css";



import { readableFileSize
       , sqlToDateInput
       , formatMoney, currentLang, getWeekdays, getMonths } from 'services/Helpers/Helpers.js';

import './ApInput.css';

class ApInput extends React.Component
{

    constructor( props )
    {
        super( props );
        this.state = {
            focused: false,
            hideCalendar: true,
            innerValue: '',
        };
        autoBind( this );
    }

    /*
    focus()
    {
        this.apInput.focus();
    }
    */

    changeHandler(e)
    {
        if( typeof( this.props.onChange ) == "function" )
        {
            this.props.onChange(e);
        }
    }

    keyPressHandler(e)
    {
        if( typeof( this.props.onKeyPress ) == "function" )
        {
            this.props.onKeyPress(e);
        }
    }

    inputFocusChanged( e, focused )
    {
        this.setState({ focused: focused });
        if( e )
        {
            if( typeof( this.props.onFocus ) == "function" && focused )
            {
                this.props.onFocus(e);
            }
            else if ( typeof( this.props.onBlur ) == "function" && !focused )
            {
                this.props.onBlur(e);
            }
        }
    }

    datePicked( input )
    {
        if( typeof( input ) == "object" || input == "clear" )
        {
            // Simulate regular input onChange event object
            const e = {
                target: {
                    type: "datepicker",
                    name: this.props.name,
                    id: this.props.id,
                    value: input == "clear" ? "" : input.format( this.props.dateFormat ? this.props.dateFormat : 'DD.MM.YYYY' )
                }
            };
            this.changeHandler( e )

            this.setState({ hideCalendar: true });

            if( input != "clear" )
                this.pickerInput.focus();
        }
    }

    datetimePicked( input )
    {
        if( typeof( input ) == "object" || input == "clear" )
        {
            // Simulate regular input onChange event object
            const e = {
                target: {
                    type: "datepicker",
                    name: this.props.name,
                    id: this.props.id,
                    value: input == "clear" ? "" : input.format( ( this.props.dateFormat ? this.props.dateFormat : 'DD.MM.YYYY' ) + " " + ( this.props.timeFormat ? this.props.timeFormat : 'HH.mm' ) )
                }
            };
            this.changeHandler( e )

            //this.setState({ hideCalendar: true });

            if( input != "clear" )
                this.pickerInput.focus();
        }
    }

    dateSafePicked( value )
    {
        //console.log(value);
        if( moment.isMoment( value ) )
            value = value.format('YYYY-MM-DD' )
        else if( value === 'clear')
            value = null;
        else 
            return null;

        // Simulate regular input onChange event object
        const e = {
            target: {
                type: "datepicker",
                name: this.props.name,
                id: this.props.id,
                value: value,
            }
        };
        this.changeHandler( e )
        this.setState({ hideCalendar: true, focused: false });
        this.pickerInput.blur();
    }

    dateSafePickedNew( value )
    {
        if( value === 'clear') {
            value = null;
        }
        else {
            value = moment(value).format( 'YYYY-MM-DD' );
        }
        //console.log(this.props.name, this.props.id, value);

        const e = {
            target: {
                type: "ApDatePicker",
                name: this.props.name,
                id: this.props.id,
                value: value,
            }
        };
        this.changeHandler( e );
    }


    dataInputChange( e )
    {
        const value = e.target.value;
        this.setState({ innerValue: value });
    }

    calendarOnOpen()
    {
        this.setState({ focused: true, hideCalendar: false });
    }

    calendarOnClose()
    {
        this.setState({ focused: false });
        
    }

    charCounter()
    {
        if( !this.props.type in ["textarea","text"] )
            return null;

        const length = this.props.value ? this.props.value.length : 0;

        return (
            <div className="charCounter">{ length }{ this.props.maxLength && " / " + this.props.maxLength }</div>
        );
    }

    render()
    {

        let classes = ["apInputBlock"];
        let value = ( this.props.value ? this.props.value : "" );
        let validateIcon = false;
        let calendarIcon = "calendar-alt";

        if( this.props.type == "textarea" )
            classes.push("textarea");

        if( this.state.focused )
            classes.push("focused");

        // Add filled class if input should have a placeholder text so the label text will not overlap with placeholder text
        if( typeof value === 'string' || this.props.placeHolder)
        {
            if( value.length > 0 || this.props.placeHolder )
                classes.push("filled");
        }
        else if( typeof value === 'number')
        {
            classes.push("filled");
        }
        else if( moment.isMoment( value ) )
        {
            classes.push("filled");
        }
        else if( this.props.type === "file" && value && value.name )
        {
            classes.push("filled");
        }



        if( ["error", "warning", "loading", "success"].indexOf( this.props.validationState ) != -1 )
        {
            classes.push( "validator-" + this.props.validationState );

            switch( this.props.validationState )
            {
                case "error":
                    validateIcon = "times";
                    calendarIcon = "calendar-times";
                    break;

                case "warning":
                    validateIcon = "exclamation";
                    calendarIcon = "calendar-alt";
                    break;

                case "loading":
                    validateIcon = "hourglass-half";
                    calendarIcon = "calendar-alt";
                    break;

                case "success":
                    validateIcon = "check";
                    calendarIcon = "calendar-check";
                    break;

                default:
                    break;
            }
        }

        if( !this.props.label )             classes.push("no-label");
        if( this.props.disabled )           classes.push("disabled");
        if( this.props.readOnly )           classes.push("readOnly");
        if( this.props.loading )            classes.push("loading");
        if( this.props.align == "right" )   classes.push("alignRight");

        if( this.props.headermode )         classes.push("headermode");

        let inputElement = null;

        // Remove props that should not go to input element
        const { validationState, loading, inputRef, noFocusDecimals, charCounter, gapRight, gapLeft, headermode, weekNumbers, clearable, enableTime, ...otherProps } = this.props;
        let propClasses = this.props.classes || [];
        switch( this.props.type )
        {
            case "date":

                inputElement = <div>
                    <Datetime 
                        { ...otherProps }
                        locale={ currentLang() }
                        value={ value }
                        showWeekNumbers={true}
                        onChange={ this.datePicked }
                        onFocus={ () => this.calendarOnOpen() }
                        onBlur={ () => this.calendarOnClose() }
                        timeFormat={ false }
                        dateFormat={ this.props.dateFormat ? this.props.dateFormat : "DD.MM.YYYY" }
                        className={ (this.state.hideCalendar ? "hideCalendar " : "" + propClasses.join(' ')) }
                        isValidDate={ this.props.isValidDate }
                        inputProps={{
                            className: "", // Prevent 'form-control' class to appear (with bootstrap CSS styles)
                            id: this.props.id,
                            name: this.props.name,
                            onChange: this.changeHandler,
                            onKeyPress: this.keyPressHandler,
                            ref: pickerInput => this.pickerInput = pickerInput,
                            autoComplete: 'off',
                            disabled: this.props.disabled,
                            readOnly: window.innerWidth < 700 ? true : false
                        }}

                    />
                    <SvgIcon className="calendarIcon" icon={ calendarIcon } />

                    { this.props.clearable && value && !this.props.disabled &&
                        <SvgIcon className="clearIcon" icon="times-circle" type="solid" onClick={ () => this.datePicked('clear') } /> 
                    }

                </div>
                break;

            case "datetime":
                inputElement = <div>
                    <Datetime
                        { ...otherProps }
                        locale={ currentLang() }
                        value={ value }
                        onChange={ this.datetimePicked }
                        onFocus={ () => this.calendarOnOpen() }
                        onBlur={ () => this.calendarOnClose() }
                        showWeekNumbers={true}
                        timeFormat={ this.props.timeFormat ? this.props.timeFormat : "HH.mm" }
                        dateFormat={ this.props.dateFormat ? this.props.dateFormat : "DD.MM.YYYY" }
                        className={ this.state.hideCalendar ? "hideCalendar" : "" + propClasses.join(' ') }
                        isValidDate={ this.props.isValidDate }
                        timeConstraints={ this.props.timeConstraints ? this.props.timeConstraints : { minutes: { min: 0, max: 59, step: 15 } } }
                        inputProps={{
                            className: "", // Prevent 'form-control' class to appear (with bootstrap CSS styles)
                            id: this.props.id,
                            name: this.props.name,
                            onChange: this.changeHandler,
                            onKeyPress: this.keyPressHandler,
                            ref: pickerInput => this.pickerInput = pickerInput,
                            autoComplete: 'off',
                            disabled: this.props.disabled,
                        }}

                    />
                    <SvgIcon className="calendarIcon" icon={ calendarIcon } />

                    { this.props.clearable && value && !this.props.disabled &&
                        <SvgIcon className="clearIcon" icon="times-circle" type="solid" onClick={ () => this.datetimePicked('clear') } /> 
                    }

                </div>
                break;

            // This input always return date in format YYYY-MM-DD and date modifications are done in state variable and only blur
            // calls props.onChange 
            case "dateSafeOld":

                if( this.state.focused )
                    value = this.state.innerValue;
                else
                    value = this.props.value ? sqlToDateInput( this.props.value ) : '';

                inputElement = <div>
                    <Datetime
                        { ...otherProps }
                        locale={ currentLang() }
                        value={ value ? value : null }
                        onChange={ this.dateSafePicked }
                        onFocus={ () => this.calendarOnOpen() }
                        onBlur={ () => this.calendarOnClose() }
                        timeFormat={ false }
                        dateFormat={ this.props.dateFormat ? this.props.dateFormat : "DD.MM.YYYY" }
                        className={ this.state.hideCalendar ? "hideCalendar" : "" }
                        isValidDate={ this.props.isValidDate }
                        inputProps={{
                            className: "", // Prevent 'form-control' class to appear (with bootstrap CSS styles)
                            id: this.props.id,
                            name: this.props.name,
                            onKeyPress: this.keyPressHandler,
                            ref: pickerInput => this.pickerInput = pickerInput,
                            autoComplete: 'off',
                            onChange: ( e ) => this.setState({ innerValue: e.target.value }),
                            value: value,
                            onFocus: () => { this.calendarOnOpen(); this.setState({ innerValue: this.props.value ? sqlToDateInput( this.props.value ) : '' }) },
                            onBlur: () => { this.calendarOnClose(); },
                            disabled: this.props.disabled,
                            readOnly: window.innerWidth < 700 ? true : false
                        }}

                    />
                    <SvgIcon className="calendarIcon" icon={ calendarIcon } />

                    { this.props.clearable && value && !this.props.disabled &&
                            <SvgIcon className="clearIcon" icon="times-circle" type="solid" onClick={ () => this.dateSafePicked('clear') } /> 
                    }

                </div>
                break;

            case "dateSafe":
                inputElement = 
                    <ApDatePicker 
                        { ...otherProps }
                        locale="fi"
                        value={ value ? value : "" }
                        onChange={ this.changeHandler }
                        id={ this.props.id }
                        name={ this.props.name }
                        calendarIcon={calendarIcon}
                        autoComplete="off"
                    />
                break;

            case 'datetimeV2':
                inputElement = (
                    <div>
                        <Flatpickr
                            {...otherProps}
                            data-enable-time
                            value={value ? (this.props.enableTime ? moment(value).format("DD.MM.YYYY HH:mm:ss") :moment(value).format("DD.MM.YYYY")):null}
                            options={{
                                time_24hr: true,
                                weekNumbers: this.props.weekNumbers?true:false,
                                dateFormat: this.props.enableTime ? 'd.m.Y H:i' : 'd.m.Y',
                                enableTime: this.props.enableTime ? true : false,
                                //defaultDate: this.state.edited ? null : new Date(value),
                                // locale: currentLang(),
                                locale: {
                                    firstDayOfWeek: 1,
                                    weekdays: {
                                        shorthand: getWeekdays(true, true).map(day => day.label),
                                        longhand: getWeekdays(false, true).map(day => day.label),
                                    },
                                    months: {
                                        shorthand: getMonths(true).map(month => month.label),
                                        longhand: getMonths().map(month => month.label),
                                    },
                                }
                            }}
                            onChange={(selectedDates, dateStr, instance) => {
                                let selectedDate = selectedDates[0] || null;
                                let dateTimeString = selectedDate ?
                                    (this.props.enableTime ? moment(selectedDate).format("YYYY-MM-DD HH:mm:ss")
                                        : moment(selectedDate).format("YYYY-MM-DD")
                                ) : null;
                                this.props.onChange(dateTimeString, dateStr, selectedDate, instance)
                            }}
                            ref= {pickerInput => this.pickerInput = pickerInput}
                            disabled={this.props.disabled}
                        />
                        <SvgIcon className="calendarIcon" icon={calendarIcon} />
                        {this.props.clearable && value && !this.props.disabled &&
                            <SvgIcon className="clearIcon" icon="times-circle" type="solid" onClick={() => this.pickerInput.flatpickr.clear()} />
                        }
                    </div>
                    
                )
                break;
            
            case 'time':
                inputElement = (
                    <div>
                        <Flatpickr
                            {...otherProps}
                            value={value ? value : null}
                            options={{
                                time_24hr: true,
                                dateFormat: "H:i",
                                enableTime: true,
                                noCalendar: true,
                                locale: currentLang(),
                            }}
                            onChange={(selectedDates, dateStr, instance) => {
                                let selectedDate = selectedDates[0] || null;
                                let dateTimeString = selectedDate 
                                    ? moment(selectedDate).format(this.props.timeFormat ? this.props.timeFormat:"HH:mm")
                                    : null;
                                this.props.onChange(dateTimeString, dateStr, selectedDate, instance)
                            }}
                            ref= {pickerInput => this.pickerInput = pickerInput}
                        />
                        <SvgIcon className="calendarIcon" icon={calendarIcon} />
                        {this.props.clearable && value && !this.props.disabled &&
                            <SvgIcon className="clearIcon" icon="times-circle" type="solid" onClick={() => this.pickerInput.flatpickr.clear()} />
                        }
                    </div>
                    
                )
                break;

            case "select":

                if( typeof( this.props.options ) != "object" )
                    console.error('ApInput syntax error: When input type-prop is "select" you should provide options-props as an array! [ { value: 1, label: "first" }, { value: 2, label: "second" } ]');

                const addOption = ( option, index ) => {
                    return (
                        <option
                            key={ index }
                            value={ option.value }
                            disabled={ option.disabled }>
                            { option.label }
                        </option>
                    );
                };

                // Remove props that should not go to input element

                if( this.props.readOnly )
                {
                    inputElement = <input
                        { ...otherProps }
                        ref={ this.props.inputRef }
                        type={ "text" }
                        readOnly
                        id={ this.props.id }
                        name={ this.props.name }
                        value={ this.props.options.find( o => o.value === value ).label }
                        disabled={ this.props.disabled }
                        onFocus={ (e) => { this.inputFocusChanged(e, true); }}
                        onBlur={ (e) => { this.inputFocusChanged(e, false); }}
                    />;
                }
                else 
                {
                    inputElement = <label className="apSelect">
                        <select
                            { ...otherProps }
                            ref={ this.props.inputRef }
                            id={ this.props.id }
                            name={ this.props.name }
                            value={ value }
                            disabled={ this.props.disabled }
                            //readOnly={ this.props.readOnly }
                            onChange={ this.changeHandler }
                            onFocus={ (e) => { this.inputFocusChanged(e, true); }}
                            onBlur={ (e) => { this.inputFocusChanged(e, false); }}
                            dir={ this.props.align == "right" ? "rtl" : "ltr" }
                        >
                            { this.props.options.map( ( item, index ) => {

                                if( item.options && item.label )
                                {
                                    return (
                                        <optgroup key={ index } label={ item.label }>
                                            { item.options.map( ( opt, groupIndex ) => {
                                                return addOption( opt, index + "-" + groupIndex );
                                            })}
                                        </optgroup>
                                    );
                                }

                                return addOption( item, index );
                            })}
                        </select>
                        <SvgIcon className="dropdownArrow" icon="angle-down" type="solid" />
                    </label>
                }
                break;

            case "textarea":
                inputElement = <textarea
                    { ...otherProps }
                    ref={ this.props.inputRef }
                    type={ this.props.type }
                    id={ this.props.id }
                    name={ this.props.name }
                    value={ value }
                    maxLength={ this.props.maxLength ? this.props.maxLength : null }
                    disabled={ this.props.disabled }
                    readOnly={ this.props.readOnly }
                    onChange={ this.changeHandler }
                    onKeyPress={ this.keyPressHandler }
                    onFocus={ (e) => { this.inputFocusChanged(e, true); }}
                    onBlur={ (e) => { this.inputFocusChanged(e, false); }}
                ></textarea>;
                break;

            case "file":
                inputElement = <div className="fileWrapper">
                    <label className="fileLabel" htmlFor={ this.props.id }>
                        <SvgIcon className="small-inline" icon="file" type={ value && value.name ? "solid" : "regular" } /> 
                        { value && value.name ? <span><strong>{ value.name }</strong> <small>({ readableFileSize( value.size ) })</small></span> : ( this.props.label ? this.props.label : "Valitse tiedosto..." ) }
                    </label>
                    { value && value.name && <SvgIcon className="removeFile" icon="times-circle" type="solid" onClick={ () => this.changeHandler( null ) } /> }
                    <input
                        accept={ this.props.accept }
                        ref={ this.props.inputRef }
                        type={ this.props.type }
                        id={ this.props.id }
                        name={ this.props.name }
                        disabled={ this.props.disabled }
                        readOnly={ this.props.readOnly }
                        onChange={ ( e ) => { if( e.target.files.length > 0 ) this.changeHandler( e.target.files[0] ) } }
                        onFocus={ (e) => { this.inputFocusChanged(e, true); }}
                        onBlur={ (e) => { this.inputFocusChanged(e, false); }}
                    />
                    </div>;
                break;

            default:

                // Format unfocused number to show only few defined number of decimals when not focused
                if( typeof noFocusDecimals !== 'undefined' && !this.state.focused )
                {
                    if(!isNaN( parseFloat( value ) ))
                    {
                        value = formatMoney(value, noFocusDecimals );
                    }
                }

                inputElement = <input
                    { ...otherProps }
                    ref={ this.props.inputRef }
                    type={ this.props.type }
                    id={ this.props.id }
                    name={ this.props.name }
                    value={ value }
                    maxLength={ this.props.maxLength ? this.props.maxLength : 524288 } // 524288 = default maxlenght in inputs
                    disabled={ this.props.disabled }
                    readOnly={ this.props.readOnly }
                    onChange={ this.changeHandler }
                    onKeyPress={ this.keyPressHandler }
                    onFocus={ (e) => { this.inputFocusChanged(e, true); }}
                    onBlur={ (e) => { this.inputFocusChanged(e, false); }}
                    autoComplete={this.props.autoComplete ? this.props.autoComplete : "off"}
                />;
                break;
        }


        return (
            <div className={ classes.join(" ") }>

                { this.props.label && this.props.type != "file" && this.props.type != "select" &&
                    <div className="labels">
                        <label className="small" htmlFor={ this.props.id }>{ this.props.label }{ this.props.charCounter && this.charCounter() }</label>
                        <label className="large" htmlFor={ this.props.id }>{ this.props.label }</label>
                    </div>
                }

                { inputElement }

                { this.props.tooltip && <div className="apTooltip">{ this.props.tooltip }</div> }

                { validateIcon && ( !['data', 'datetime', 'dateSafe'].includes( this.props.type ) ) && <SvgIcon className="validationIcon" icon={ validateIcon } type="solid" /> }

                { this.props.loading && <div className="apLoader"></div> }
                { this.props.infoText &&
                    <div className={"info" + (this.props.type === "select" ? " shift-left" : "")}>
                        <ApTooltip text={this.props.infoText} position={this.props.infoTextPosition || 'top'}>
                            <SvgIcon icon="info-circle" type="solid" />
                        </ApTooltip>
                    </div>
                }
            </div>
        );
    }
};




class ApInputStack extends React.Component
{
    constructor( props )
    {
        super( props );
        this.state = {
            width: 0
        }
        autoBind( this );
    }

    componentDidMount() 
    {
        if (this.props.collapseAt)
        {
            this.updateWidth()
            window.addEventListener('resize', this.updateWidth)
        }
    }
      
    componentWillUnmount() 
    {
        if (this.props.collapseAt)
            window.removeEventListener('resize', this.updateWidth)
    }

    updateWidth()
    {
        this.setState({ width: window.innerWidth })
    }

    render()
    {
        const inputs = this.props.children;
        const gap = ( this.props.gap ? parseInt( this.props.gap, 10 ) : 10 );

        let renderChild = ( child, index = 0 ) => {
            if( !child )
                return null;

            let gapStyles = { marginRight: ( index < ( inputs.length - 1 ) ? gap : 0 ) + "px" };
            if( child.props.gapLeft ) {
                gapStyles.marginLeft = parseInt( child.props.gapLeft, 10 ) + "px";
            }
            if( child.props.gapRight ) {
                gapStyles.marginRight = parseInt( child.props.gapRight, 10 ) + "px";
            }

            return (
                <td key={index} width={ child.props.width ? child.props.width : "auto" }
                    style={this.props.style ? this.props.style : null}
                    className={
                        this.props.timetrackingMobileResponsive && index === 0 ?
                        'timetracking-mobile' : ''
                }>
                    <div style={ gapStyles } >
                       { child }
                    </div>
                </td>
            );
        };

        let doms = null;
        if( Array.isArray( inputs ))
            doms = inputs.map( renderChild );
        else
            doms = renderChild( inputs )

        let firstRow = null

        if (this.props.collapseAt && this.state.width <= this.props.collapseAt)
        {
            return (
                <div>
                   { inputs.map(input => input) }
                </div>
            );
        }
        
        if (this.props.timetrackingMobileResponsive && window.innerWidth <= 600) {
            firstRow = doms.splice(0, 1)
        } 

        if (window.innerWidth <= 600) {
            return (  
                <div>
                    {firstRow ? <table>
                        <tbody>
                            <tr>{firstRow}</tr>
                        </tbody>
                    </table> : null}
                    
                    <table className="apInputStack">
                        <tbody>
                            <tr>
                                { doms }
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }

        return (
            <table className="apInputStack">
                <tbody>
                    <tr>
                        { doms }
                    </tr>
                </tbody>
            </table>
        );
    }
}



class ApAddon extends React.Component
{
    render()
    {
        if( this.props.custom ) return <div>{ this.props.children }</div>;

        let classes = ["apAddon"];
        let onClick = () => null;
        if( this.props.inline )         classes.push("inline");
        if( this.props.noLeftBorder )   classes.push("noLeftBorder");
        if( this.props.noRightBorder )  classes.push("noRightBorder");
        if( this.props.noPadding )      classes.push("noPadding");
        if( this.props.disabled )       classes.push("disabled");
        if( this.props.className )      classes.push( this.props.className );
        if (this.props.timetrackingPreventFlex) classes.push('timetracking-prevent-flex')

        if( typeof( this.props.onClick ) == "function" )
        {
            classes.push("button");
            if( !this.props.disabled )
                onClick = this.props.onClick;
        }

        if( ["error", "warning", "loading", "success"].indexOf( this.props.validationState ) != -1 )
        {
            classes.push( "validator-" + this.props.validationState );
        }


        return (
            <label
                style={this.props.style ? this.props.style : null}
                className={ classes.join(" ") }
                htmlFor={ this.props.labelFor ? this.props.labelFor : "" }
                onClick={ onClick }
            >
                <ApTooltip block text={ this.props.tooltip ? this.props.tooltip : false } position={ this.props.tooltipPosition ? this.props.tooltipPosition : "top" }>
                    { this.props.children }
                </ApTooltip>
            </label>
        );
    }
}


ApInput.propTypes = {
    name:               PropTypes.string.isRequired,
    id:                 PropTypes.string.isRequired,
    label:              PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]),
    type:               PropTypes.string,   // HTML Input types(text,number,color,...) and datetimeV2, select, textarea, file. Old(datetime, dateSafeOld, dateSafe)
    value:              PropTypes.oneOfType([ PropTypes.string, PropTypes.number, PropTypes.object ]),
    options:            PropTypes.array, // Only when props.type == 'select'
    maxLength:          PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    charCounter:        PropTypes.any,
    align:              PropTypes.string,
    tooltip:            PropTypes.string,
    validationState:    PropTypes.string,
    disabled:           PropTypes.bool,
    readOnly:           PropTypes.bool,
    loading:            PropTypes.bool,
    onChange:           PropTypes.func,
    onKeyPress:         PropTypes.func,
    onFocus:            PropTypes.func,
    onBlur:             PropTypes.func,
    dateFormat:         PropTypes.string,   // Only when props.type == 'date'
    timeFormat:         PropTypes.string,   // Only when props.type == 'date' 
                                            // or props.type == 'time'(only onChange format default HH:mm) if changed onChange format is different than input show
    
    isValidDate:        PropTypes.func,     // Only when props.type == 'date'
    clearable:          PropTypes.bool,     // Only when props.type == 'date' for now
    headermode:         PropTypes.bool,     // Only when props.type == 'select'
    charCounter:        PropTypes.bool,     // Only when props.type in ['textarea','text']
    inputRef:           PropTypes.any,
    noFocusDecimals:    PropTypes.number, // Only when props.type == default input
    classes:            PropTypes.array,
    infoText:           PropTypes.string,
    infoTextPosition:   PropTypes.string,

    // These are used only when inside ApInputStack!
    width:          PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    gapLeft:        PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    gapRight:       PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
};

ApInputStack.propTypes = {
    gap: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    timetrackingMobileResponsive: PropTypes.bool, //Only for allowing better mobile responsive for expenses in timetracking entries.
    collapseAt: PropTypes.number
};

ApAddon.propTypes = {
    custom:             PropTypes.bool,
    inline:             PropTypes.bool,
    labelFor:           PropTypes.string,
    noLeftBorder:       PropTypes.bool,
    noRightBorder:      PropTypes.bool,
    validationState:    PropTypes.string,
    onClick:            PropTypes.func,
    tooltip:            PropTypes.string,
    tooltipPosition:    PropTypes.string,
    className:          PropTypes.string,
    disabled:           PropTypes.bool,

    // These are used only when inside ApInputStack!
    width:          PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    gapLeft:        PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    gapRight:       PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
};

export {
    ApInput,
    ApInputStack,
    ApAddon
};
