import React, {useState, useEffect} from 'react'
import {QrReader} from 'react-qr-reader'
import BarcodeScannerComponent from "react-qr-barcode-scanner";
//import BarcodeReader from 'react-barcode-reader' //puhelimeen liitettävä viivakoodinlukija

import './StorageReader.css'

import { errorPopper, tr }  from 'services/Helpers/Helpers.js'
import api from 'services/Api/Api.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApButton	from 'common/ApButton/ApButton.js';
import ApSelect from 'common/ApSelect/ApSelect.js';

import { ApTabs, ApTab } from 'common/ApTabs/ApTabs'
import Collect from '../../Checkout/Collections/Collect'
import CollectionsList from '../../Checkout/Collections/CollectionsList'

const locations = [
    {label: tr('storage')},
    {label: tr('project')},  
]

const StorageReader = () => {
    const [usesQRcodes, setUsesQRcodes] = useState('qr-code')
    const [loading, setLoading] = useState(false)
    const [read, setRead] = useState(null)

    const [collectionId, setCollectionId] = useState(null)
    const [refetchCollectionList, setRefetchCollectionList] = useState(false)
    const [forceTabChangeIndex, setForceTabChangeIndex] = useState(null)

    const [codeRead, setCodeRead] = useState(null)

    const [transferItem, setTransferItem] = useState(null)
    const [transferAmount, setTransferAmount] = useState(0)

    const [senderBalance, setSenderBalance] = useState(null)
    const [receiverBalance, setReceiverBalance] = useState(null)

    const [sendingStorage, setSendingStorage] = useState({})
    const [receivingStorage, setReceivingStorage] = useState({})

    const [receiverAddress, setReceiverAddress] = useState({
        delivery_address: null,
        delivery_zipcode: null,
        delivery_city: null,
    })
    const [receiverSelected, setReceiverSelected] = useState(false)

    const [sendingType, setSendingType] = useState(locations[0].label)
    const [receivingType, setReceivingType] = useState(locations[0].label)
    const notCountInputs = [];

    useEffect(() => {
        const getSettings = async () => {
            const result = await api({
                method: 'get',
                url: 'company/settings/defaults'
            })

            if (result.default_reader === 0) setUsesQRcodes('barcode')

            if (result.default_reader === 1) setUsesQRcodes('qr-code')

            if (result.default_reader === 2) setUsesQRcodes('collection')
            
            result.default_sender_type === 0 ? setSendingType(locations[0].label) : setSendingType(locations[1].label)
            result.default_receiver_type === 0 ? setReceivingType(locations[0].label) : setReceivingType(locations[1].label)
            result.default_sender_storage === 0 ? setSendingStorage({}) : setSendingStorage(result.default_sender_storage)
        }

        getSettings()

    }, [])

    useEffect(() => {
        setForceTabChangeIndex(0)
    }, [collectionId])

    useEffect(() => {
        if (transferItem && sendingStorage.id) {
            const getBalance = async () => {
                const result = await api({
                    method: 'post',
                    url: 'storage/component/location/balance',
                    data: {
                        component_id: transferItem.id,
                        project_id: sendingStorage.id
                    }
                })
                setSenderBalance(result)
            }

            getBalance()
        }
    }, [transferItem, sendingStorage])

    useEffect(() => {
        if (transferItem && receivingStorage.id) {
            const getBalance = async () => {
                const result = await api({
                    method: 'post',
                    url: 'storage/component/location/balance',
                    data: {
                        component_id: transferItem.id,
                        project_id: receivingStorage.id
                    }
                })
                setReceiverBalance(result)
            }

            getBalance()
        }
    }, [transferItem, receivingStorage])

    const handleScan = (data) => {
        //QR-code reader
        if (data) {
            setRead(tr('searching_for_storage_component'))
            searchItem(data)
        }
    }

    const handleBarcodeScan = (error, result) => {
        // console.log(error)
        if (result) {
            setRead(tr('searching_for_storage_component'))
            searchItem(result.text)
        }
    }

    const searchItem = async (code) => {
        setLoading(true)

        const result = await api({
            method: 'get',
            url: `storage/component/${code}`,
        })

        if (result === 'component id not found') {
            setRead(tr('code_not_found_in_database'))
            setLoading(false)
            return
        }

        if (result === 'component not found') {
            setRead(tr('component_not_found_in_database'))
            setLoading(false)
            return
        }

        setTransferItem(result)
        setLoading(false)
    }

    const customPopper = (message) => {
        window.emitter.emit('popper', {
            content: <strong>{message}</strong>,
            type: 'warning',
            time: 4500,
            style: {color: 'yellow'},
        });
    }

    const handleError = (error) => {
        if (usesQRcodes !== 'qr-reader') {
            return
        }

        console.log('Virhe lukijassa:', error.message)
        if (error.message === 'Requested device not found' || error.message === 'Could not start video source') {
            customPopper(tr('camera_not_found'))
            return
        }

        customPopper(`${tr('error')}: ${error.message}`)
    }

    const handleTypeChange = (e, storage = null) => {
        if (storage === 'sending') {
            setSendingType(e)
        } else {
            setReceivingType(e)
        }
    }


    const saveMultiple = async (components, isReturn) => {
        setLoading(true)

        let deliveryInfo = {
            delivery_address: receivingStorage.delivery_address,
            delivery_zipcode: receivingStorage.delivery_zipcode,
            delivery_city: receivingStorage.delivery_city,
        }

        if (receivingType === 'Projekti') {
            deliveryInfo.delivery_address = receivingStorage.site_address
            deliveryInfo.delivery_zipcode = receivingStorage.site_postal_code
            deliveryInfo.delivery_city = receivingStorage.site_city

            if (!receivingStorage.site_address) {
                
                deliveryInfo.delivery_address = receiverAddress.delivery_address ? receiverAddress.delivery_address : '-'
                deliveryInfo.delivery_zipcode = receiverAddress.delivery_zipcode ? receiverAddress.delivery_zipcode : '-'
                deliveryInfo.delivery_city = receiverAddress.delivery_city ? receiverAddress.delivery_city : '-'
            }
        }

        let sender_type = sendingType === tr('storage') ? "location" : 'project'
        let sender_id = sendingStorage.id

        let receiver_type = receivingType === tr('storage') ? "location" : 'project'
        let receiver_id = receivingStorage.id
        let receiver_name = receivingStorage.name

        if (isReturn) {
            sender_type = receivingType === tr('storage') ? "location" : 'project'
            sender_id = receivingStorage.id

            receiver_type = sendingType === tr('storage') ? "location" : 'project'
            receiver_id = sendingStorage.id

            receiver_name = '-'
            deliveryInfo.delivery_address = '-'
            deliveryInfo.delivery_zipcode = '-'
            deliveryInfo.delivery_city = '-'
        }
        
        const data = {

            related_id: undefined,
            related_type: 'new',

            sender_type,
            sender_id,

            type: 'collection',

            receiver_type,
            receiver_id,
            
            receiver_name,
            receiver_address:  deliveryInfo.delivery_address, 
            receiver_zipcode:  deliveryInfo.delivery_zipcode,
            receiver_city:  deliveryInfo.delivery_city,
            receiver_country:  undefined,
            receiver_contactName:  undefined,

            reference_your: undefined,
            reference_our: undefined,
            comment: '',
            components: components.map((component) => {
                
                return {
                    component_id: component.id,
                    name: component.name,
                    delivery_count: component.count,
                    delivery_from_id: sender_id,
                    delivery_from_type: sender_type,
                    delivery_to_id: receiver_id,
                    delivery_to_type: receiver_type,
                    unit: component.unit,
                    id: undefined
                }
            })
        }

        try {
            await api({
                method: 'post',
                url: 'storage/delivery/save',
                data: data,
            })

            setLoading(false)
            setRead(null)

            setSenderBalance(null)
            setReceiverBalance(null)

            setTransferAmount(0)
            setTransferItem(null)
            setCodeRead(null)
        } catch (e) {
            setLoading(false)
            errorPopper('Virhe', `Palvelinvirhe ${e.message}`)
        }
    }


    const save = async () => {
        setLoading(true)

        if (!transferAmount) {
            customPopper(tr('amount_cannot_be_empty'))
            setLoading(false)
            return
        }

        if (!transferItem) {
            customPopper(tr('product_to_be_sent_not_selected'))
            setLoading(false)
            return
        }

        if (!sendingStorage.id || !receivingStorage.id) {
            customPopper(tr('no_storages_selected'))
            setLoading(false)
            return
        }

        if (sendingStorage.balance < transferAmount) {
            customPopper(tr('no_more_can_be_transferred_from_storage'))
            setLoading(false)
            return
        }

        let deliveryInfo = {
            delivery_address: receivingStorage.delivery_address,
            delivery_zipcode: receivingStorage.delivery_zipcode,
            delivery_city: receivingStorage.delivery_city,
        }

        if (receivingType === tr('project')) {
            deliveryInfo.delivery_address = receivingStorage.site_address
            deliveryInfo.delivery_zipcode = receivingStorage.site_postal_code
            deliveryInfo.delivery_city = receivingStorage.site_city

            if (!receivingStorage.site_address) {
                
                deliveryInfo.delivery_address = receiverAddress.delivery_address
                deliveryInfo.delivery_zipcode = receiverAddress.delivery_zipcode
                deliveryInfo.delivery_city = receiverAddress.delivery_city
            }
        }

        if (!deliveryInfo.delivery_address || !deliveryInfo.delivery_zipcode || !deliveryInfo.delivery_city ) {
            customPopper(tr('fill_in_address_info'))
            setLoading(false)
            return
        }

        const data = {

            related_id: undefined,
            related_type: 'new',

            sender_type: sendingType === tr('storage') ? "location" : 'project',
            sender_id: sendingStorage.id,

            receiver_type: receivingType === tr('storage') ? "location" : 'project',
            receiver_id: receivingStorage.id,

            receiver_name:  receivingStorage.name,
            receiver_address:  deliveryInfo.delivery_address, 
            receiver_zipcode:  deliveryInfo.delivery_zipcode,
            receiver_city:  deliveryInfo.delivery_city,
            receiver_country:  undefined,
            receiver_contactName:  undefined,

            reference_your: undefined,
            reference_our: undefined,
            comment: '',
            components: [
                {
                    component_id: transferItem.id,
                    name: transferItem.name,
                    delivery_count: transferAmount,
                    delivery_from_id: sendingStorage.id,
                    delivery_from_type: sendingType === tr('storage') ? "location" : 'project',
                    delivery_to_id: receivingStorage.id,
                    delivery_to_type: receivingType === tr('storage') ? "location" : 'project',
                    id: undefined,
                    unit: transferItem.unit,
                }
            ],
        }

        try {
            await api({
                method: 'post',
                url: 'storage/delivery/save',
                data: data,
            })

            setLoading(false)
            setRead(null)

            setSenderBalance(null)
            setReceiverBalance(null)

            setTransferAmount(0)
            setTransferItem(null)
            setCodeRead(null)

            window.emitter.emit('popper', {
                content: <strong>{`${tr('transferred')}!`}</strong>,
                type: 'success',
                time: 4000,
                style: {color: 'yellow'},
            });

        } catch (e) {
            setLoading(false)
            errorPopper('Virhe', `Palvelinvirhe ${e.message}`)
        }
    }

    /*
    if (window.innerWidth > 800) {
        return (
            <div>
                <h1>{ tr('storage_reader') }</h1>
                <p>{ tr('storage_reader_info') }</p>
                <div>
                    { tr('storage_reader_note') }
                </div>
            </div>
        )
    }
    */

    const renderAddressInput = () => {
        if ((receivingType === tr('storage') && receiverSelected && !receivingStorage.delivery_address) ||
            (receivingType === tr('project') && receiverSelected && !receivingStorage.site_address)
        ) {
            return <div>
                <br />
                <ApInput 
                    type="text" 
                    id="address" 
                    name="address"
                    label={ tr('address') }
                    value={ receiverAddress.delivery_address }
                    onChange={ (e) => {
                        setReceiverAddress({
                            delivery_address: e.target.value,
                            delivery_zipcode: receiverAddress.delivery_zipcode,
                            delivery_city: receiverAddress.delivery_city,
                        })} }
                    loading={ loading }
                    disabled={ loading }
                    validationState={ !receiverAddress.delivery_address ? 'error' : null}
                />
                <ApInput 
                    type="text" 
                    id="zipcode" 
                    name="zipcode"
                    label={ tr('postal_code') }
                    value={ receiverAddress.delivery_zipcode }
                    onChange={ (e) => {
                        setReceiverAddress({
                            delivery_address: receiverAddress.delivery_address,
                            delivery_zipcode: e.target.value,
                            delivery_city: receiverAddress.delivery_city,
                        })} }
                    loading={ loading }
                    disabled={ loading }
                    validationState={ !receiverAddress.delivery_zipcode ? 'error' : null}
                />
                <ApInput 
                    type="text" 
                    id="city" 
                    name="city"
                    label={ tr('city') }
                    value={ receiverAddress.delivery_city }
                    onChange={ (e) => {
                        setReceiverAddress({
                            delivery_address: receiverAddress.delivery_address,
                            delivery_zipcode: receiverAddress.delivery_zipcode,
                            delivery_city: e.target.value,
                        })} }
                    loading={ loading }
                    disabled={ loading }
                    validationState={ !receiverAddress.delivery_city ? 'error' : null}
                />

            </div>
                
        }

        return null
    }

    const rescanButton = () => {
        return <ApButton 
                    onClick={ () => {
                        setTransferItem(null)
                        setRead(null)
                        setCodeRead(null)}} 
                    fullWidth
                    className='add-padding'>
                    { tr('scan_again') }
                </ApButton>
    }

    const readerRenderer = () => {
        if (transferItem) {
            return (
                <div style={{
                    height: '18em'
                }}>

                    <div className='item-center'>{transferItem.articleType}: {codeRead}</div>
                    <h6 className='item-center'>{transferItem.name}</h6>
                    {transferItem.code ? <div className='item-center' style={{paddingBottom: '2em'}}>{transferItem.code.code} | {transferItem.code.name}</div> : null }
                    
                    {rescanButton()}
                </div>
            )
        }

        if (read) {
            return <div style={{
                    height: '18em',
                }}>
                {read}
                {read === tr('component_not_found_in_database') ? rescanButton() : null}
                {loading ? <div className='apLoader' /> : null }
            </div>
        }

        if (usesQRcodes === 'qr-reader') {
            return (
                <QrReader 
                delay={300}
                onError={error => handleError(error)}
                onScan={handleScan}
                style={{ width: '100%'}}
                />
            )
        }

        if (usesQRcodes === 'collection') {
            return null
        }

        return (
            <BarcodeScannerComponent
                width={250}
                height={250}
                onUpdate={(err, result) => {
                    handleBarcodeScan(err, result)
            }}
          />
        )
    }

    const collectionsRenderer = () => {
        return (
            <div id='pageCheckout'>
                <div className="apBox">
                    <ApTabs
                        parentTabControl={forceTabChangeIndex} 
                        clearParentTabControl={() => setForceTabChangeIndex(null)}>
                        
                        <ApTab key='collections-1' label={ tr('collection') }>
                            <Collect
                                manualStorageLocation={sendingStorage}
                                type='storage-transfer'
                                receivingStorage={receivingStorage}
                                storageTransfer={saveMultiple}
                                notCountInputs={notCountInputs}
                                clearCollection={() => setCollectionId(null)}
                                collection={collectionId} 
                                refresh={refetchCollectionList} 
                                refreshList={setRefetchCollectionList}/>
                        </ApTab>
                        <ApTab key='collections-2' label={ tr('saved_collections') }>
                            <CollectionsList type='storage-transfer' 
                                selectCollection={setCollectionId}
                                refetch={refetchCollectionList}/>
                        </ApTab>
                    </ApTabs>
                </div>
            </div>
        )
    }

    const sendingInfoRenderer = () => {
        if (usesQRcodes === 'collection') {
            return (
                <>
                    <h5>{ tr('collection_storage') }</h5>
                    <ApSelect
                        label={tr('collection_storage')}
                        id="storageSelect"
                        value={ sendingStorage.name ? sendingStorage.name : '' }
                        optionRenderer={sendingType === tr('storage') ? 'storage_location_address' : 'project'}
                        objKeyId="id"
                        clearable
                        onChange={ e => setSendingStorage(e) }
                        apiUrl={sendingType === tr('storage') ? 'storage/locations/find' : 'storage/delivery/findprojects'}
                        apiData={{
                            canSend: true,
                            componentId: transferItem ? transferItem.id : null
                        }}
                        disabled={sendingStorage.name ? true : false}
                        ref={(ref) => {
                            if (notCountInputs[0] == null) {
                                notCountInputs[0] = ref ? ref.inputRef : null;
                            }
                        }}
                    />

                    <br />
                    <h5>{ tr('receiving_or_returning_storage') }</h5>

                    <ApInputStack gap="0">
                        <ApInput 
                            type="select" 
                            id="receivingtype" 
                            name="receivingtype"
                            value={ receivingType }
                            options={ locations }
                            onChange={(e) => handleTypeChange(e.target.value)}
                            // ref={(ref) => { notCountInputs[2] = ref ? ref: null; }}
                        />
                        <ApSelect
                            label={ tr('receiving_storage') }
                            value={receivingStorage.name ? receivingStorage.name : ''}
                            id="receivingStorageSelect" 
                            optionRenderer={receivingType === tr('storage') ? 'storage_location_address' : 'project'}
                            onChange={ (e) => {
                                setReceivingStorage(e)
                                setReceiverAddress({
                                    delivery_address: null,
                                    delivery_zipcode: null,
                                    delivery_city: null,
                                    delivery_country: null
                                })
                                setReceiverSelected(true)
                            } }
                            objKeyId="id"
                            clearable
                            apiUrl={receivingType === tr('storage') ? 'storage/locations/find' : 'storage/delivery/findprojects'}
                            apiData={{
                                //projectStorage: true, 
                                canReceive: true,
                            }}
                            disabled={loading}
                            ref={(ref) => {
                                if (notCountInputs[1] == null) {
                                    notCountInputs[1] = ref ? ref.inputRef : null;
                                }
                            }}
                        />
                    </ApInputStack>

                    {collectionsRenderer()}
                </>
            )
        }

        return (
            <>
                <br />
            <h5>{ tr('sender_storage') }</h5>
            <ApInputStack gap="0">
                <ApInput 
                    type="select" 
                    id="sendingtype" 
                    name="sendingtype"
                    value={ sendingType }
                    options={ locations }
                    onChange={ (e) => handleTypeChange(e.target.value, 'sending') }
                />
                <ApSelect
                    label={ tr('sender_storage') }
                    value={ sendingStorage.name ? sendingStorage.name : '' }
                    optionRenderer={sendingType === tr('storage') ? 'storage_location_address' : 'project'}
                    onChange={ e => setSendingStorage(e) }
                    objKeyId="id"
                    clearable
                    apiUrl={sendingType === tr('storage') ? 'storage/locations/find' : 'storage/delivery/findprojects'}
                    apiData={{
                        canSend: true,
                        componentId: transferItem ? transferItem.id : null
                    }}
                    disabled={ loading }
                />
            </ApInputStack>

            <ApButton
                onClick={() => {
                    const flipSending = {...sendingStorage}
                    const flipSendingBalance = {...senderBalance}
                    const flipSendingType = sendingType
                    const flipReceivingType = receivingType

                    setSendingStorage(receivingStorage)
                    setSenderBalance(receiverBalance)
                    setSendingType(flipReceivingType)

                    setReceivingStorage(flipSending)
                    setReceiverBalance(flipSendingBalance)
                    setReceivingType(flipSendingType)
                }}
            >
                <SvgIcon icon="exchange-alt" type="solid" />
            </ApButton>

            <br />
            <h5>{ tr('receiving_storage') }</h5>
            <ApInputStack gap="0">
                <ApInput 
                    type="select" 
                    id="receivingtype" 
                    name="receivingtype"
                    value={ receivingType }
                    options={ locations }
                    onChange={ (e) => handleTypeChange(e.target.value) }
                />
                <ApSelect
                    label={ tr('receiving_storage') }
                    value={ receivingStorage.name ? receivingStorage.name : '' }
                    optionRenderer={receivingType === tr('storage') ? 'storage_location_address' : 'project'}
                    onChange={ (e) => {
                        setReceivingStorage(e)
                        if (receivingType === tr('project')) {
                            // Check project storage address. If not set, check customer addresses
                            let delivery_address = null;
                            let delivery_zipcode = null;
                            let delivery_city = null;
                            let delivery_country = null;

                            if (e?.storage_location) {
                                delivery_address = e.storage_location.delivery_address;
                                delivery_zipcode = e.storage_location.delivery_zipcode;
                                delivery_city = e.storage_location.delivery_city;
                                delivery_country = e.storage_location.delivery_country;
                            }

                            // If project storage address is not set, check customer addresses
                            if (!delivery_address && e?.root_customer?.addresses?.length) {
                                const defaultShippingAddressId = false;
                                if (defaultShippingAddressId) {
                                    const defaultShippingAddress = e.root_customer.addresses.find(a => a.id === defaultShippingAddressId);
                                    if (defaultShippingAddress) {
                                        delivery_address = defaultShippingAddress.street;
                                        delivery_zipcode = defaultShippingAddress.postal_code;
                                        delivery_city = defaultShippingAddress.city;
                                    }
                                } else {
                                    for(let address of e.root_customer.addresses) {
                                        // Take first address as default
                                        if (!delivery_address) {
                                            delivery_address = address.street;
                                            delivery_zipcode = address.postal_code;
                                            delivery_city = address.city;
                                        }
                                        // Stop at first shipping address
                                        if (address.type === 'shipping') {
                                            delivery_address = address.street;
                                            delivery_zipcode = address.postal_code;
                                            delivery_city = address.city;
                                            break;
                                        }
                                    }
                                }
                            }

                            setReceiverAddress({
                                delivery_address,
                                delivery_zipcode,
                                delivery_city,
                                delivery_country
                            })
                        } else {
                            setReceiverAddress({
                                delivery_address: null,
                                delivery_zipcode: null,
                                delivery_city: null,
                                delivery_country: null
                            })
                        }
                        setReceiverSelected(true)
                    } }
                    objKeyId="id"
                    clearable
                    apiUrl={receivingType === tr('storage') ? 'storage/locations/find' : 'storage/delivery/findprojects'}
                    apiData={{
                        //projectStorage: true, 
                        canReceive: true,
                    }}
                    disabled={ loading }
                />
            </ApInputStack>

            <br />
            <h5>{ tr('product') }</h5>
            <div>
                {transferItem ? 
                <div>
                    <h6>{transferItem.name}</h6>
                    {transferItem.code ? <div>{transferItem.code.code} | {transferItem.code.name}</div> : null}
                    {typeof senderBalance === 'number' ? <div><strong>{ tr('sender_storage_balance') }:</strong> {senderBalance}</div> : null}
                    {typeof receiverBalance === 'number' ? <div><strong>{ tr('receiving_storage_balance') }:</strong> {receiverBalance}</div> : null}
                </div> 
                : tr('scan_product_first')}

                {renderAddressInput()}
            </div>

                <div>
                    <br />
                    <h5>{ tr('amount') }</h5>
                    <ApInputStack gap="0">
                        <ApInput 
                            type="number" 
                            id="amount" 
                            name="amount"
                            label={ tr('amount') }
                            value={ transferAmount }
                            onChange={ (e) => setTransferAmount(e.target.value) }
                            loading={ loading }
                            disabled={ loading }
                        />
                        <ApAddon noLeftBorder width="50px">
                            {transferItem ? `${transferItem.unit}` : '*'}
                        </ApAddon>
                    </ApInputStack>

                    <br />
                    <ApButton 
                        color="green" 
                        onClick={ save } 
                        loading={loading} 
                        disabled={!transferItem || !transferAmount || Object.keys(sendingStorage).length === 0 || Object.keys(receivingStorage).length === 0}
                        fullWidth 
                        className='add-padding'
                    >
                        { tr('move_to_storage') }
                        <SvgIcon icon="arrow-right" type="solid" />
                    </ApButton>
                </div>
            </>
        )
    }

    return (
        <div className="padding apBox">
            <h1>{ tr('storage_reader') }</h1>
            <p>{ tr('storage_reader_info') }</p>

            <div className={`apOptionBar count-3 userType`}>
                <div className={`option ${usesQRcodes === 'qr-code' ? 'selected' : ''}`}
                    onClick={() => setUsesQRcodes('qr-code')}>
                    <SvgIcon icon="qrcode" type="solid" />{' '}
                    { tr('qr_code') }
                </div>
                <div className={`option ${usesQRcodes === 'barcode' ? 'selected' : ''}`}
                    onClick={() => setUsesQRcodes('barcode')}>
                    <SvgIcon icon="barcode" type="solid" />{' '}
                    { tr('barcode') }
                </div>
                <div className={`option ${usesQRcodes === 'collection' ? 'selected' : ''}`}
                    onClick={() => setUsesQRcodes('collection')}>
                    <SvgIcon icon="dolly-flatbed" type="solid" />{' '}
                    { tr('collection') }
                </div>
            </div>
            <br />

            { readerRenderer() }
        
            { sendingInfoRenderer() }
                
        </div>
    )
}

export default StorageReader