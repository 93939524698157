
// Construct navigation bar according to logged-in user's permissions

// Values for a link:
// id:          used to mark a menu as active
// disabled:    disables the menu item, used to display content that has not yet been implemented
// to:          where clicking this will redirect the user
// icon:        icon for the link
// name:        link title
// permissions: array of permision user needs to have in order to see the menu item
//               - empty array ( [] ) means it's available for everybody
//               - permission can also be optional ( use suffix "_?" )
//               - at least one optional is required (if optional permissions available)
//               - Examples: 
//                  [ "first", "second", "third"]           => all three permissions required 
//                  [ "first_?", "second_?", "third_?" ]    => any one of three permission is required 
//                  [ "first", "second_?", "third_?" ]      => "first" is required with either "second" or "third" 
//               
// subMenuOpen: submenu open by default 
// subMenu:     submenu items
import { tr } from 'services/Helpers/Helpers.js';

const sideNavItems = [
    { 
        id: "home",
        to: "/",
        icon: "home",
        name: tr('frontpage'),
        permissions: [],
    },
    {
        id: "chat",
        to: "/chat",
        icon: "mail-bulk",
        name: tr('message'),
        permissions: ['chat'],
    },
    { 
        id: "timetracking",
        to: null,
        icon: "briefcase",
        name: tr('working_time_management'),
        permissions: ['timetracking'],
        subMenuOpen: false,
        subMenu: [
            { 
                id: "timetracking.entry",
                to: '/timetracking/entry',
                icon: "calendar-alt",
                name: tr('own_working_time'),
                permissions: ['timetracking.entries'],
            },
            {
                id: "timetracking.calendar",
                to: '/timetracking/calendar',
                icon: "calendar-day",
                name: tr('calendar'),
                permissions: [],
            },
            { 
                id: "timetracking.approval",
                to: '/timetracking/approval',
                icon: "calendar-check",
                name: tr('approval_of_hours'), // Työaikojen hyväksyntä
                permissions: ['timetracking.approval'],
            },
            { 
                id: "timetracking.confirm",
                to: '/timetracking/confirm',
                icon: "check-circle",
                name: tr('administrator_approval'), // Työaikojen vahvistus
                permissions: ['timetracking.confirm'],
            },
            { 
                id: "timetracking.overview",
                to: '/timetracking/overview',
                icon: "eye",
                name: tr('overview'),
                permissions: ['timetracking.overview'], 
                //adminOnly: true,
            },
            {
                id: "timetracking.time_attendance",
                to: '/timetracking/time_attendance',
                icon: "id-card",
                name: tr('time_attendance'),
                permissions: ['timetracking.time_attendance'], // TODO: Add new permission
                //adminOnly: true,
            },
            { 
                id: "timetracking.salary_extras",
                to: '/timetracking/salary_extras',
                icon: "trophy",
                name: tr('fees_supplements'),
                permissions: ['timetracking.salaryextras'],
            },
            { 
                id: "timetracking.payroll",
                to: '/timetracking/payroll',
                icon: "hand-holding-usd",
                name: tr('payroll'),
                permissions: ['timetracking.payroll'],
            },
            { 
                id: "workhours.holidays",
                to: '/workhours/holidays',
                icon: "suitcase",
                name: tr('holidays'),
                permissions: ['timetracking.holiday'],
            },
            { 
                id: "timetracking.assignments",
                to: '/timetracking/assignments',
                icon: "clock",
                name: tr('work_list'),
                permissions: ['assignments.timetracking_assignments'],
            },
        ]
    },

    { 
        id: "crm",
        to: null,
        icon: "bullseye",
        name: tr('customer_management'),
        permissions: ['clients'],
        subMenuOpen: false,
        subMenu: [
            { 
                id: "crm.customers",
                to: "/crm/customers",
                icon: "building",
                name: tr('customers'),
                permissions: ['clients'],
            },
            { 
                id: "crm.groups",
                to: "/crm/groups",
                icon: "tags",
                name: tr('customer_groups'),
                permissions: ['clients'],
            },
            /*
            { 
                id: "crm.marketing",
                to: "/crm/marketing",
                icon: "bullhorn",
                name: "Markkinointi",
                permissions: ['clients.marketing'],
            },
            */
            { 
                id: "crm.extranet",
                to: "/crm/extranet",
                icon: "handshake",
                name: "Extranet",
                permissions: ['clients.extranet'],
            },
            // { 
            //     id: "crm.viestisuora",
            //     to: "/crm/viestisuora",
            //     icon: "envelope",
            //     name: "Viestisuora",
            //     permissions: ['clients.viestisuora'],
            // },
        ]
    },

    { 
        id: "hr",
        to: null,
        icon: "user-friends",
        name: tr('personnel_management'),
        permissions: ['hr'],
        subMenuOpen: false,
        subMenu: [
            { 
                id: "hr.users",
                to: '/hr/users',
                icon: "user",
                name: tr('personnel'),
                permissions: ['hr.users'],
            },
            {
                id: "hr.usergroups",
                to: '/hr/usergroups',
                icon: "users",
                name: tr('user_groups_management'),
                permissions: ['hr.usergroups'],
            },
            {
                id: "hr.permissions",
                to: '/hr/permissions',
                icon: "key",
                name: tr('user_permissions'),
                permissions: ['hr.users.details'],
            },
            { 
                id: "hr.permits",
                to: '/hr/permits',
                icon: "id-card",
                name: tr('permits'),
                permissions: ['hr.permits'],
            },
            /*
            { 
                id: "hr.recruit",
                to: null,
                icon: "plus-square",
                name: "Rekrytointi",
                permissions: ['hr.recruit'],
                subMenuOpen: false,
                subMenu: [
                    { 
                        id: "recruit.jobs",
                        to: '/hr/recruit/jobs',
                        icon: "toolbox",
                        name: "Työpaikkailmoitukset",
                        permissions: ['hr.recruit'],
                    },
                    { 
                        id: "recruit.applicants",
                        to: '/hr/recruit/applicants',
                        icon: "envelope",
                        name: "Hakemukset",
                        permissions: ['hr.recruit.applicants'],
                    }
                ]
            },
            */
        ]
    },

    { 
        id: "storage",
        to: null,
        icon: "warehouse",
        name: tr('storage_management'),
        permissions: ['storage_?', 'purchases_?', 'logistic_?', 'webshop_?' ],
        subMenuOpen: false,
        subMenu: [
            {
                id: "storage.componentes",
                to: null,
                icon: "cubes",
                name: tr('storage'),
                permissions: ['storage'],
                subMenuOpen: false,
                subMenu: [
                    { 
                        id: "storage.items",
                        to: '/storage/components',
                        icon: "cube",
                        name: tr('components'),
                        permissions: ['storage.items'],
                    },
                    { 
                        id: "storage.locations",
                        to: '/storage/locations',
                        icon: "map-signs",
                        name: tr('storage_locations'),
                        permissions: ['storage.locations'],
                    },
                    {
                        id: "storage.consumables",
                        to: '/storage/consumables',
                        icon: "dolly-flatbed",
                        name: tr('consumables'),
                        permissions: ['storage.consumables'],
                    },
                    /*
                    { 
                        id: "storage.reports",
                        to: '/storage/reports',
                        icon: "file-alt",
                        name: "Raportit",
                        permissions: ['storage.reports'],
                    },
                    */
                    /*
                    { 
                        id: "storage.invetory",
                        to: '/storage/inventory',
                        icon: "archive",
                        name: "Inventaario",
                        permissions: ['storage.inventory'],
                    },
                    */
                ]
            },

            { 
                id: "purchases",
                to: null,
                icon: "shopping-basket",
                name: tr('purchases'),
                permissions: ['purchases'],
                subMenuOpen: false,
                subMenu: [
                     { 
                        id: "purchases.list",
                        to: '/storage/purchase/demands',
                        icon: "clipboard-list",
                        name: tr('order_requirements'),
                        permissions: ['purchases.orders.demands'],
                    },
                    { 
                        id: "purchases.orders",
                        to: '/storage/purchase/orders',
                        icon: "clipboard-check",
                        name: tr('purchase_orders'),
                        permissions: ['purchases'],
                    },
                    { 
                        id: "purchases.suppliers",
                        to: '/storage/purchase/suppliers',
                        icon: "building",
                        name: tr('suppliers'),
                        permissions: ['purchases.suppliers'],
                    },
                    {
                        id: "purchases.suppliers.groups",
                        to: "/crm/groups/supplier",
                        icon: "tags",
                        name: tr('supplier_groups'),
                        permissions: ['purchases.suppliers'],
                    },
                ]
            },
            
            { 
                id: "logistic",
                to: null,
                icon: "dolly",
                name: tr('logistics'),
                permissions: ['logistic'],
                subMenuOpen: false,
                subMenu: [
                    { 
                        id: "logistic.in",
                        to: '/storage/logistic/arrivals',
                        icon: "sign-in-alt",
                        name: tr('logistics_arrivals'),
                        permissions: ['logistic'],
                    },
                    { 
                        id: "logistic.out",
                        to: '/storage/logistic/deliveries',
                        icon: "sign-out-alt",
                        name: tr('logistics_deliveries'),
                        permissions: ['logistic'],
                    },
                    { 
                        id: "logistic.tracking",
                        to: '/storage/logistic/tracking',
                        icon: "road",
                        name: tr('logistics_tracking'),
                        permissions: ['logistic.tracking'],
                    },
                    { 
                        id: "logistic.transmit",
                        to: '/storage/transports/',
                        icon: "road",
                        name: tr('storage_transfers'),
                        permissions: ['logistic'],
                    },
                    { 
                        id: "logistic.storage.reader",
                        to: '/storage/reader',
                        icon: "barcode",
                        name: tr('logistics_reader'),
                        permissions: ['logistic.reader'],
                    },
                ]
            }, 
            /*
            { 
                id: "webshop",
                to: '/webshop',
                icon: "shopping-cart",
                name: "Nettikauppa",
                permissions: [], // TODO
            },
            {
                id: "purchases.suppliers.extranet",
                to: '/storage/purchase/suppliers/extranet',
                icon: "dolly-flatbed",
                name: "Toimittajamoduuli",
                permissions: [], // TODO
            },      
            */
        ]
    },

    { 
        id: "project-management",
        to: null,
        icon: "sitemap",
        name: tr('project_management'),
        permissions: ['projects_?', 'assignments_?',  ],
        subMenuOpen: false,
        subMenu: [
            { 
                id: "projects",
                to: '/projects',
                icon: "project-diagram",
                name: tr('projects'),
                permissions: ['projects'],
            },
            { 
                id: "projects.personnel",
                to: '/projectspersonnel',
                icon: "users",
                name: tr('production_management'),
                permissions: ['projects'],
            },
            { 
                id: "assignments",
                to: '/assignments',
                icon: "tasks",
                name: tr('assignments'),
                permissions: ['assignments.manage'],
            },
            { 
                id: "taskqueue",
                to: '/taskqueue',
                icon: "thumbtack",
                name: tr('task_queue'),
                permissions: ['assignments.manage'],
            },
            {
                id: 'workgroups',
                to: '/workgroups',
                icon: 'user-friends',
                name: tr('workgroups'),
                permissions: ['assignments.manage'],
            },
            {
                id: 'tax_announcement',
                to: '/tax_announcement',
                icon: 'receipt',
                name: tr('tax_announcements'),
                permissions: ['projects.edit'],
            },
            {
                id: 'material_certificates',
                to: '/material_certificates',
                icon: 'certificate',
                name: tr('material_certificates'),
                permissions: ['material_certificates'],
            }
        ]
    },

    /*
    { 
        id: "projects",
        to: null,
        icon: "sitemap",
        name: "Projektinhallinta",
        permissions: ['projects'],
        subMenuOpen: false,
        subMenu: [
            { 
                id: "projects.projects",
                to: '/projects',
                icon: "folder",
                name: "Projektit",
                permissions: ['projects'],
            },
            { 
                id: "projects.reports",
                to: '/projects/reports',
                icon: "file-alt",
                name: "Raportit",
                permissions: ['projects.reports'],
            },
            { 
                id: "maintanancesystem",
                to: '/projects/reports',
                icon: "wrench",
                name: "Kunnossapitojärjestelmä",
                permissions: [], // TODO
            },
        ]
    },
    */

    { 
        id: "financial",
        to: null,
        icon: "cash-register",
        name: tr('financial_management'),
        permissions: ['billable_?', 'invoices_?', 'orders_?', 'sales_?' ],
        subMenuOpen: false,
        subMenu: [
            { 
                id: "financial.billable",
                to: "/billable",
                icon: "euro-sign",
                name: tr('billing_work'), // Laskutettavat työt
                permissions: ['billable'],
            },
            // { 
            //     id: "invoices",
            //     to: "/invoices",
            //     icon: "inbox",
            //     name: "Saapuneet laskut",
            //     permissions: ['invoices'],
            // },
            { 
                id: "orders",
                to: '/storage/orders',
                icon: "receipt",
                name: tr('sales_orders'),
                permissions: ['orders'],
            },
            { 
                id: "contract_billing",
                to: '/storage/contract-billing',
                icon: "redo",
                name: tr('contract_billing'),
                permissions: ['orders.add'],
            },
            {
                id: "financial.sales",
                to: null,
                icon: "wallet",
                name: tr('sales_management'),
                permissions: ['sales'],
                subMenuOpen: false,
                subMenu: [
                    { 
                        id: "financial.sales.checkout",
                        to: '/orders/checkout',
                        icon: "shopping-cart",
                        name: tr('checkout'),
                        permissions: ['sales.checkout'],
                    },
                    { 
                        id: "financial.sales.collection",
                        to: '/orders/checkout/collection',
                        icon: "dolly-flatbed",
                        name: tr('collection'),
                        permissions: ['sales.collection'],
                    },
                ],
            },
            { 
                id: "cash-flow-forecast",
                to: '/cash-flow-forecast',
                icon: "money-bill-wave",
                name: tr('cash_flow_forecast'),
                permissions: ['orders', 'purchases'],
            },
            { 
                id: "online-store",
                to: '/online-store',
                icon: "shopping-cart",
                name: tr('online_store'),
                permissions: ['sales.onlinestore'],
            },
            
            /*
            { 
                id: "budget",
                to: '/budget',
                icon: "chart-bar",
                name: "Budjetti ja raportointi",
                permissions: [], // TODO
            },
            */
        ]
    },

    { 
        id: "reports",
        to: "/reports",
        icon: "chart-pie",
        name: tr('reports'),
        permissions: ['reports'],
    },
/*
    {
        id: "assignments",
        to: "/assignments",
        icon: "drafting-compass",
        name: "Toimeksiannot",
        permissions: []
    },
*/
    { 
        id: "company.settings",
        to: null,
        icon: "cogs",
        name: tr('settings'),
        // permissions: ['companysettings_?', 'timetracking.settings_?', 'storage.settings_?', 'purchases.settings_?', 'projects.settings_?', 'extranet.settings_?' ],
        permissions: [],
        adminOnly: true,

        subMenuOpen: false,
        subMenu: [
            {
                id: "company.settings.general",
                to: '/settings/general',
                icon: "cog",
                name: tr('general_settings'),
                // permissions: ['companysettings'],
                permissions: [],
            },
            {
                id: "crm.settings",
                to: "/settings/customers",
                icon: "bullseye",
                name: tr('customer_settings'),
                permissions: ['clients'],
            },
            {
                id: "timetracking.settings",
                to: '/timetracking/settings',
                icon: "clock",
                name: tr('working_time_settings'),
                // permissions: ['timetracking.settings'],
                permissions: [],
            },
            {
                id: "company.settings.workpermits",
                to: '/settings/workpermits',
                icon: "id-card",
                name: tr('work_permit_settings'),
                // permissions: ['companysettings']
                permissions: [],
            },
            {
                id: "company.settings.storage",
                to: '/settings/storage',
                icon: "cubes",
                name: tr('storage_settings'),
                // permissions: ['storage.settings'],
                permissions: [],
            },
            { 
                id: "company.settings.purchase",
                to: '/settings/purchase',
                icon: "shopping-cart",
                name: tr('purchase_settings'),
                // permissions: ['purchases.settings'],
                permissions: [],
            },
            { 
                id: "company.settings.order",
                to: '/settings/order',
                icon: "receipt",
                name: tr('sales_settings'),
                //permissions: ['purchases.settings'],
                permissions: [],
            },
            {
                id: "company.settings.projects",
                to: '/settings/projects',
                icon: "sitemap",
                name: tr('project_settings'),
                // permissions: ['projects.settings'],
                permissions: [],
            },
            {
                id: "company.settings.costCenter",
                to: '/settings/costCenter',
                icon: "euro-sign",
                name: tr('cost_center_settings'),
                // permissions: ['storage.settings'],
                permissions: [],
            },
/*
            {
                id: 'company.settings.assignments',
                to: '/settings/assignments',
                icon: "exclamation",
                name: "Toimeksiantoasetukset",
                permissions: [],
            },
*/          
            {
                id: "company.settings.notifications",
                to: '/settings/notifications',
                icon: "bell",
                name: tr('notification_settings'),
                permissions: [],
            },
            {
                id: "company.settings.extranet",
                to: '/settings/extranet',
                icon: "handshake",
                name: tr('extranet_settings'),
                permissions: [],
            },
        ]
    },

    { 
        header: "",
        globalAdminOnly: true,
    },
    { 
        id: "admin",
        to: null,
        icon: "user-astronaut",
        name: "Pääkäyttäjä",
        permissions: [],
        globalAdminOnly: true,
        subMenuOpen: false,
        subMenu: [
            {
                id: "admin.customers",
                to: '/management/customermanagement',
                icon: "toolbox",
                name: "Asiakashallinta",
                permissions: [],
            },
            {
                id: 'admin.billofuse',
                to: '/management/billofuse',
                icon: "money-bill-wave-alt",
                name: "Asiakaslaskutus",
                permissions: [],
            },

            {
                id: "admin.import",
                to: '/management/import',
                icon: "file-excel",
                name: "Excel tuonti",
                permissions: [],
            },
            {
                id: "admin.bugs",
                to: '/management/bugs',
                icon: "bug",
                name: "Bugi-ilmoitukset",
                permissions: [],
            },
            {
                id: "admin.fronterrors",
                to: '/management/fronterrors',
                icon: "exclamation-triangle",
                name: "Front virheet",
                permissions: [],
            },
            {
                id: "admin.usertracking",
                to: '/management/usertracking',
                icon: "fingerprint",
                name: "Käyttäjän jäljitys",
                permissions: [],
            },
            {
                id: "admin.loginslog",
                to: '/management/loginslog',
                icon: "key",
                name: "Kirjautumiset",
                permissions: [],
            },
            {
                id: "admin.wholesales",
                to: '/management/wholesales',
                icon: "warehouse",
                name: "Tukkuri logit",
                permissions: [],
            },
            {
                id: "admin.news",
                to: '/management/news',
                icon: "newspaper",
                name: "Uutiset",
                permissions: [],
            },
            {
                id: "admin.interfaces",
                to: '/management/interfaces',
                icon: "share-square",
                name: "Ulkoiset liittymät",
                permissions: [],
            },
            {
                id: "admin.translations",
                to: '/management/translations',
                icon: "language",
                name: tr('translations'),
                permissions: [],
            },
            {
                id: "admin.api",
                to: '/management/api',
                icon: "cloud",
                name: 'Api',
                permissions: [],
            },
        ]
    },

];

export default sideNavItems;
