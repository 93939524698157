/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSwitch from 'common/ApSwitch/ApSwitch';
import { tr } from 'services/Helpers/Helpers';
import ApSelect from 'common/ApSelect/ApSelect';

import styles from "./ProjectTypes.module.css";

const locations = [
    {value:'', label:'Valitse'},
    {value:'storage', label:tr('use_storage')},
    {value:'project', label:tr('create_project_storage')},  
]

export default class ProjectTypes extends React.Component {

    
    constructor(props) 
    {


        super(props);
        autoBind(this);
    }

    render()
    {
        return (
            
            <div className="padding projectTypes">

                { this.props.types && this.props.types.map( ( type, index ) => {
                
                    let example = type.code_prefix || '';
                    if( type.code_default_length && type.code_prefix && type.code_default_length > type.code_prefix.length )
                        example += "0".repeat( type.code_default_length - type.code_prefix.length );

                    if( !example )
                        example = tr('inactive');

                    const disabled = this.props.loading || type.removed;

                    let storage_selection = ""

                    if (type.project_storage_options==='storage_required_normal' && type.main_project_logging_disabled) {
                        storage_selection = <ApInputStack gap="0" id='checkout-storage-input'>
                                <ApAddon gapLeft={ 0 } >
                                    {tr('auto_storage_to_subproject')} : 
                                </ApAddon>
                                <ApSelect
                                    label= {tr('use_storage') }
                                    value={  type.auto_use_storage && type.auto_use_storage.name ? type.auto_use_storage.name: '' }
                                    optionRenderer= { 'storage_location_address' }
                                    onChange={ ( e ) => { this.props.onChange( type.id, "auto_use_storage", e ) } }
                                    objKeyId="id"
                                    clearable
                                    apiUrl={'storage/locations/find' }
                                    apiData={{
                                        //projectStorage: true, 
                                        canSend: true,
                                    }}
                                    disabled={ this.props.loading }
                                />
                            </ApInputStack>
                        }
                    if (type.project_storage_options==='storage_required_project' && type.main_project_logging_disabled) {
                        storage_selection = <ApInputStack gap="0" id='checkout-storage-input'>
                                <ApAddon gapLeft={0 } >
                                    {tr('auto_create_storage_to_subproject')}
                                </ApAddon>
                            </ApInputStack>
                        }                   
                    return (
                        <div className={"typeRow" + (type.removed ? " removed" : "")} key={index} style={{ marginBottom: '1em', paddingBottom: '1em', borderBottom: '2px solid lightgray'}} >
                            <ApInputStack gap="0">
                                <ApInput
                                    type="text"
                                    id={ "name" + index }
                                    name={ "name" + index }
                                    label={ tr('name') }
                                    value={ type.name || '' }
                                    onChange={ ( e ) => { this.props.onChange( type.id, "name", e.target.value ) } }
                                    loading={ this.props.loading }
                                    disabled={ disabled }
                                    validationState={ !type.name && !type.removed ? "warning" : "" }
                                />
                                
                                <ApAddon width={ 150 } gapLeft={ 10 } noRightBorder>
                                    { tr('numbering') }
                                </ApAddon>
                                <ApInput
                                    width={ 100 }
                                    type="text"
                                    id={ "prefix" + index }
                                    name={ "prefix" + index }
                                    label={ tr('prefix') }
                                    value={ type.code_prefix || '' }
                                    onChange={ ( e ) => { this.props.onChange( type.id, "code_prefix", e.target.value ) } }
                                    loading={ this.props.loading }
                                    disabled={ disabled }
                                />
                                <ApInput
                                    width={ 100 }
                                    type="number"
                                    id={ "length" + index }
                                    name={ "length" + index }
                                    label={ tr('length') }
                                    value={ type.code_default_length || '' }
                                    onChange={ ( e ) => { this.props.onChange( type.id, "code_default_length", e.target.value ) } }
                                    loading={ this.props.loading }
                                    disabled={ disabled }
                                    validationState={ type.code_default_length && type.code_prefix && type.code_default_length < type.code_prefix.length && !type.removed ? "warning" : "" }
                                />
                                <ApInput
                                    width={ 150 }
                                    type="text"
                                    id={ "example" + index }
                                    name={ "example" + index }
                                    label={ tr('example') }
                                    value={ example }
                                    readOnly
                                    disabled
                                />
                                
                                { type.projects_count == 0 && 
                                    <ApAddon 
                                        gapLeft={ 10 } 
                                        width={ 100 } 
                                        onClick={ () => this.props.onRemove( type.id ) } 
                                        tooltip={ type.removed ? tr('undo_deletion') : tr('delete') }
                                    >
                                        <SvgIcon icon={ type.removed ? "times" : "trash-alt" } type="solid" />
                                    </ApAddon>
                                }
                                { type.projects_count > 0 && 
                                    <ApAddon 
                                        gapLeft={ 10 } 
                                        width={ 100 } 
                                        tooltip={ tr('projects_in_total') }
                                    >
                                        <small>
                                            <SvgIcon className="small-inline" icon="folder" type="solid" />
                                            { type.projects_count } { tr('pcs') }
                                        </small>
                                    </ApAddon>
                                }
                            </ApInputStack>
                            <ApInputStack gap="0">
                            <ApAddon width={ 150 }>
                                    { tr('customer') }
                                </ApAddon>
                                <ApInput
                                    type="select"   
                                    options= { this.props.getProjectClientOption() }
                                    id={ "project_client_options" + index }
                                    name={ "project_client_options" + index }
                                    value={ type.project_client_options }
                                    onChange={ ( e ) => { this.props.onChange( type.id, "project_client_options", e.target.value ) } }
                                    loading={ this.props.loading }
                                />
                            <ApAddon width={ 150 }
                                        gapLeft={ 10 }>
                                    { tr('continuous') }
                                </ApAddon>
                                <ApInput
                                    type="select"   
                                    options= { this.props.getProjectContinuousOption() }
                                    id={ "project_continuous_options" + index }
                                    name={ "project_continuous_options" + index }
                                    value={ type.project_continuous_options }
                                    onChange={ ( e ) => { this.props.onChange( type.id, "project_continuous_options", e.target.value ) } }
                                    loading={ this.props.loading }
                                />
                                <ApAddon    width={ 150 }
                                            gapLeft={ 10 } >
                                    { tr('billing') }
                                </ApAddon>
                                <ApInput
                                    type="select"   
                                    options= { this.props.getProjectBillingOption() }
                                    id={ "project_billing_options" + index }
                                    name={ "project_billing_options" + index }
                                    value={ type.project_billing_options }
                                    onChange={ ( e ) => { this.handleBillingTypeChange(e, type); } }
                                    loading={ this.props.loading }
                                    disabled={ type.project_offer_options === 'offer_required'}
                                />
                                <ApAddon    width={ 150 }
                                            gapLeft={ 10 } >
                                    { tr('offer_calculation') }
                                </ApAddon>
                                <ApInput
                                    type="select"   
                                    options= { this.props.getProjectOfferOption() }
                                    id={ "project_offer_options" + index }
                                    name={ "project_offer_options" + index }
                                    value={ type.project_offer_options }
                                    onChange={ ( e ) => { this.handleOfferTypeChange(e, type); } }
                                    loading={ this.props.loading }
                                    disabled={ type.project_billing_options === 'hourly_billing_required'}
                                />
                            </ApInputStack>
                            <ApInputStack gap="0">
                            <ApAddon width={ 150 }>
                                { tr('storage') }
                                </ApAddon>
                                <ApInput
                                    type="select"   
                                    options= { this.props.getProjectStorageOption() }
                                    id={ "project_storage_options" + index }
                                    name={ "project_storage_options" + index }
                                    value={ type.project_storage_options }
                                    onChange={ ( e ) => { this.props.onChange( type.id, "project_storage_options", e.target.value ) } }
                                    loading={ this.props.loading }
                                />
                            {storage_selection}
                            
                            <span className={styles["toggler-container"]}>
                                <ApSwitch
                                    id={"main_project_logging_disabled" + index}
                                    on={type.main_project_logging_disabled}
                                    onChange={(e) => this.props.onToggle(type.id, "main_project_logging_disabled")}
                                />
                                <label htmlFor={"main_project_logging_disabled"+index} className="info">
                                    <small>{tr('nothing_to_main_project')}</small>
                                </label>
                            </span>
                            </ApInputStack>
                        <ApInputStack gap="0">
                            <ApAddon width={200}>
                                {tr('installation_required')}
                                </ApAddon>
                                <span className={styles["toggler-container"]}>
                                    <ApSwitch
                                        id={"installation_required" + index}
                                        on={type.installation_required}
                                        onChange={(e) => this.props.onToggle(type.id, "installation_required")}
                                    />

                                    <label htmlFor={"installation_required" + index} className="info">
                                        <small>{tr('installation_required_description')}</small>
                                    </label>
                                </span>
                                
                            </ApInputStack>
                            {type.installation_required && <ApInputStack gap="0">
                                <ApAddon width={400}>
                                    {tr('show_products_in_billing_after_days')}
                                </ApAddon>
                                <ApInput
                                    width={50}
                                    type="number"
                                    id={"installation_visible_after_days" + index}
                                    name={"installation_visible_after_days" + index}
                                    label={tr('days')}
                                    value={type.installation_visible_after_days || ''}
                                    onChange={(e) => { this.props.onChange(type.id, "installation_visible_after_days", e.target.value) }}
                                    loading={this.props.loading}
                                    disabled={disabled}
                                />
                            </ApInputStack>}
                            
                        </div>
                    );
                })}

                <ApButton color="white" onClick={ this.props.onAdd }>
                    <SvgIcon icon="plus" type="solid" />
                    { tr('add_project_type') }
                </ApButton>

            </div>
        );
    }

    
    handleBillingTypeChange(e, type) {
        const newVal = e.target.value;
        this.props.onChange( type.id, "project_billing_options", newVal );

        //Vaihda tarjouslaskenta pois päältä jos tuntilaskutus pakollinen
        if (newVal == "hourly_billing_required") {
            this.props.onChange(type.id, "project_offer_options", "cannot_make_offer");
        }
    }

    handleOfferTypeChange(e, type) {
        const newVal = e.target.value;
        this.props.onChange( type.id, "project_offer_options", newVal );

        //Vaihda tarjouslaskenta pois päältä jos tuntilaskutus pakollinen
        if (newVal == "offer_required") {
            this.props.onChange(type.id, "project_billing_options", "cannot_be_hourly_billable");
        }
    }

}