import React, { useEffect } from "react";
import propTypes from "prop-types";
import { READ_ONLY_TYPES, entryTypeOptions, recurrenceOptions } from "./CalendarHelpers";
import { connect } from "react-redux";
import { capitalize, getWeekdays, hasPermissions, keyExists, tr } from "services/Helpers/Helpers";
import { ApAddon, ApInput, ApInputStack } from "common/ApInput/ApInput";
import ApSwitch from "common/ApSwitch/ApSwitch";
import ApTooltip from "common/ApTooltip/ApTooltip";
import ApSelect from "common/ApSelect/ApSelect";
import moment from "moment";
import SvgIcon from "common/SvgIcon/SvgIcon";
import ApButton from "common/ApButton/ApButton";
import InstallationRow from "./InstallationRow";
import { Collapse } from "react-bootstrap";


const CalendarModalBody = (props) => {
    const [showInstallations, setShowInstallations] = React.useState(localStorage.getItem('calendar_showInstallations') === 'true');
    const readOnly = props.readOnly || READ_ONLY_TYPES.includes(props.selectedEvent?.type);

    // Calculate automatic time for installations that have no time set
    const calculateAutomaticTime = () => {
        if (!props.eventData.project) return;

        let start = new Date(props.eventData.start);
        let end = new Date(props.eventData.end);
        if (!start.getTime() || !end.getTime()) return;

        let diff = end - start;
        let minutes = Math.floor((diff / 1000) / 60);


        let installations = props.eventData.installations_components || [];
        let installationsStorage = props.eventData.installations_components_storage || [];
        let hasNoTimeSetCount = 0;
        let usedMinutes = 0;

        const calcMinutesAndCount = (installation) => {
            if (!parseFloat(installation.time)) {
                hasNoTimeSetCount++;
            }
            if (parseFloat(installation.time)) {
                usedMinutes += parseFloat(installation.time);
            }
        }

        installations.forEach(calcMinutesAndCount);
        installationsStorage.forEach(calcMinutesAndCount);

        let installationsExpenses = props.eventData.installations_expenses || [];
        let installationsExpensesStorage = props.eventData.installations_expenses_storage || [];

        installationsExpenses.forEach(calcMinutesAndCount);
        installationsExpensesStorage.forEach(calcMinutesAndCount);

        if (hasNoTimeSetCount > 0) {
            let remainingMinutes = minutes - usedMinutes;
            let remainingPerInstallation = 0;
            if (remainingMinutes > 0) {
                remainingPerInstallation = Math.floor(remainingMinutes / hasNoTimeSetCount);
            }
            if (remainingPerInstallation < 0) remainingPerInstallation = 0;
            installations.forEach(installation => {
                if (!parseFloat(installation.time)) {
                    installation.automaticTime = remainingPerInstallation;
                }
            });
            installationsStorage.forEach(installation => {
                if (!parseFloat(installation.time)) {
                    installation.automaticTime = remainingPerInstallation;
                }
            });
            installationsExpenses.forEach(installation => {
                if (!parseFloat(installation.time)) {
                    installation.automaticTime = remainingPerInstallation;
                }
            });
            installationsExpensesStorage.forEach(installation => {
                if (!parseFloat(installation.time)) {
                    installation.automaticTime = remainingPerInstallation;
                }
            });

            props.updateEventData('installations_components', installations);
            props.updateEventData('installations_components_storage', installationsStorage);
            props.updateEventData('installations_expenses', installationsExpenses);
            props.updateEventData('installations_expenses_storage', installationsExpensesStorage);
        }
    }

    // When recurrence is initially set, set recurrence start to the selected date
    useEffect(() => {
        if (props.eventData.frequency && !props.eventData.recurrence_start) {
            props.updateEventData('recurrence_start', props.eventData.start);
        }
    }, [props.eventData.frequency]);

    useEffect(() => {
        calculateAutomaticTime();
    }, [
        props.eventData.start, 
        props.eventData.end, 
        JSON.stringify(props.eventData.installations_components), 
        JSON.stringify(props.eventData.installations_components_storage),
        JSON.stringify(props.eventData.installations_expenses),
        JSON.stringify(props.eventData.installations_expenses_storage),
    ]);

    const renderTitle = (readOnly) => {
        if (!readOnly) {
            return <ApInput
                type='text'
                id='title'
                name='title'
                label={tr('header')}
                value={props.eventData.title}
                readOnly={readOnly}
                onChange={e => props.updateEventData('title', e.target.value)}
                validationState={props.eventData.title ? '' : 'error'}
            />
        } else {
            return null;
        }
    }

    const renderDescription = (readOnly) => {
        if (!readOnly) {
            return <ApInput
                id='description'
                name='description'
                type='textarea'
                label={tr('description')}
                value={props.eventData.description}
                onChange={event => props.readOnly ? null : props.updateEventData('description', event.target.value)}
                disabled={readOnly}
                readOnly={readOnly}
            />
        }
        else if (readOnly && description) {
            return <div>
                <h6 className='formHeader'>{tr('description')}</h6>
                {props.eventData.description}
            </div>
        }
        return null;
    }

    let eventTypeOptions = [];
    if (props.apGeneralSettings.calendarEventTypes) {
        eventTypeOptions = props.apGeneralSettings.calendarEventTypes
            .filter(eventType => !eventType.is_static)
            .map(eventType => {
                return {
                    value: eventType.id,
                    label: eventType.name,
                }
            });
    }
    eventTypeOptions.unshift({
        value: '',
        label: `- ${tr('other')} -`
    })

    const getInstallationOptions = (currentValue = null, type) => {
        const selected = keyExists(props.eventData, "installations_" + type, true, []).map(i => parseInt(i.id));
        let options = [];

        keyExists(props.eventData, "project." + type, true, []).forEach(c => {
            if (c.in_timetracking) {
                let disabled = c.id != currentValue && selected.includes(c.id);
                options.push({
                    ...c,
                    disabled,
                    type,
                    label: c.name,
                    value: c.id,
                    unit: c.component?.unit,
                    count: 1,
                });
            }
        });
        return options;
    }

    /**
     * Renders the installations based on the provided type.
     *
     * @param {string} type - The type of installations to render. Type is either components or expenses.
     * @returns {JSX.Element|null} Returns the installations elements or null if no project is selected or no installation options are available.
     */
    const renderInstallations = (type = 'components') => {
        // If no project selected, return null
        if (!props.eventData.project) {
            return null;
        }

        let installationOptions = getInstallationOptions(null, type);

        if (installationOptions.length === 0) {
            return null;
        }

        const selectedInstallations = keyExists(props.eventData, "installations_" + type, true, []);
        const selectedStorageInstallation = keyExists(props.eventData, "installations_" + type + "_storage", true, []);

        // Get all component ids of installation options and selected storage installations so we can exclude them from search
        let excludeIds = [];
        installationOptions.forEach((option) => {
            excludeIds.push(option.component_id);
        });
        selectedStorageInstallation.forEach((option) => {
            if (option.selectedValue) {
                excludeIds.push(option.selectedValue.id);
            }
        });

        const installationTr = type === 'components' ? tr('add_component') : tr('add_billable_cost');
        const installationFromStorageTr = type === 'components' ? tr('add_component_from_storage') : tr('add_billable_from_storage');
        const headerText = type === 'components' ? tr('add_component') : tr('billing_costs');

        return <div className="apFormGroup">
            <h6>{headerText}</h6>
            {selectedInstallations.map((installation, index) => {
                return <InstallationRow
                    key={index}
                    installation={installation}
                    installationOptions={getInstallationOptions(installation.id, type)}
                    fromStorage={false}
                    projectSettings={props.apProjectSettings}
                    editRow={(objKey, value) => {
                        selectedInstallations[index][objKey] = value;
                        props.updateEventData('installations_' + type, selectedInstallations);
                    }}
                    removeRow={() => {
                        selectedInstallations.splice(index, 1);
                        props.updateEventData('installations_' + type, selectedInstallations);
                    }}
                    changeRow={(id) => {
                        let newInstallation = installationOptions.find(installation => installation.id == id);
                        selectedInstallations[index] = {
                            ...newInstallation,
                            count: installation.count,
                            time: installation.time,
                        }
                        props.updateEventData('installations_' + type, selectedInstallations);
                    }}
                />
            })}
            {selectedStorageInstallation.map((installation, index) => {
                return <InstallationRow
                    key={index}
                    index={index}
                    installation={installation}
                    installationOptions={getInstallationOptions(installation.id, type)}
                    fromStorage={true}
                    projectSettings={props.apProjectSettings}
                    excludeIds={excludeIds}
                    editRow={(objKey, value) => {
                        selectedStorageInstallation[index][objKey] = value;
                        props.updateEventData('installations_' + type + '_storage', selectedStorageInstallation);
                    }}
                    removeRow={() => {
                        selectedStorageInstallation.splice(index, 1);
                        props.updateEventData('installations_' + type + '_storage', selectedStorageInstallation);
                    }}
                />
            })}
            {selectedInstallations.length < installationOptions.length &&
                <ApButton color="white" size="small" onClick={() => addRow(type)}>
                    <SvgIcon icon="plus" type="solid" />
                    {installationTr}
                </ApButton>
            }
            <ApButton color="white" size="small" onClick={() => addRow(type + '_storage')}>
                <SvgIcon icon="plus" type="solid" />
                {installationFromStorageTr}
            </ApButton>
        </div>;
    }

    const addRow = (type) => {
        // add first available installation that is not already selected
        let installationOptions = getInstallationOptions(null, type);
        let selectedInstallations = keyExists(props.eventData, "installations_" + type, true, []);
        let selectedIds = selectedInstallations.map(installation => installation.id);
        let newInstallation = null;

        // if type includes storage, add empty installation row from storage
        if (type.includes('storage')) {
            newInstallation = {
                id: 'new',
                component: null,
                count: 1,
            }
        } else {
            newInstallation = installationOptions.find(installation => {
                return !selectedIds.includes(installation.id);
            });
        }

        if (newInstallation) {
            newInstallation.type = type;
            selectedInstallations.push(newInstallation);
            props.updateEventData('installations_' + type, selectedInstallations);
        }
    }

    const renderRecurrence = () => {
        // Render nothing if event type is static
        if (props.eventData.is_static) {
            return null;
        }

        const recurIntervalTr = (interval, intervalNum) => {
            switch (interval) {
                case 'daily':
                case 'weekdays':
                    return tr('recur_days', [intervalNum]);
                case 'weekly':
                    return tr('recur_weeks', [intervalNum]);
                case 'monthly':
                    return tr('recur_months', [intervalNum]);
                default:
                    return null;
            }
        }
        return (
            <>
                <h5 className="formHeader">{tr('recurrence')}</h5>
                <ApInput
                    type="select"
                    id="frequency"
                    name="frequency"
                    label={tr('recurrence')}
                    options={recurrenceOptions}
                    value={props.eventData.frequency}
                    onChange={e => props.updateEventData('frequency', e.target.value)}

                />
                {props.eventData.frequency &&
                    <>
                        <ApInputStack gap="0">
                            <ApInput
                                type="datetimeV2"
                                id="recurrence_start"
                                name="recurrence_start"
                                label={tr('recurrence_begins')}
                                value={props.eventData.recurrence_start}
                                onChange={e => props.updateEventData('recurrence_start', e)}
                                validationState={!props.eventData.recurrence_start ? 'error' : ''}
                            />
                            <ApInput
                                type="datetimeV2"
                                id="recurrence_end"
                                name="recurrence_end"
                                label={tr('recurrence_ends')}
                                value={props.eventData.recurrence_end}
                                onChange={e => props.updateEventData('recurrence_end', e)}
                                validationState={!props.eventData.recurrence_end ? 'error' : ''}
                            />
                        </ApInputStack>
                        <ApInput
                            type="number"
                            id="interval"
                            name="interval"
                            label={recurIntervalTr(props.eventData.frequency, props.eventData.interval ?? 0)}
                            value={props.eventData.interval}
                            onChange={e => {
                                if (e.target.value < 1) {
                                    e.target.value = 1;
                                }

                                props.updateEventData('interval', e.target.value);
                            }}
                        />
                        {props.eventData.frequency === 'daily' &&
                            <>
                                <ApSelect
                                    multiselect
                                    clearable
                                    options={getWeekdays()}
                                    optionRenderer="label"
                                    objKeyId="value"
                                    objKeySearchable="label"
                                    valueRenderer="label"
                                    value={props.eventData.days || []}
                                    onChange={days => {
                                        props.updateEventData('days', days);
                                    }}
                                    loading={props.loading}
                                    disabled={props.loading}
                                    label={tr('recurrence')}
                                    noSorting={true}
                                    keepFocusOnSelect={true}
                                />
                                <div className="apInfo small">
                                    <SvgIcon icon="info-circle" type="solid" />
                                    {tr('event_recur_days') + ' (' + tr('event_recur_days_info') + ')'}
                                </div>
                            </>
                        }

                        {props.eventData.frequency === 'monthly' &&
                            <>
                                <div className={"apFormGroup" + (props.eventData.last_of_month ? " success" : "")}>
                                    <div className="apSwitchBlock small">
                                        <label htmlFor="last_day_of_month" className="info">
                                            {tr('last_of_month')}
                                        </label>
                                        <ApSwitch
                                            id="last_day_of_month"
                                            name="last_day_of_month"
                                            on={props.eventData.last_of_month}
                                            onChange={e => {
                                                // Set the eventData start and end to the last of month
                                                if (e.target.checked && props.eventData.start) {
                                                    let startDate = new Date(props.eventData.start);
                                                    const originalMonth = startDate.getMonth();
                                                    startDate.setMonth(originalMonth + 1);
                                                    // Check month overflow
                                                    if (startDate.getMonth() - originalMonth > 1) {
                                                        startDate.setMonth(startDate.getMonth() - 1);
                                                    }

                                                    startDate.setDate(0);
                                                    startDate = moment(startDate);
                                                    props.updateEventData('start', startDate);

                                                    let endDate = new Date(props.eventData.end);
                                                    endDate.setMonth(endDate.getMonth() + 1);
                                                    // Check month overflow
                                                    if (endDate.getMonth() - originalMonth > 1) {
                                                        endDate.setMonth(endDate.getMonth() - 1);
                                                    }

                                                    endDate.setDate(0);
                                                    endDate = moment(endDate);
                                                    props.updateEventData('end', endDate);
                                                }

                                                props.updateEventData('last_of_month', e.target.checked);
                                            }}
                                            loading={props.loading}
                                        />
                                    </div>
                                </div>
                                <div className="apInfo small">
                                    <SvgIcon icon="info-circle" type="solid" />
                                    {tr('recur_last_of_month_info')}
                                </div>
                            </>
                        }
                    </>
                }
                <hr />
            </>
        )
    }

    /**
     * Validates the given date.
     *
     * @param {string} date - The date to validate.
     * @returns {string} Returns 'error' if the date is invalid or empty, otherwise returns an empty string.
     */
    const validateDate = (date) => {
        if (!date) {
            return 'error';
        }
        // check if valid date
        if (isNaN(new Date(date).getTime())) {
            return 'error';
        }
        return '';
    }

    let showProgress = true;
    if (!props.eventData.project || !props.eventData.project.works || props.eventData.entry_type === 'travel') {
        showProgress = false;
    }
    const componentInstallations = getInstallationOptions(null, 'components');
    const expenseInstallations = getInstallationOptions(null, 'expenses');
    if (componentInstallations.length === 0 && expenseInstallations.length === 0) {
        showProgress = false;
    }

    return (
        props.selectedEvent && (
            <div className='padding'>
                {readOnly && props.selectedEvent?.type && <h5 className='formHeader'>{tr(props.selectedEvent.type)}</h5>}
                {renderTitle(readOnly)}
                {!readOnly && <ApInputStack gap="0">
                    <ApAddon width="100">{tr('type')}</ApAddon>
                    <ApInput
                        type="select"
                        id="type"
                        name="type"
                        value={eventTypeOptions.find(option => option.value == props.eventData.company_event_type_id) ? props.eventData.company_event_type_id : ''}
                        disabled={readOnly}
                        readOnly={readOnly}
                        onChange={e => {
                            props.updateEventData('company_event_type_id', e.target.value);
                        }}
                        options={eventTypeOptions}
                    />
                </ApInputStack>}
                {(!props.eventData.is_static) && hasPermissions('timetracking.entries') && <div>
                    <ApSelect
                        label={tr('select_project')}
                        value={props.eventData.project ? props.eventData.project.name + ": " + props.eventData.project.project_code : ""}
                        optionRenderer="project_assignment"
                        onChange={(e) => props.updateEventData('project', e)}
                        objKeyId="id"
                        clearable
                        request_time={true}
                        apiUrl="timetracking/projects"
                        apiData={{
                            date: moment(props.eventData.start).format('YYYY-MM-DD'),
                        }}
                    />
                    {/* Entry type select */}
                    {props.eventData.project &&
                        <ApInputStack gap="0">
                            <ApAddon width="150">{tr('entry_type')}</ApAddon>
                            <ApInput
                                type="select"
                                id="entry_type"
                                name="entry_type"
                                label={tr('entry_type')}
                                options={entryTypeOptions}
                                value={props.eventData.entry_type}
                                onChange={e => props.updateEventData('entry_type', e.target.value)}
                            />
                        </ApInputStack>}
                    {/* Project work select */}
                    {(props.eventData.project?.works && props.eventData.entry_type !== 'travel') &&
                        <ApInputStack gap="0">
                            <ApAddon width="150">{tr('work_title')}</ApAddon>
                            <ApInput
                                type="select"
                                id="project_work"
                                name="project_work"
                                label={tr('project_work')}
                                options={props.eventData.project.works.map(work => {
                                    return {
                                        value: work.id,
                                        label: work.name,
                                    }
                                })}
                                value={props.eventData.project_work_id}
                                onChange={e => props.updateEventData('project_work_id', e.target.value)}
                            />
                        </ApInputStack>}
                    {props.eventData.entry_type === 'travel' &&
                        <>
                            <ApInput
                                type="select"
                                id="vehicle_type"
                                name="vehicle_type"
                                label={tr('vehicle_type')}
                                options={props.apTimetrackingSettings.vehicles.map(vehicleType => {
                                    return {
                                        value: vehicleType.id,
                                        label: vehicleType.name,
                                    }
                                })}
                                value={props.eventData.vehicle_id}
                                onChange={e => props.updateEventData('vehicle_id', e.target.value)}
                            />
                            <ApInput
                                type="text"
                                id="route"
                                name="route"
                                label={tr('route')}
                                value={props.eventData.route}
                                onChange={e => props.updateEventData('route', e.target.value)}
                                validationState={props.eventData.route ? '' : 'error'}
                            />
                            <ApInputStack gap="0">
                                <ApInput
                                    type="number"
                                    id="distance"
                                    name="distance"
                                    label={tr('trip_length')}
                                    value={props.eventData.distance}
                                    onChange={e => props.updateEventData('distance', e.target.value)}
                                    validationState={props.eventData.distance ? '' : 'error'}
                                />
                                <ApAddon width="10">km</ApAddon>
                            </ApInputStack>
                        </>
                    }
                </div>}
                <ApInputStack gap='0'>
                    <ApInput
                        id='start'
                        name='start'
                        type='datetimeV2'
                        label={capitalize(tr('begins'))}
                        value={props.eventData.start}
                        enableTime={!props.eventData.all_day}
                        onChange={value => {
                            // If last_of_month is set and new date is not the last day of month, unset last_of_month
                            let lastOfMonthDate = new Date(value);
                            lastOfMonthDate.setMonth(lastOfMonthDate.getMonth() + 1);
                            // Check month overflow
                            if (lastOfMonthDate.getMonth() - new Date(value).getMonth() > 1) {
                                lastOfMonthDate.setMonth(lastOfMonthDate.getMonth() - 1);
                            }
                            lastOfMonthDate.setDate(0);

                            if (props.eventData.last_of_month && new Date(value).getDate() != new Date(lastOfMonthDate).getDate()) {
                                props.updateEventData('last_of_month', false);
                            }
                            props.updateEventData('start', value);
                        }}
                        disabled={readOnly}
                        readOnly={readOnly}
                        validationState={validateDate(props.eventData.start)}
                    />

                    <ApAddon width="10">-</ApAddon>

                    <ApInput
                        id='end'
                        name='end'
                        type='datetimeV2'
                        label={capitalize(tr('ends'))}
                        value={props.eventData.end}
                        enableTime={!props.eventData.all_day}
                        onChange={value => props.updateEventData('end', value)}
                        disabled={readOnly}
                        readOnly={readOnly}
                        validationState={validateDate(props.eventData.end)}
                    />
                </ApInputStack>

                <div className='switchContainer'>
                    <label htmlFor='allDay'>
                        {tr('all_day')}
                        <ApSwitch
                            on={props.eventData.all_day}
                            inline
                            id='allDay'
                            label={tr('all_day')}
                            onChange={event => {
                                props.updateEventData('all_day', event.target.checked);
                            }}
                            disabled={readOnly}
                        />
                    </label>
                </div>
                <div className='switchContainer'>
                    <ApTooltip text={tr('private_calendar_event_text')}>
                        <label htmlFor='isPrivate'>
                            {tr('private')}
                            <ApSwitch
                                on={props.eventData.is_private}
                                inline
                                id='isPrivate'
                                label={tr('private')}
                                onChange={event => {
                                    props.updateEventData('is_private', event.target.checked);
                                }}
                                disabled={readOnly}
                            />
                        </label>
                    </ApTooltip>
                </div>

                {showProgress &&
                <>
                    <h6
                        onClick={() => {
                            setShowInstallations(!showInstallations);
                            localStorage.setItem('calendar_showInstallations', !showInstallations);
                        }}
                        className="formHeader pointer"
                    >
                        <SvgIcon style={{transition: '0.3s'}} className={showInstallations ? '' : 'rotate-anti-clockwise-90'} icon='chevron-down' type='solid' /> {tr('progress')}
                    </h6>
                    <Collapse in={showInstallations}>
                        <div>
                            {/* Component Installations */}
                            {renderInstallations()}

                            {/* Expenses */}
                            {renderInstallations('expenses')}
                        </div>
                    </Collapse>
                </>
                }

                {renderRecurrence()}

                {renderDescription(readOnly)}

                {/* Only show color options if selected type is not from company settings */}
                {(!props.eventData.company_event_type_id || props.eventData.company_event_type_id === '') && <>
                    <div style={{ width: '125px', display: readOnly ? 'none' : 'block' }}>
                        <ApInput
                            type="color"
                            id="color"
                            name="color"
                            label={tr('choose_color')}
                            value={props.eventData.color}
                            onChange={e => props.updateEventData('color', e.target.value)}
                            disabled={readOnly}
                        />
                    </div>
                    {!readOnly && <div className='usedColors'>
                        {props.colors.map((color, index) => {
                            return (
                                <div
                                    key={index}
                                    className='color'
                                    style={{ backgroundColor: color }}
                                    onClick={() => props.updateEventData('color', color)}
                                >
                                </div>
                            );
                        })}
                    </div>
                    }
                </>}
            </div>
        )
    );
}

CalendarModalBody.propTypes = {
    selectedEvent: propTypes.object,
    readOnly: propTypes.bool.isRequired,
    colors: propTypes.array.isRequired,
    eventData: propTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        apGeneralSettings: state.apGeneralSettings,
        apTimetrackingSettings: state.apTimetrackingSettings,
        apProjectSettings: state.apProjectSettings,
    }
}

export default connect(mapStateToProps)(CalendarModalBody);