/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import auth from 'services/Authed/Authed.js';

import { currentLang, formatMoney, keyExists, tr } from  'services/Helpers/Helpers.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';

import Entry from './Entry/Entry.js';

export default class Day extends React.Component {

    constructor(props) 
    {
        super(props);
        autoBind(this); 
    }

    onDayClick()
    {
    	if( !this.showReadOnly && this.props.entries.filter( e => e.readOnly == false ).length == 0 )
    		this.props.onToggleReadOnly( true );
    }

    extraTooltip( extra )
    {
        return (
            <div>
                { extra.rule_id ? "Automaattinen lisä" : "Kertaluontoinen lisä" }<br />
                <span>{ tr('creator') }: { keyExists( extra, "creator.name", true, "Tuntematon" ) }</span><br />
                <span>{ tr('edited') } { moment( extra.updated_at ).format('D.M.Y HH.mm') }</span>
            </div>
        );
    }

    render() 
    {
        const noOwnEntries = this.props.entries.filter( e => e.readOnly == false ).length == 0;

        return (
            <div className={ "day" + ( noOwnEntries ? " empty" : "" ) } key={ this.props.date } onClick={ !this.props.old ? this.onDayClick : null } style={{minWidth: 1200}}>
                
                <div className="dayName">
                    <strong>{ moment( this.props.date, "YYYY-MM-DD" ).locale(currentLang()).format( 'dddd' ) }</strong><br />
                    <small>{ moment( this.props.date, "YYYY-MM-DD" ).format( 'DD.MM.YYYY' ) }</small>
                </div>
                <div >
                    
                    {this.props.renderOverview([this.props.entries], true,true)}
                    
                </div>

                { this.props.entries && this.props.entries.map( e => {
                	return (
                		<Entry
                			key={ e.id }
                			data={ e }
                            settings={ this.props.settings }
                			selected={ this.props.selectedEntries.indexOf( e.id ) != -1 }
                			changes={ this.props.changes?.find( c => c.id == e.id ) || {} }
                			noOwnEntries={ noOwnEntries }
                			renderOverview={ this.props.renderOverview }
                			showReadOnly={ this.props.showReadOnly }
                			statuses={ this.props.statuses }
                            onToggleReadOnly={ this.props.onToggleReadOnly }
                            onToggleStatus={ this.props.onToggleStatus }
                            onEntrySelect={ this.props.onEntrySelect }
                            onEditComment={ this.props.onEditComment }
                		/>
                	);
                })}

                { this.props.showSalaryExtras && this.props.salaryExtras && this.props.salaryExtras.map( ( extra, index ) =>
                    <div className={ "salaryExtra " + ( extra.rule_id ? "hasRule" : "noRule" ) }>
                        <ApTooltip text={ this.extraTooltip( extra ) }>
                            <SvgIcon className="small-inline" icon="trophy" type="solid" />
                            { extra.name }: <strong>+{ formatMoney( extra.total_salary_extra ) } { auth.getCurrencySign() }</strong>
                        </ApTooltip> 
                    </div>
                )}
            
            </div>
        );
    }
}
