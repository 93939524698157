import ApCalendar from 'common/ApCalendar/ApCalendar';
import React, { useState, useEffect, useRef } from 'react';
import { tr } from 'services/Helpers/Helpers';

const Calendar = (props) => {
    const [calendarLoading, setCalendarLoading] = useState(true);

    return (
        <div className='apBox'>
            <div className="apBoxHeader">
                <h1>{tr('calendar')} {calendarLoading && <span className='apLoader'></span>}</h1>
                {/* <p>Calendar info TODO</p> */}
            </div>

            <div className='padding'>
                <ApCalendar
                    events={[]}
                    getLoading={(loading) => setCalendarLoading(loading)}
                />
            </div>
        </div>
    );
}

export default Calendar;