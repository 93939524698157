import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import { ApTabs, 
         ApTab } from 'common/ApTabs/ApTabs.js';

import CompanySettings from './CompanySettings.js';
import CompanyNews from './CompanyNews.js';
import CompanyDefaults from './CompanyDefaults.js';
import CompanyInterfaces from './CompanyInterfaces.js';
import { tr } from 'services/Helpers/Helpers.js';
import OrganizationalChartSetting from './OrganizationalChartSetting.js';
import CalendarSettings from './CalendarSettings.js';

export default class GeneralSettings extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			news: []
		};

		autoBind(this);

	}

	componentDidMount()
	{
		this.getNewsitems()
	}

	getNewsitems()
	{

		api({
			method: 'get',
			url: 'company/settings/getNewsItems'
		}).then((response) => {
			this.setState({ news: response });
		}).catch((error) => {
			console.log(error);
		});

	}

	render()
	{ 

		return(

			<div className="apBox">

				<div className="apBoxHeader">
					<h1> { tr('general_settings') } </h1>
					<p> { tr('general_settings_info') } </p>
				</div>

				<ApTabs>

					<ApTab icon="cog" label={ tr('company_settings') }>
						<CompanySettings />
					</ApTab>

					<ApTab icon="newspaper" label={ tr('news') }>
						<CompanyNews 
							data={this.state.news}
						/>
					</ApTab>
						
					<ApTab icon="share-square" label={ tr('external_interfaces')}>
						<CompanyInterfaces	 />
					</ApTab>

					<ApTab icon="check" label={ tr('default_settings') }>
						<CompanyDefaults/>
					</ApTab>						
					
					<ApTab icon="sitemap" label={ tr('organizational_chart') } mountOnSelect>
						<OrganizationalChartSetting/>
					</ApTab>

					<ApTab icon='calendar-day' label={ tr('calendar') } mountOnSelect>
						<CalendarSettings/>
					</ApTab>						
				</ApTabs>

			</div>

		);

	}

}
