/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';

import { keyExists } from 'services/Helpers/Helpers.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
// import ApButton from 'common/ApButton/ApButton.js';
// import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';

import { formatMoney, tr } from  'services/Helpers/Helpers.js';

// import { Collapse } from 'react-bootstrap';


export default class TravelEntry extends React.Component {

	constructor(props) 
    {
        super(props);
        autoBind(this);
    }

   
	getVehicleOptions()
	{
		let options = [];
		const vehicles = keyExists( this.props, "vehicles", true, [] );
		vehicles.forEach( w => {
			options.push({
				value: w.id,
				label: w.name
			});
		});
		return options;
	}

	getVehicleCompensation()
	{
		const vehicleId = keyExists( this.props, "data.detail.vehicle_id", true );
		if( vehicleId )
		{
            const vehicle = keyExists( this.props, "vehicles", true, [] ).find( v => v.id == vehicleId );
			return ( vehicle ? vehicle.compensation : 0 );
		}
		return 0;
    }
    
    getTravelHoursCompensation()
	{
		const vehicleId = keyExists( this.props, "data.detail.vehicle_id", true );
		if( vehicleId )
		{
            const vehicle = keyExists( this.props, "vehicles", true, [] ).find( v => v.id == vehicleId );
			return ( vehicle ? vehicle.compensation_hours : 0 );
		}
		return 0;
	}

    validateDescription()
    {
        const vehicleId = keyExists( this.props, "data.detail.vehicle_id", true );
        const vehicle = keyExists( this.props, "vehicles", true, [] ).find( v => v.id == vehicleId );
        const desc = keyExists( this.props, "data.description", true, '' );
        if( vehicle.is_entry_description_required && !desc )
            return false;

        return true;
    }
    
    showCustomerWorkNumber()
    {
        const project = keyExists( this.props, "data.project", true );
        if( !project )
            return false;

        if( !project.billing_hourly )
            return false;

        let customer = project.customer_id;
        if( !customer && project.ancestors.length > 0 )
            customer = project.ancestors[0].customer_id;

        if( !customer )
            return false;

        return true;
    }

    validateCustomerWorkNumber() {
        // check if customer work number is required in project
		const project = keyExists( this.props, "data.project", true );
		if( !project )
			return '';

		if (project.customer_work_number_required) {
			return keyExists( this.props, "data.customer_work_number", true, 'error' );
		}
    }

    validateTravelDistanceAndTime() {
        // check if customer work number is required in project

        // validationState = { keyExists(this.props, "data.detail.route", true, '' ) ?"": "error" }
        let distance = keyExists(this.props, "data.detail.distance", true, '');
        let hours = keyExists(this.props, "data.detail.hours", true, '');


        

        if (!Boolean(Number(hours)) && !Boolean(Number(distance))) {
            return 'error';
        }
        return '';

    }

    render()
    {
        return (
            <div>

            	<ApInput 
                    type="text" 
                    id="description" 
                    name="description" 
                    label={ tr('description') }
                    value={ keyExists( this.props, "data.description", true, '' ) }
                    onChange={ this.props.onChange }
                    validationState={ this.validateDescription() ? "" : "error" }
                />

                { this.showCustomerWorkNumber() &&
                    <ApInput 
                        type="text" 
                        id="customer_work_number" 
                        name="customer_work_number" 
                        label="Asiakastyönumero"
                        value={ keyExists( this.props, "data.customer_work_number", true, '' ) }
                        onChange={ this.props.onChange }
                        validationState={ this.validateCustomerWorkNumber()}
                    />
                }

                <ApInputStack gap="0">
	                <ApInput 
	                    type="select" 
	                    id="vehicle_id" 
	                    name="vehicle_id" 
	                    value={ keyExists( this.props, "data.detail.vehicle_id", true, '' ) }
	                    options={ this.getVehicleOptions() }
	                    onChange={ ( e ) => this.props.onChange( e, "detail.vehicle_id" ) }
	                />
	                <ApAddon noLeftBorder width="150">
                		{ formatMoney( this.getVehicleCompensation() ) } €/km
                	</ApAddon>
                </ApInputStack>

	            <ApInput 
                    type="text" 
                    id="route" 
                    name="route" 
                    label={ tr('route') }
                    value={ keyExists( this.props, "data.detail.route", true, "" ) }
                    onChange={ ( e ) => this.props.onChange( e, "detail.route" ) }
                    validationState={ keyExists( this.props, "data.detail.route", true, '' ) ? "" : "error" }
                />

                <ApInputStack gap="0">
                	<ApAddon noRightBorder width="40%">
                		{ tr('trip_length') }
                	</ApAddon>
	                <ApInput 
	                    type="number" 
	                    id="distance" 
	                    name="distance" 
	                    value={ keyExists( this.props, "data.detail.distance", true, "" ) }
                        onChange={(e) => this.props.onChange(e, "detail.distance")}
                        validationState={this.validateTravelDistanceAndTime()}
	                />
	                <ApAddon noLeftBorder width="50">
                		km
                	</ApAddon>
                </ApInputStack>

                <ApInputStack gap="0">
                	<ApAddon noRightBorder width="40%">
                		{ tr('travel_time') }
                	</ApAddon>
	                <ApInput 
	                    type="number" 
	                    id="hours" 
	                    name="hours" 
	                    value={ keyExists( this.props, "data.detail.hours", true, "" ) }
                        onChange={(e) => this.props.onChange(e, "detail.hours")}
                        validationState={this.validateTravelDistanceAndTime()}
	                />
	                <ApAddon noLeftBorder width="50">
                		h
                	</ApAddon>
                </ApInputStack>

                <div className="apInfo small">
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('travel_time_note') }
                </div>
                <div className="apInfo small">
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('trip_length_note') }
                </div>

                {/*  this.props.addFieldsRenderer( "travel", this.props.data ) */}


            </div>
        );
    }

}
