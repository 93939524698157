/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
// import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import api from 'services/Api/Api.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
// import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApList from 'common/ApList/ApList.js';
import ApModal from 'common/ApModal/ApModal.js';

import { keyExists, formatMoney, pluralizeCount, tr, getWeekdays } from  'services/Helpers/Helpers.js';
import HourTypeExtraRuleModal from './Modals/HourTypeExtraRuleModal';
import ApTooltip from 'common/ApTooltip/ApTooltip';

// import { Collapse } from 'react-bootstrap';


export default class Listings extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            modalShow: false,
            modalData: null,
            nextId: 0,
            loading: false,
            showExtraRuleModal: false,
            extraRuleModalData: null,
        };
        autoBind(this);
    }

    renderItem( item ) {
        return (
            <div>
                <strong>{ item.name }</strong><br />
                <small>{ item.description || tr('no_description') }</small>
            </div>
        );
    }

    getListActions( type ) {
        return [
            {
                label: tr('copy'),
                icon: "copy",
                action: ( item, closeFunc ) => { 
                    this.copyItem( type, item );
                    closeFunc(); 
                }
            },
            { divider: true },
            {
                label: ( item ) => item.removed ? tr('undo_deletion') : tr('delete'),
                icon: ( item ) => item.removed ? "times" : "trash",
                disabled: ( item ) => item.is_static,
                action: ( item, closeFunc ) => { 
                    this.removeItem( type, item.id );
                    closeFunc(); 
                }
            },
        ];
    }

    editItem( type, data = false, copyMode = false, position = false )
    {
        if( !data )
        {
            data = {
                id: "new" + this.state.nextId,
                unsaved: true,
                name: null,
                description: null,
                salary_category: null,
                useHourPrice: false,
            };

            if( type === "hour_types" )
            {
                data.is_default = false;
                data.is_overtime = false;
                data.is_notation = false;
                data.salary_multiplier = 1;
                data.useHourPrice = data.hour_price ? true : false;
            }
            else if( type === "vehicles" )
            {
                data.compensation = 0;
                data.salary_category_hours = false;
                data.compensation_hours = 0;
                data.vehicle_type = null;
                data.passengers = 1;
            }
            else if( type === "allowances" )
            {
                data.compensation = 0;
                data.amount = 1;
                data.allowance_type = null;
            }
            else if ( type === "absences" )
            {
                data.salary_multiplier = 1;
            }
        }

        if( copyMode )
        {   
            data.position = this.props[ type ].findIndex( i => i.id == data.id ) + 1;
            data.id = "copy" + this.state.nextId;
            data.unsaved = true;
            data.created_at = null;
            data.updated_at = null;
            data.deleted_at = null;
        }
        else if ( position !== false )
            data.position = position;


        if (type == "hour_types") {
            if (!("useHourPrice" in data)) {
                data.useHourPrice = data.hour_price ? true : false;
            }
        }
        this.setState({
            modalData: {
                type: type,
                data: data,
            },
            modalShow: true,
            nextId: this.state.nextId + 1,
        });
    }

    copyItem( type, data )
    {
        this.editItem( type, JSON.parse(JSON.stringify( data )), true );
    }

    removeItem( type, id )
    {
        let items = this.props[ type ].slice();
        const index = items.findIndex( i => i.id == id );
        if( index != -1 )
            items[ index ].removed = !items[ index ].removed;

        this.props.handleChange( type, items );
    }

    closeModal()
    {
        this.setState({ modalShow: false });
    }

    applyModal()
    {   

        let data = this.state.modalData.data;
        let items = this.props[ this.state.modalData.type ].slice();
        
        const index = items.findIndex(i => i.id == data.id);
        
        if (this.state.modalData.type === "hour_types") {
            if (!data.useHourPrice) {
                data.hour_price = null;
            } 
        }
        
        const position = data.position;
        if( position )
            delete data.position;

        // Edit old row
        if( index != -1 )
            items[ index ] = data;

        // Add new row to given position (copy)
        else if( !isNaN( parseFloat( position ) ) )
            items.splice( position, 0, data );

        // Add new row at the end
        else 
            items.push( data );
        

       
        this.props.handleChange( this.state.modalData.type, items, this.closeModal );
        
        //check hourtypes
        let hourTypes = this.props.hour_types;
        if (this.state.modalData.type === "hour_types") {
            hourTypes = items;
        }
        if (hourTypes) {
            if  ( hourTypes.filter(d => d.is_default &&  d.is_overtime ==false  && d.is_notation ==false   ).length ==0) {
                this.props.handleEmptyListings(0);             
            }   
            else {
                this.props.handleEmptyListings(1);
            }        
        }
      
    }

    validateModal()
    {
        if( !keyExists( this.state, "modalData.data.name", true ) )
            return false;

        const type = keyExists( this.state, "modalData.type", true );

        if( type === "hour_types" || type === "absences" )
        {
            if( keyExists( this.state, "modalData.data.salary_multiplier", true, 0 ) < 0 )
                return false;
         
        } 

        if( type === "vehicles" || type === "allowances" )
        {
            if(  keyExists( this.state, "modalData.data.compensation", true, 0 ) < 0 )
                return false;
        } 

        if( type === "allowances" )
        {
            if(  keyExists( this.state, "modalData.data.amount", true, 0 ) < 0 )
                return false;
        }  
        
        
       

        return true;
    }

    getModalSelectedHourType()
    {
        const is_overtime = keyExists( this.state.modalData, "data.is_overtime", true );
        const is_notation = keyExists( this.state.modalData, "data.is_notation", true );
        
        if( is_overtime )
            return "overtime";
        
        if ( is_notation )
            return "notation";

        return "normal"; 
    }

    onModalChangeManual( values )
    {
        let modalData = JSON.parse( JSON.stringify( { ...this.state.modalData } ) );
        
        for( const key in values )
            modalData.data[ key ] = values[ key ];

        this.setState({ modalData });
    }

    onModalChange( e )
    {
        const name = e.target.name;
        let value = e.target.value;

        let modalData = JSON.parse( JSON.stringify( { ...this.state.modalData } ) );

        if (name.startsWith("is_") || name === "useHourPrice") {
            modalData.data[ name ] = e.target.checked;
            if (name === 'useHourPrice') {
                if (e.target.checked) {
                    modalData.data.salary_multiplier = null;
                }
                else {
                    modalData.data.hour_price = null;
                }
            }
        }
        else 
            modalData.data[ name ] = value;
        
        this.setState({ modalData });
    }


    getStatutoryAllowances()
    {   
        if( this.state.loading )
            return false;

        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'timetracking/settings/statutoryallowances',
        }).then(( response ) => {

            //console.log('statutoryAllowances', response );

            let allowances = this.props.allowances.slice();
            let nextId = this.state.nextId;

            let counterUpdate = 0;
            let counterNew = 0;

            response.forEach( item => {
                const index = allowances.findIndex( a => a.statutory_allowance_id == item.id );
                if( index != -1 )
                {
                    if( allowances[ index ].compensation != item.compensation )
                    {
                        allowances[ index ].compensation = item.compensation;
                        counterUpdate++;
                    }   
                }
                else 
                {
                    allowances.push({
                        id:                     "new" + nextId,
                        statutory_allowance_id: item.id,
                        unsaved:                true,
                        group:                  item.group,
                        name:                   item.name,
                        description:            item.description,
                        salary_category:        null,
                        compensation:           item.compensation,
                        amount:                 item.amount,
                    });
                    nextId++;
                    counterNew++;
                }
            });

            window.emitter.emit('popper', {
                type: 'success',
                content: <div>{ tr('import_successful') }: <br />{ tr('new_ones') }: <strong>{ counterNew } { tr('pcs') }</strong>, { tr('updated_ones') }: <strong>{ counterUpdate } { tr('pcs') }</strong></div>,
            });

            this.props.handleChange( 'allowances', allowances );

            this.setState({
                loading: false,
                nextId: nextId,
            });
            
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    removeStatutoryAllowances()
    {
        let allowances = this.props.allowances.slice();
        allowances = allowances.map( a => {
            if( a.statutory_allowance_id )
                a.removed = true;
            return a;
        })
        this.props.handleChange( 'allowances', allowances );
    }

    handleRuleSave(rule) {
      
        const { modalData } = this.state;
        const { data } = modalData;
        if (!data.rules) {
            data.rules = [];
        }
        const { rules } = data;

        const index = rules.findIndex(r => r.id === rule.id);
        if (index !== -1) {
            rules[index] = rule;
        }
        else {
            rules.push(rule);
        }

        this.setState({modalData, showExtraRuleModal: false, extraRuleModalData: null});
    }

    removeExtraRule(rule) {
        const { modalData } = this.state;
        const { data } = modalData;
        const { rules } = data;
        const index = rules.findIndex(r => r.id === rule.id);
        if (index !== -1) {
            rules.splice(index, 1);
        }
        this.setState({modalData});
    }

    render()
    {
        const statutoryAllowances = this.props.allowances ? this.props.allowances.filter( a => a.statutory_allowance_id ).length : 0;

       
   
    	return (
    		<div className="padding">

                <h3>{ tr('hour_ratings') }</h3>
                <p>{ tr('hour_ratings_info') }</p>

                <ApList
                    loading={ this.props.loading }
                    items={ this.props.hour_types || [] }
                    itemRenderer={ this.renderItem }
                    columns={[
                        { style: { width: "100px" }, value: ( item ) => <small>{ item.is_default ? tr('default_value') : "" }</small> },
                        { style: { width: "100px" }, value: ( item ) => <small>{ pluralizeCount( item.entries_count || 0, tr('pluralize_entry'), tr('pluralize_entries') ) }</small> },
                    ]}
                    onClick={ ( item ) => this.editItem( "hour_types", item ) }
                    actions={ this.getListActions( "hour_types" ) }
                    validationState={ ( item ) => 
                        item.removed ? "removed" : 
                        item.unsaved ? "warning" : 
                        "" 
                    }
                    icon={ ( item ) => item.is_overtime ? <SvgIcon icon="clock" /> : item.is_notation ? <SvgIcon icon="trophy" type="solid" /> : <SvgIcon icon="clock" type="solid" /> }
                    sortable
                    onSort={ ( items ) => this.props.handleChange( "hour_types", items ) }
                    addNewItem={ ( pos ) => this.editItem( "hour_types", false, false, pos ) }
                />

                <br />
                
                <h3>{ tr('absences') }</h3>
                <p>{ tr('absences_info') }</p>

                <ApList
                    loading={ this.props.loading }
                    items={ this.props.absences || [] }
                    itemRenderer={ this.renderItem }
                    columns={[
                        { style: { width: "100px" }, value: ( item ) => <small>{ item.entries_count || 0 } { tr('pluralize_entries') }</small> },
                    ]}
                    onClick={ ( item ) => this.editItem( "absences", item ) }
                    actions={ this.getListActions( "absences" ) }
                    validationState={ ( item ) => 
                        item.is_static ? "disabled" :
                        item.removed ? "removed" : 
                        item.unsaved ? "warning" : 
                        "" 
                    }
                    icon="ban"
                    sortable
                    onSort={ ( items ) => this.props.handleChange( "absences", items ) }
                    addNewItem={ ( pos ) => this.editItem( "absences", false, false, pos ) }
                />

                <br />

                <h3>{ tr('kilometer_allowances') }</h3>
                <p>{ tr('kilometer_allowances_info') }</p>

                <ApList
                    loading={ this.props.loading }
                    items={ this.props.vehicles || [] }
                    itemRenderer={ this.renderItem }
                    columns={[
                        { style: { width: "150px" }, value: ( item ) => <small>{ formatMoney( item.compensation || 0 ) } €/km + { formatMoney( item.compensation_hours || 0 ) } €/h</small> },
                        { style: { width: "100px" }, value: ( item ) => <small>{ item.entries_count || 0 } { tr('pluralize_entries') }</small> },
                    ]}
                    onClick={ ( item ) => this.editItem( "vehicles", item ) }
                    actions={ this.getListActions( "vehicles" ) }
                    validationState={ ( item ) => 
                        item.removed ? "removed" : 
                        item.unsaved ? "warning" : 
                        "" 
                    }
                    icon="car"
                    sortable
                    onSort={ ( items ) => this.props.handleChange( "vehicles", items ) }
                    addNewItem={ ( pos ) => this.editItem( "vehicles", false, false, pos ) }
                />
                
                <br />

                <h3>{ tr('daily_allowances') }</h3>
                <p>{ tr('daily_allowances_info', [<a href="https://www.vero.fi/henkiloasiakkaat/auto/kilometrikorvaus_ja_paivaraha/" rel="noopener noreferrer" target="_blank">{ tr('daily_allowances_info_link') }</a>]) }  </p>
                
                <div className="apButtonGroup">
                    <ApButton color="white" size="small" onClick={ () => this.getStatutoryAllowances() } disabled={ this.props.loading || this.state.loading } loading={ this.props.loading || this.state.loading }>
                        <SvgIcon icon="book" type="solid" />
                        { tr('import_statutory_allowances') }
                    </ApButton>
                    <ApButton color="white" size="small" onClick={ () => this.removeStatutoryAllowances() } disabled={ this.props.loading || this.state.loading || statutoryAllowances == 0 } loading={ this.props.loading }>
                        <SvgIcon icon="trash" type="solid" />
                        { tr('delete_statutory_allowances') } { statutoryAllowances > 0 && <span>({ statutoryAllowances } { tr('pcs') })</span> }
                    </ApButton>
                </div>

               <ApList
                    loading={ this.props.loading }
                    items={ this.props.allowances || [] }
                    itemRenderer={ this.renderItem }
                    columns={[
                        { style: { width: "100px" }, value: ( item ) => <small>{ formatMoney( item.compensation || 0 ) } €</small> },
                        { style: { width: "100px" }, value: ( item ) => <small>{ item.entries_count || 0 } { tr('pluralize_entries') }</small> },
                    ]}
                    onClick={ ( item ) => this.editItem( "allowances", item ) }
                    actions={ this.getListActions( "allowances" ) }
                    validationState={ ( item ) => 
                        item.removed ? "removed" : 
                        item.unsaved ? "warning" : 
                        "" 
                    }
                    icon="suitcase"
                    sortable
                    onSort={ ( items ) => this.props.handleChange( "allowances", items ) }
                    addNewItem={ ( pos ) => this.editItem( "allowances", false, false, pos ) }
                />


                <ApModal
                    className="narrow"
                    show={ this.state.modalShow }
                    handleClose={ this.closeModal }
                    // closeFromBg
                    header={ 
                        <div className="padding">
                            <h3>{ tr('edit') }</h3>
                        </div>
                    }
                    body={ 
                        <div className="padding">
                            
                            { keyExists( this.state.modalData, "type", true ) == "hour_types" &&
                                <div>
                                    <div className="apOptionBar count-3">
                                        <div className={ "option" + ( this.getModalSelectedHourType() == "normal" ? " selected" : "" ) } onClick={ () => this.onModalChangeManual( { is_overtime: false, is_notation: false } ) }>
                                            <SvgIcon icon="clock" type="solid" />
                                            { tr('normal') }
                                        </div>
                                        <div className={ "option" + ( this.getModalSelectedHourType() == "overtime" ? " selected" : "" ) } onClick={ () => this.onModalChangeManual( { is_overtime: true, is_notation: false } ) }>
                                            <SvgIcon icon="clock" type="regular" />
                                            { tr('overtime') }
                                        </div>
                                        <div className={ "option" + ( this.getModalSelectedHourType() == "notation" ? " selected" : "" ) } onClick={ () => this.onModalChangeManual( { is_overtime: false, is_notation: true } ) }>
                                            <SvgIcon icon="trophy" type="solid" />
                                            { tr('fee_or_supplement') }
                                        </div>
                                    </div>
                             
                                    <div className="apInfo small">
                                        <SvgIcon icon="info-circle" type="solid" />
                                        { this.getModalSelectedHourType() == "normal" &&
                                            <span>{ tr('hour_type_normal_info') }</span>
                                        }
                                        { this.getModalSelectedHourType() == "overtime" &&
                                            <span>{ tr('hour_type_overtime_info') }</span>
                                        }
                                        { this.getModalSelectedHourType() == "notation" &&
                                            <span>{ tr('hour_type_notation_info') }</span>
                                        }
                                    </div>
                        
                                </div>
                            }

                            {/* 
                            <ApInput 
                                type="text" 
                                id="group" 
                                name="group" 
                                label="Ryhmä"
                                value={ keyExists( this.state.modalData, "data.group", true, '' ) }
                                onChange={ this.onModalChange }
                            />
                            */}

                            <ApInput 
                                type="text" 
                                id="name" 
                                name="name" 
                                label={ tr('name') }
                                value={ keyExists( this.state.modalData, "data.name", true, '' ) }
                                onChange={ this.onModalChange }
                                validationState={ keyExists( this.state.modalData, "data.name", true, '' ) ? "" : "error" }
                            />

                            <ApInput 
                                type="textarea" 
                                id="description" 
                                name="description" 
                                label={ tr('description') }
                                value={ keyExists( this.state.modalData, "data.description", true, '' ) }
                                onChange={ this.onModalChange }
                                charCounter
                                rows={3}
                            />
                            {["hour_types"].indexOf(keyExists(this.state.modalData, "type", true, "")) != -1 &&
                                <div className={"apFormGroup" + (keyExists(this.state.modalData, "data.useHourPrice", true) ? " success" : "")}>
                                    <div className="apSwitchBlock">
                                        <label htmlFor="modal-use-hour-price-switch" className="info">
                                            {tr('use_hour_price')}
                                            <small>{tr('expense_tracking_salary_use_hour_price_info')}</small>
                                        </label>
                                        <ApSwitch
                                            id="modal-use-hour-price-switch"
                                            name="useHourPrice"
                                            on={keyExists(this.state.modalData, "data.useHourPrice", true, false)}
                                            onChange={this.onModalChange}
                                        />
                                    </div>
                                </div>
                            }
                            {["hour_types"].indexOf(keyExists(this.state.modalData, "type", true, "")) != -1
                                && keyExists(this.state.modalData, "data.useHourPrice", true) &&
                                <div>
                                    <ApInputStack gap="0">
                                        <ApAddon noRightBorder>
                                            {tr('hour_price')}
                                        </ApAddon>
                                        <ApInput
                                            width="200"
                                            type="number"
                                            id="hour_price"
                                            name="hour_price"
                                            align="right"
                                            value={keyExists(this.state.modalData, "data.hour_price", true, '1')}
                                            onChange={this.onModalChange}
                                            // tooltip={<span>{tr('expense_tracking_salary_multiplier_tooltip1')}<br />{tr('expense_tracking_salary_multiplier_tooltip2')}<br />{tr('expense_tracking_salary_multiplier_tooltip3')}</span>}
                                            validationState={keyExists(this.state.modalData, "data.hour_price", true, 0) >= 0 ? "" : "error"}
                                        />
                                    </ApInputStack>
                                    {/* <div className="apInfo small">
                                        <SvgIcon icon="info-circle" type="solid" />
                                        {tr('expense_tracking_salary_multiplier_info')}
                                    </div> */}
                                </div>
                            }

                            {["hour_types", "absences"].indexOf(keyExists(this.state.modalData, "type", true, "")) != -1
                                && !keyExists(this.state.modalData, "data.useHourPrice", true) &&
                                <div>
                                    <ApInputStack gap="0">
                                        <ApAddon noRightBorder>
                                            { tr('expense_tracking_salary_multiplier') }
                                        </ApAddon>
                                        <ApInput 
                                            width="200"
                                            type="number" 
                                            id="salary_multiplier" 
                                            name="salary_multiplier" 
                                            align="right"
                                            value={ keyExists( this.state.modalData, "data.salary_multiplier", true, '1' ) }
                                            onChange={ this.onModalChange }
                                            tooltip={ <span>{ tr('expense_tracking_salary_multiplier_tooltip1') }<br />{ tr('expense_tracking_salary_multiplier_tooltip2') }<br />{ tr('expense_tracking_salary_multiplier_tooltip3') }</span> }
                                            validationState={ keyExists( this.state.modalData, "data.salary_multiplier", true, 0 ) >= 0 ? "" : "error" }
                                        />
                                    </ApInputStack>
                                    {/* <div className="apInfo small">
                                        <SvgIcon icon="info-circle" type="solid" />
                                        { tr('expense_tracking_salary_multiplier_info') } 
                                    </div> */}
                                </div>
                            }

                            { [ "hour_types", "vehicles", "absences" ].indexOf( keyExists( this.state.modalData, "type", true, "" ) ) != -1 &&
                                <div className={ "apFormGroup" + ( keyExists( this.state.modalData, "data.is_entry_description_required", true ) ? " success" : "" ) }> 
                                    <div className="apSwitchBlock">
                                        <label htmlFor="modal-is-desc-required-switch" className="info">
                                            { tr('description_required') }
                                            <small>{ tr('description_required_info') }</small>
                                        </label>
                                        <ApSwitch
                                            id="modal-is-desc-required-switch"
                                            name="is_entry_description_required"
                                            on={ keyExists( this.state.modalData, "data.is_entry_description_required", true, false ) }
                                            onChange={ this.onModalChange }
                                        />
                                    </div>
                                </div>
                            }

                            { keyExists( this.state.modalData, "type", true ) == "hour_types" &&
                                <div className={ "apFormGroup" + ( keyExists( this.state.modalData, "data.is_default", true ) ? " success" : "" ) }> 
                                    <div className="apSwitchBlock">
                                        <label htmlFor="modal-is-default-switch" className="info">
                                            { tr('default_value') }
                                            <small>{ tr('hour_type_default_value_info') }</small>
                                        </label>
                                        <ApSwitch
                                            id="modal-is-default-switch"
                                            name="is_default"
                                            on={ keyExists( this.state.modalData, "data.is_default", true, false ) }
                                            onChange={ this.onModalChange }
                                        />
                                    </div>
                                </div>
                            }

                            { (keyExists( this.state.modalData, "type", true ) == "hour_types" 
                            && ['notation', 'overtime'].includes(this.getModalSelectedHourType())) &&
                            <div>
                                <h4 className='formHeader'>{tr('special_rules')}</h4>
                                {keyExists(this.state.modalData, "data.rules", true, []).map((rule, index) => {
                                    return <ApInputStack gap="0">
                                        <ApAddon onClick={() => this.setState({showExtraRuleModal: true, extraRuleModalData: rule})}>
                                            {rule.days.map(day => {
                                                const foundDay = getWeekdays(true).find(d => d.value === day.day);
                                                return foundDay ? foundDay.label : '';
                                            }).join(', ')} {' '}
                                            {rule.start_time} {(rule.start_time && rule.end_time) && " - "} {rule.end_time} {rule.salary_category ? ` (${rule.salary_category})` : ''}
                                        </ApAddon>
                                        <ApAddon className="remove-btn" width="50" onClick={() => this.removeExtraRule(rule)}>
                                            <SvgIcon type="solid" icon="trash" />
                                        </ApAddon>
                                    </ApInputStack>
                                })}
                                <ApButton color="green" onClick={() => this.setState({showExtraRuleModal: true, extraRuleModalData: null})}>{tr('add_new_rule')}</ApButton>
                            </div>}

                            { keyExists( this.state.modalData, "type", true ) == "vehicles" &&
                                <div>
                                <ApInputStack gap="0">
                                    <ApAddon noRightBorder>
                                        { tr('kilometer_allowance') }
                                    </ApAddon>
                                    <ApInput 
                                        width="200"
                                        type="number" 
                                        id="compensation" 
                                        name="compensation" 
                                        align="right"
                                        value={ keyExists( this.state.modalData, "data.compensation", true, '' ) }
                                        onChange={ this.onModalChange }
                                        validationState={ keyExists( this.state.modalData, "data.compensation", true, 0 ) >= 0 ? "" : "error" }
                                    />
                                    <ApAddon noLeftBorder width="70">
                                        €/km
                                    </ApAddon>
                                </ApInputStack>
                                
                                <ApInputStack gap="0">
                                    <ApAddon noRightBorder>
                                        { tr('travel_allowance') }
                                    </ApAddon>
                                    <ApInput 
                                        width="200"
                                        type="number" 
                                        id="compensation_hours" 
                                        name="compensation_hours" 
                                        align="right"
                                        value={ keyExists( this.state.modalData, "data.compensation_hours", true, '' ) }
                                        onChange={ this.onModalChange }
                                        validationState={ keyExists( this.state.modalData, "data.compensation_hours", true, 0 ) >= 0 ? "" : "error" }
                                    />
                                    <ApAddon noLeftBorder width="70">
                                        €/h
                                    </ApAddon>
                                </ApInputStack>
                                <ApInputStack gap="0">
                                    <ApAddon noRightBorder>
                                        { tr('vehicle_type') }
                                    </ApAddon>
                                    <ApInput 
                                        type="select" 
                                        id="vehicle_type"
                                        name="vehicle_type" 
                                        width="200"
                                        align="right"
                                        value={ keyExists(this.state.modalData, "data.vehicle_type", true, '') }
                                        options={[
                                            { value: null, label: '' },
                                            { value: 'car', label: tr('car') },
                                            { value: 'car_with_trailer', label: tr('car_with_trailer') },
                                            { value: 'car_with_caravan', label: tr('car_with_caravan') },
                                            { value: 'car_with_heavy_cargo', label: tr('car_with_heavy_cargo') },
                                            { value: 'car_with_big_machinery', label: tr('car_with_big_machinery') },
                                            { value: 'car_with_dog', label: tr('car_with_dog') },
                                            { value: 'car_travel_in_rough_terrain', label: tr('car_travel_in_rough_terrain') },
                                            { value: 'motorboat_max_50hp', label: tr('motorboat_max_50hp') },
                                            { value: 'motorboat_over_50hp', label: tr('motorboat_over_50hp') },
                                            { value: 'snowmobile', label: tr('snowmobile') },
                                            { value: 'atv', label: tr('atv') },
                                            { value: 'motorbike', label: tr('motorbike') },
                                            { value: 'moped', label: tr('moped') },
                                            { value: 'other', label: tr('other') },
                                            { value: 'carbenefit', label: tr('carbenefit') }
                                        ]}
                                        onChange={ this.onModalChange }
                                    />
                                </ApInputStack>
                                <ApInputStack gap="0">
                                    <ApAddon noRightBorder>
                                        { tr('passengers') }
                                    </ApAddon>
                                    <ApInput 
                                        width="200"
                                        type="number" 
                                        id="passengers"
                                        name="passengers" 
                                        align="right"
                                        value={ keyExists( this.state.modalData, "data.passengers", true, '' ) }
                                        onChange={ this.onModalChange }
                                        validationState={ keyExists( this.state.modalData, "data.passengers", true, 0 ) >= 0 ? "" : "error" }
                                    />
                                </ApInputStack>
                                </div>
                            }

                            { keyExists( this.state.modalData, "type", true ) == "allowances" &&
                                <div>
                                <ApInputStack gap="0">
                                    <ApAddon noRightBorder>
                                        { tr('compensation') }
                                    </ApAddon>
                                    <ApInput 
                                        width="100"
                                        type="number" 
                                        id="compensation" 
                                        name="compensation" 
                                        align="right"
                                        value={ keyExists( this.state.modalData, "data.compensation", true, '' ) }
                                        onChange={ this.onModalChange }
                                        validationState={ keyExists( this.state.modalData, "data.compensation", true, 0 ) >= 0 ? "" : "error" }
                                    />
                                    <ApAddon noLeftBorder width="70">
                                        { `€/${tr('day_abbr')}` }
                                    </ApAddon>

                                    <ApAddon noRightBorder gapLeft="10">
                                        { tr('amount') }
                                    </ApAddon>
                                    <ApInput 
                                        width="70"
                                        type="number" 
                                        id="amount" 
                                        name="amount" 
                                        align="right"
                                        value={ keyExists( this.state.modalData, "data.amount", true, '' ) }
                                        onChange={ this.onModalChange }
                                        validationState={ keyExists( this.state.modalData, "data.amount", true, 0 ) >= 0 ? "" : "error" }
                                    />
                                    <ApAddon noLeftBorder width="70">
                                        { tr('pcs') }
                                    </ApAddon>
                                </ApInputStack>
                                    <ApInputStack gap="0">
                                        <ApAddon noRightBorder>
                                            { tr('daily_allowance_type') }
                                        </ApAddon>
                                        <ApInput 
                                            type="select" 
                                            id="allowance_type"
                                            name="allowance_type" 
                                            width="200"
                                            align="right"
                                            value={ keyExists(this.state.modalData, "data.allowance_type", true, '') }
                                            options={[
                                                { value: null, label: '' },
                                                { value: 'domesticfull', label: tr('full_daily_allowance') },
                                                { value: 'domestichalf', label: tr('half_daily_allowance') },
                                                { value: 'foreign', label: tr('daily_allowance_abroad') },
                                            ]}
                                            onChange={ this.onModalChange }
                                        />
                                    </ApInputStack>
                                </div>
                            }

                        </div>
                    }
                    footer={
                        <div className="padding">
                            
                            <ApButton onClick={ this.closeModal }>
                                <SvgIcon icon="times" type="solid" />
                                { tr('cancel') }
                            </ApButton>

                            <ApButton className="applyButton" color="green" onClick={ this.applyModal } disabled={ !this.validateModal() }>
                                <SvgIcon icon="check" type="solid" />
                                { tr('continue') }
                            </ApButton>

                        </div>
                    }
                />

                <HourTypeExtraRuleModal 
                    show={ this.state.showExtraRuleModal }
                    handleClose={ () => this.setState({showExtraRuleModal: false, extraRuleModalData: null}) }
                    onSave={ (rule) => this.handleRuleSave(rule) }
                    data={ this.state.extraRuleModalData }
                    type={ keyExists( this.state.modalData, "data.is_overtime", true ) ? "overtime" : "notation" }
                />

    		</div>
    	);
    }

}
