/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import _ from 'lodash'

import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';

import { keyExists, setNested, formatMoney, groupify, pluralizeCount, sqlToDateInput, tr, currentLang, roundToDecimals } from 'services/Helpers/Helpers.js';

import './Entry.css';

import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
// import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApButton from 'common/ApButton/ApButton.js';
// import ApModalInput from 'common/ApModalInput/ApModalInput.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';
import ApModal from 'common/ApModal/ApModal.js';
//import ApReactTable from 'common/ApReactTable/ApReactTable.js';
// import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
//import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import ApPath from 'common/ApPath/ApPath.js';

import WeekBrowser from './WeekBrowser/WeekBrowser.js';
import WeekDay from './WeekDay/WeekDay.js';

import HourEntry from './Modal/HourEntry.js';
import TravelEntry from './Modal/TravelEntry.js';
import ExpenseEntry from './Modal/ExpenseEntry.js';
import AbsenceEntry from './Modal/AbsenceEntry.js';
import AllowanceEntry from './Modal/AllowanceEntry.js';

import { Collapse } from 'react-bootstrap';
import { treemapResquarify } from 'd3';
import { connect } from 'react-redux';

class TimetrackingEntry extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
        	loading: true,
            settings: null,
            weekData: [],
            lastWeekData:[],
            weekDataOriginal: [],
            removedEntries: [],

            errors: [],

            newEntryNextId: 1,

            confirm: {
                show: false,
                header: false,
                body: false,
                onConfirm: undefined,
            },

            entryEditShow: false,
            entryEditData: false,

            sendModalShow: false,
            sendDates: [],

            addFlexiUsage: false,

            unsavedChanges: false,

            selectedYear: null,
            selectedWeek: null,
        };
        autoBind(this);
        this.hourEntryRef = React.createRef();
    }

    componentDidMount()
    {
        const params = this.props.match.params;
        let year = params.year;
        let week = params.week;

        if(!year || !week)
        {
            // Get last day of the week
            const lastOfWeek = moment().endOf('isoWeek');
            year = moment(lastOfWeek).year();
            week = moment(lastOfWeek).week();
        }
        
        // Get global settings from backend
        this.getSettings( () => {
            //console.log( 'Settings: ', this.state.settings );
            this.changeWeek( year, week );
        });
 
    }

    getSettings( afterFunc )
    {
        api({
            method: 'get',
            url: 'timetracking/settings/entry',
        }).then(( settings  ) => {
            
            this.setState({ settings }, afterFunc );

        }).catch((error) => {
            console.error(error);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{ tr('get_error') }</strong>,
            });
        });
    }

    hourTypeIsNotation( id )
    {
        const notationIds = keyExists( this.state, "settings.hour_types", true, [] ).filter( t => t.is_notation ).map( t => t.id );
        return notationIds.indexOf( parseInt( id ) ) != -1;
    }

    getEntries( days, updateMode )
    {
        // Convert single date string ("YYYY-MM-DD") to array
        if( typeof( days ) == "string" ) 
            days = [ days ];

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'timetracking/get/days',
            data: {
                dates: days,
            }
        }).then(( response ) => {
            
            //console.log( 'getEntries', response );
            const entries = response && response.entries ? response.entries : [];
            let entryIds = [];

            let weekData = entries.map(date => {
                const dayEntryIds = date.entries.map(e => e.id);
                entryIds.push(...dayEntryIds);
                return this.parseAllowancesDates(date)
            })
            // Update mode loops trough old week data and only updates new rows and delete removed rows
            if( updateMode ) {
                weekData = [...this.state.lastWeekData,...this.state.weekData];
                for( let i = 0; i < weekData.length; i++ )
                {
                    for( let j = 0; j < response['entries'].length; j++ )
                    {
                        if( weekData[i].date == response['entries'][j].date ) {
                            weekData[i] = this.parseAllowancesDates(response['entries'][j]);
                        }
                    }
                    // Filter out entries that are not in response. If user deleted a saved entry, it should be removed from UI also
                    weekData[i].entries = weekData[i].entries.filter(e => entryIds.indexOf( e.id ) != -1 );
                }
            }

            this.setState({
                loading: false,
                weekData: weekData.slice(7, 14),
                lastWeekData: weekData.slice(0, 7),
                weekDataOriginal: JSON.parse(JSON.stringify(weekData)),
                unsavedChanges: false,
                project_storage_check_balance_strict: response.project_storage_check_balance_strict,
                projectStorageEnabled: response.project_storage_enabled
            });

            // check if current day is locked and add that info to redux
            const currentDay = weekData.find( d => d.date === moment().format('YYYY-MM-DD') );
            if (currentDay) {
                this.props.dayIsLocked(!!currentDay.locked);
            }
            
        }).catch( ( error ) => {
            console.error(error);

            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{updateMode ? tr('save_error_reload_page') :tr('get_error')}</strong>,
                time:updateMode?30000:3000
            });
            //this.setState({ loading: false});
        });
    }

    // Allowances are split to projects accordingly in save, this parses them back together
    parseAllowancesDates(date)
    {
        let allowanceCount = 0
        const allowanceEntries = []
        const otherEntries = []

        date.entries.forEach((entry) => {
            if (entry.type === 'allowance') {
                allowanceEntries.push(entry)
                allowanceCount++
            } else {
                otherEntries.push(entry)
            }
        })

        if (allowanceCount === 0) return date

        if (allowanceCount ===  0 || allowanceCount === 1) {
            const singleAllowanceDate = {...date}
            
            for (let i = 0; i < singleAllowanceDate.entries.length; i++) {
                if (singleAllowanceDate.entries[i].type === 'allowance') {
                    singleAllowanceDate.entries[i].temp_projectId = singleAllowanceDate.entries[i].project_id
                    singleAllowanceDate.entries[i].project_id = null
                    return singleAllowanceDate
                }
            }
        }
        const foundIndex = date.entries.findIndex(entry => entry.type == 'allowance');
        const placeholderAllowance = {...date.entries[foundIndex]}
        placeholderAllowance.project_id = null
        placeholderAllowance.originalAllowances = allowanceEntries
        //Note: placeholderAllowance is only a placeholder to render data. Allowance details are recalculated on save.
        
        const newDate = {...date}

        newDate.entries = otherEntries
        newDate.entries.push(placeholderAllowance)

        return newDate
    }

    getDayEntries( date )
    {
        const weekData = this.state.weekData.slice();
        const day = weekData.find( d => d.date === date );
        return ( day ? day.entries : [] );
    }

    /**
     * Changes selected week (and year) and get entries for
     * each day of the week (monday -> sunday).
     *
     **/
    changeWeek( year, week )
    {
     
        if( this.state.unsavedChanges )
            return false;

        year = parseInt(year, 10);
        week = parseInt(week, 10);

        // Get last day of the week so when year changes we can get correct week
        const lastOfWeek = moment().endOf('isoWeek');
        
        let monday = moment(lastOfWeek).week(week).year(year).week(week).startOf("week");

        let days = [];
        for (let i = -7; i < 7; i++) {
            days.push( monday.clone().add(i, 'd').format('YYYY-MM-DD') );
        }

        // Set browsers URL to match selected week/year
        this.props.history.replace('/timetracking/entry/' + year + '/' + week );
        
        this.setState({
            selectedYear: year,
            selectedWeek: week,
            selectedWeekdays: [],
        });

        this.getEntries( days );
    }

    getWeekSummary()
    {
        const week = this.state.weekData.slice();

        const HOURS_DAILY = parseFloat( keyExists( this.state, "settings.user.hours_daily", true, 0 ) );
        // const HOURS_WEEKLY = parseFloat( keyExists( this.state, "settings.user.hours_weekly", true, 0 ) );
        const DAYS_WEEKLY = parseFloat( keyExists( this.state, "settings.user.days_weekly", true, 0 ) );
        const CONTRACT_START = keyExists( this.state, "settings.user.contract_start", true ); 
        const CONTRACT_END = keyExists( this.state, "settings.user.contract_end", true ); 

        const DEFAULT_CURRENCY = auth.getCurrency();

        const haveFlexitime = keyExists( this.state, "settings.user.flex_time_allowed", true, false );
        const FLEXITIME_IDS = keyExists( this.state, "settings.absences", true, [] ).filter( a => a.flexitime_usage ).map( a => a.id );

        const parseNumber = ( value ) => {
            value = parseFloat( value );
            if( isNaN( value ) ) value = 0;
            return value;
        };

        const initStructure = () => {
            return {
                hours: {
                    count: 0,
                    regular: 0,
                    overtime: 0,
                    notation: 0,
                    flexi: 0, // flexi change +/- hours
                    flexi_usage: 0, // This should be absolute value of negative flexi
                },
                travels: {
                    count: 0,
                    distance: 0,
                    compensation: 0,
                    hours: 0,
                },
                expenses: {
                    count: 0,
                    costs: 0,
                    rows: 0,
                    files: 0,
                },
                absences: {
                    count: 0,
                    hours: 0,
                },
                allowances: {
                    count: 0,
                    compensation: 0,
                }
            }; 
        };

        let summary = {
            week: initStructure(),
            days: [],
            errors: [],
        };

        week.forEach( day => {
            let daySummary = initStructure();

            // If day have "required hours" set use those, otherwise use current value from user contract settings
            const dayHoursDaily = parseFloat( keyExists( day, "required_hours_daily", true, HOURS_DAILY ) );
            const dayDaysWeekly = parseFloat( keyExists( day, "required_days_weekly", true, DAYS_WEEKLY ) );
            const weekdayIndex = parseInt( moment( day.date ).isoWeekday() ); // Monday = 1, Tuesday = 2 ... Sunday = 7

            day.entries.forEach( entry => {

                if( !entry.is_misentry )
                {
                    switch( entry.type )
                    {
                        case "hour":
                            daySummary.hours.count++;
                            keyExists( entry, "detail.rows", true, [] ).forEach( row => {
                                if( this.state.settings.hour_types.find( t => t.id == row.hour_type_id ).is_overtime )
                                    daySummary.hours.overtime += parseNumber( row.hours );
                                
                                else if ( this.state.settings.hour_types.find( t => t.id == row.hour_type_id ).is_notation )
                                    daySummary.hours.notation += parseNumber( row.hours );

                                else 
                                    daySummary.hours.regular += parseNumber( row.hours );
                            });
                            break;

                        case "travel":
                            daySummary.travels.count++;
                            daySummary.travels.distance     += parseNumber( entry.detail.distance );
                            daySummary.travels.hours        += parseNumber( entry.detail.hours );
                            daySummary.travels.compensation += 
                                (parseNumber( entry.detail.compensation ) * parseNumber( entry.detail.distance )) +
                                (parseNumber( entry.detail.compensation_hours) * parseNumber( entry.detail.hours ));
                            break;

                        case "expense":
                            daySummary.expenses.count++;
                            keyExists( entry, "detail.rows", true, [] ).forEach( row => {
                                
                                let rate = 1;
                                if( row.currency != DEFAULT_CURRENCY && parseFloat( row.currency_rate ) > 0 )
                                    rate = parseFloat( row.currency_rate );

                                daySummary.expenses.costs   += parseNumber( row.cost / rate );
                                daySummary.expenses.rows    += 1;
                            });
                            daySummary.expenses.files += entry.detail.files.length;
                            break;

                        case "absence":
                            
                            // Separate flexi usages from other absences 
                            if( FLEXITIME_IDS.indexOf( entry.detail.absence_id ) != -1 ) {
                                daySummary.hours.flexi_usage += parseNumber( entry.detail.hours );
                                if (!day.locked) {
                                    daySummary.absences.hours += parseNumber( entry.detail.hours );
                                }
                            }
                            
                            else 
                            {
                                if( entry.detail.is_all_day )
                                    daySummary.absences.hours += dayHoursDaily;
                                else 
                                    daySummary.absences.hours += parseNumber( entry.detail.hours );
                            }
                            daySummary.absences.count++;
                            break;

                        case "allowance":
                            daySummary.allowances.count++;
                            daySummary.allowances.compensation += parseNumber( entry.detail.compensation );
                            break;
                        default: break;
                    }
                }

                if( entry.status === "rejected" )
                    summary.errors.push({
                        date: day.date,
                        entry: entry.id,
                        type: "error",
                        text: tr('entry_rejected') + (entry.approver_user_person_detail?.full_name ? " / " + entry.approver_user_person_detail?.full_name : ''),
                        name: entry.approver_user_person_detail?.full_name
                    });
                          
            });

            // We need daily target hours to calculate flexi change
            let dailyTarget = dayHoursDaily;

            // No more working days left for the week (weekend) 
            if( weekdayIndex > dayDaysWeekly )
                dailyTarget = 0;

            // If user contract is not started (or ended already) prevent
            // negative flexi time by setting daily target to zero 
            if( moment( day.date ) < moment( CONTRACT_START ) ||
                moment( day.date ) > moment( CONTRACT_END ) )
                dailyTarget = 0;

            let flexiTotal = daySummary.hours.regular + daySummary.travels.hours;
            const companyTravelDontAddFlextime = keyExists(this.props, 'apTimetrackingSettings.company.travel_dont_add_flextime', true, false);
            if (companyTravelDontAddFlextime && keyExists( this.state, "settings.user.travel_dont_add_flextime", true, false )) {
                flexiTotal = daySummary.hours.regular;
            }
            
            if (weekdayIndex !== 6) {
                flexiTotal += daySummary.absences.hours
            }

            if (companyTravelDontAddFlextime && keyExists( this.state, "settings.user.travel_dont_add_flextime", true, false )) {
                daySummary.hours.flexi = flexiTotal - dailyTarget;
                if (daySummary.hours.flexi<0) {
                    if ((daySummary.hours.flexi+daySummary.travels.hours)>0) {
                        daySummary.hours.flexi=0;
                    }
                    else {
                        daySummary.hours.flexi=daySummary.hours.flexi + daySummary.travels.hours;
                    }
                }
            }
            else {
                daySummary.hours.flexi = flexiTotal - dailyTarget;
            }

            // Increase weekly stats
            summary.week.hours.count                += daySummary.hours.count;
            summary.week.hours.regular              += daySummary.hours.regular;
            summary.week.hours.overtime             += daySummary.hours.overtime;
            summary.week.hours.notation             += daySummary.hours.notation;
            summary.week.hours.flexi                += daySummary.hours.flexi;
            summary.week.hours.flexi_usage          += daySummary.hours.flexi_usage;

            summary.week.travels.count              += daySummary.travels.count;
            summary.week.travels.distance           += daySummary.travels.distance;
            summary.week.travels.compensation       += daySummary.travels.compensation;
            summary.week.travels.hours              += daySummary.travels.hours;

            summary.week.expenses.count             += daySummary.expenses.count;
            summary.week.expenses.costs             += daySummary.expenses.costs;
            summary.week.expenses.rows              += daySummary.expenses.rows;
            summary.week.expenses.files             += daySummary.expenses.files;

            summary.week.absences.count             += daySummary.absences.count;
            summary.week.absences.hours             += daySummary.absences.hours;

            summary.week.allowances.count           += daySummary.allowances.count;
            summary.week.allowances.compensation    += daySummary.allowances.compensation;

            // Save daily summary as individual object
            summary.days[ day.date ] = daySummary;

            if( day.emptyProjects && day.emptyProjects.length > 0 )
                summary.errors.push({
                    date: day.date,
                    entry: false,
                    type: "warning",
                    text: tr('empty_project_warning')
                });

            if( !haveFlexitime && flexiTotal < dailyTarget && !day.locked )
                summary.errors.push({
                    date: day.date,
                    entry: false,
                    type: "warning",
                    text: tr('regular_working_hours_warning') + " (" + flexiTotal + "/" + dailyTarget + " h)"
                });
        });

        return summary;
    }

    markEntryAsRemoved( id )
    {
        let removedEntries = this.state.removedEntries.slice();

        if( Array.isArray( id ) )
            removedEntries = removedEntries.concat( id );
        else 
            removedEntries.push( id );

        this.setState({ removedEntries });
    }


    addProject( date, project )
    {
        //console.log( "New project", project );

        let weekData = this.state.weekData.slice();
        const dayIndex = weekData.findIndex( d => d.date === date );

        if( dayIndex == -1 )
            return false;

        if (typeof project.extranet_approvers !== 'undefined' ) {
            if (project.extranet_approvers.length==0) {
                if (project.ancestors.length == 1 ) {
                    let anscestor=project.ancestors[0];
                    project.extranet_approvers=anscestor.extranet_approvers;
                }
            }
            project.extranet_approvers.forEach(approver => {
                approver.value = approver.contact.id;
                approver.label = `${approver.contact.first_name} ${approver.contact.last_name}`;
            });
            
        }
        let projects = weekData[ dayIndex ].emptyProjects || [];
        projects.push( project );
        weekData[ dayIndex ].emptyProjects = projects;

        this.setState({ weekData });
    }

    removeProject(date, projectId, removeEmptyProject = false )
    {
        // console.log("date", date, "projectId", projectId);
        let weekData = this.state.weekData.slice();
        const dayIndex = weekData.findIndex( d => d.date === date );

        if( dayIndex == -1 )
            return false;

        // Remove possible empty project holder
        if( weekData[ dayIndex ].emptyProjects )
        {
            const projectIndex = weekData[ dayIndex ].emptyProjects.findIndex( p => p.id == projectId );
            if( projectIndex > -1 )
                weekData[ dayIndex ].emptyProjects.splice( projectIndex, 1 );
        }
        
        // Pick removed entries and add them to removed array
        const removed = weekData[dayIndex].entries.filter(e => e.project_id == projectId);
        let markAsRemoved = [];
        
        removed.forEach(r => {
            const entryIndex = weekData[ dayIndex ].entries.findIndex( e => e.id == r.id );
            if( r.status == "rejected" )
            {
                weekData[ dayIndex ].entries[ entryIndex ].is_misentry = !weekData[ dayIndex ].entries[ entryIndex ].is_misentry;
            } else {
                weekData[ dayIndex ].entries.splice( entryIndex, 1 );

                // If removed row was last item in project, lets add project as empty holder
                if( r.project_id && r.project )
                {
                    if (weekData[dayIndex].entries.findIndex(e => e.project_id == r.project_id) == -1 && !removeEmptyProject)
                    {
                        if( !weekData[ dayIndex ].emptyProjects )
                            weekData[ dayIndex ].emptyProjects = [];

                        weekData[ dayIndex ].emptyProjects.push( r.project );
                    }
                }
            }
            markAsRemoved.push(r.id);
            
        });
        this.markEntryAsRemoved(markAsRemoved);
        this.setState({ 
            weekData: weekData,
            unsavedChanges: true
        });
    }

    confirm( type, params = undefined )
    {
        let confirm = { ...this.state.confirm };
        switch( type )
        {
            case "removeProject":

                // No entries inside project so no need for confirm, just remove empty holder
                if( params[2] == 0 )
                    this.removeProject(params[0], params[1] );
                else 
                {
                    confirm.show = true;
                    confirm.header = tr('project_delete_confirm');
                    confirm.body = tr('project_delete_note') + " (" + params[2] + " " + tr('pcs') + ")";
                    confirm.onConfirm = () => this.removeProject(params[0], params[1],true );
                }
                break;

            case "clearDate":
                confirm.show = true;
                confirm.header = tr('clear_day_confirm');
                confirm.body = tr('clear_day_sure');
                confirm.onConfirm = () => this.clearDate( params );
                break;
            default: break;
        }

        if( confirm.show ) 
            this.setState({ confirm });
    }

    closeConfirm()
    {
        let confirm = { ...this.state.confirm };
        confirm.show = false;
        this.setState({ confirm });
    }

    getNextNewEntryId()
    {
        const id = this.state.newEntryNextId;
        this.setState({ newEntryNextId: ( id + 1 ) });
        return id;
    }

    editEntry(data, skipUI=false)
    {
        // Cancel if entry is already locked (submitted, approved, confirmed, payrolled or paid )
        if( [ "suggestion", "open", "rejected" ].indexOf( data.status ) == -1 )
            return false;
        
        data = JSON.parse(JSON.stringify( data )); // Removes reference

        if((data.type === 'hour' || data.type === 'travel') && !data.customer_work_number && isNaN(data.id)) {
            this.state.weekData.forEach(day => {
                if (day.date !== data.date) return;
                day.entries.forEach(entry => {
                    if (entry.type !== 'hour' && entry.type !== 'travel') return;
                    if (entry.customer_work_number && (data.project && entry.project && data.project.id === entry.project.id)) {
                        data.customer_work_number = entry.customer_work_number;
                    }
                });
            });
        }

        if( data.type === "hour" )
        {
            data[ 'description_long_checked' ] = keyExists( data, "detail.description_long", true ) ? true : false;
        }
        if (skipUI === true) {
            this.setState({
                entryEditData: data,
                entryEditShow: false,
            }, () => {
                this.applyEntryModal();
                this.copyDate(data.date, "rest-of-the-week-and-saturday");
                
                
            });
            
        } 
        else {
            this.setState({
                entryEditData: data,
                entryEditShow: true,
            });
        }

        
        //console.log( 'edit entry', data );
    }

    removeEntry( data )
    {
        let weekData = this.state.weekData.slice();
        const dayIndex = weekData.findIndex( d => d.date == data.date );

        if( dayIndex == -1 )
            return false;

        const entryIndex = weekData[ dayIndex ].entries.findIndex( e => e.id == data.id );

        if( entryIndex > -1 )
        {
            this.markEntryAsRemoved( weekData[ dayIndex ].entries[ entryIndex ].id );

            if( data.status == "rejected" )
            {
                weekData[ dayIndex ].entries[ entryIndex ].is_misentry = !weekData[ dayIndex ].entries[ entryIndex ].is_misentry;
            }
            else 
            {
                weekData[ dayIndex ].entries.splice( entryIndex, 1 );

                // If removed row was last item in project, lets add project as empty holder
                if( data.project_id && data.project )
                {
                    if( weekData[ dayIndex ].entries.findIndex( e => e.project_id == data.project_id ) == -1 )
                    {
                        if( !weekData[ dayIndex ].emptyProjects )
                            weekData[ dayIndex ].emptyProjects = [];

                        weekData[ dayIndex ].emptyProjects.push( data.project );
                    }
                }
            }

            this.setState({ 
                weekData: weekData,
                unsavedChanges: true
            });
        }
    }

    clearDate( date )
    {
        //console.log('clearing date', date );

        let weekData = this.state.weekData.slice();
        const dayIndex = weekData.findIndex( d => d.date == date );

        // Pick removed entries and add them to removed array
        this.markEntryAsRemoved( weekData[ dayIndex ].entries.map( e => e.id ) );

        weekData[ dayIndex ].entries = [];
        weekData[ dayIndex ].emptyProjects = [];
        this.setState({ 
            weekData: weekData,
            unsavedChanges: true
        });
    }

  

    copyDate( date, target )
    {
        //console.log('copying date', date, target );

        const copyId = this.getNextNewEntryId();
        const flexiUsageIds = keyExists( this.state, "settings.absences", true, [] ).filter( a => a.flexitime_usage ).map( a => a.id );
        let weekData = this.state.weekData.slice();
        let unsavedChanges = this.state.unsavedChanges;
        let srcEntries = weekData.find( w => w.date == date ).entries;
        srcEntries = JSON.parse(JSON.stringify( srcEntries )); // Removes reference
        
        // Filter out some special cases
        srcEntries = srcEntries.filter( e => {
        
            if( e.is_misentry )
                return false;

            // Flexi usages (absence) cannot be copied
            if( keyExists( e, "detail.absence_id" ) && flexiUsageIds.indexOf( e.detail.absence_id ) != -1 )
                return false;
           
            return true
        });

        let targetDates = [];
        if( target === "next-day" )
            targetDates.push( moment( date ).add( 1, "day" ).format( 'YYYY-MM-DD' ) );

        else if (target === "rest-of-the-week" || target ==="rest-of-the-week-and-saturday" )
        {
            let endOfWeek;
            if (target ==="rest-of-the-week-and-saturday") {
                endOfWeek = moment(date).endOf('isoWeek').subtract(1, "days"); // Subtract sunday
            }
            else {
                endOfWeek = moment(date).endOf('isoWeek').subtract(2, "days"); // Subtract saturday and sunday
            }
            
           // console.log('end of week', endOfWeek );
            let day = moment( date ).add( 1, "day" );
            while( day <= endOfWeek )
            {
                targetDates.push( day.format( 'YYYY-MM-DD' ) );
                day = day.add( 1, "day" );
            }
        }
        else if (target ="rest-of-the-week-from-last-week") {
            const endOfWeek = moment(date).endOf('isoWeek');
            // console.log('end of week', endOfWeek );
            let day = moment(date);
            while (day <= endOfWeek) {
                targetDates.push(day.format('YYYY-MM-DD'));
                day = day.add(1, "day");
            }
        }

        let skipped = [];
        if (target === "rest-of-the-week-from-last-week") {
            weekData.map(w => {
                if (targetDates.indexOf(w.date) != -1) {
                    const haveEntries = w.entries.length > 0;
                    const haveAllDayAbsence = w.entries.findIndex(e => e.type == "absence" && keyExists(e, "detail.is_all_day", true)) != -1;

                    if (w.locked)
                        skipped.push(moment(w.date).format('DD.MM.YYYY') + ": " + tr('day_locked'));

                    else if (haveAllDayAbsence)
                        skipped.push(moment(w.date).format('DD.MM.YYYY') + ": " + tr('day_full_day_absence'));

                    else {
                        srcEntries = this.state.lastWeekData.find(lw => moment(lw.date).add(7, "days").format('DD.MM.YYYY') == moment(w.date).format('DD.MM.YYYY'));
                        srcEntries = srcEntries ? srcEntries.entries : [];
                        srcEntries = JSON.parse(JSON.stringify(srcEntries)); // Removes reference

                        // Filter out some special cases
                        srcEntries = srcEntries.filter(e => {

                            if (e.is_misentry)
                                return false;

                            // Flexi usages (absence) cannot be copied
                            if (keyExists(e, "detail.absence_id") && flexiUsageIds.indexOf(e.detail.absence_id) != -1)
                                return false;

                            return true
                        });
                        srcEntries.forEach(e => {

                            // Entry has project, so we need to confirm that project is available on target date also
                            if (e.project) {
                                let projectAvailable = true;

                                if (e.project.begin_date && moment(w.date).isBefore(e.project.begin_date, 'day'))
                                    projectAvailable = false;

                                else if (e.project.end_date && moment(w.date).isAfter(e.project.end_date, 'day'))
                                    projectAvailable = false;

                                else if (e.project.ended && moment(w.date).isAfter(e.project.ended, 'day'))
                                    projectAvailable = false;

                                if (!projectAvailable) {
                                    skipped.push(moment(w.date).format('DD.MM.YYYY') + ": " + tr("project") + " '" + e.project.name + "' " + tr("not_available"));
                                    return false;
                                }
                            }

                            let copy = JSON.parse(JSON.stringify(e)); // Removes reference
                            copy.id = "copy" + copyId + "_" + e.id;
                            copy.date = w.date;
                            copy.status = "open";

                            delete copy.detail.entry_id;
                            delete copy.approver_comment;
                            delete copy.created_at;
                            delete copy.updated_at;
                            delete copy.imported_at;

                            if (copy.detail.rows)
                                copy.detail.rows.map(r => {
                                    delete r.id;
                                    delete r.entry_id;
                                    return r;
                                });

                            if (copy.detail.files)
                                copy.detail.files.map(f => {
                                    f.file_id = "copy_" + f.file_id;
                                    return f;
                                });

                            if (haveEntries ) {
                                skipped.push(moment(w.date).format('DD.MM.YYYY') + ": " + tr('day_contains_markups'));
                            }
                            else {
                                w.entries.push(copy);
                                unsavedChanges = true;
                            }
                        });
                    }
                }
                return w;
            });
        }
        else {
            weekData.map(w => {
                if (targetDates.indexOf(w.date) != -1) {
                    const haveEntries = w.entries.length > 0;
                    const haveAllDayAbsence = w.entries.findIndex(e => e.type == "absence" && keyExists(e, "detail.is_all_day", true)) != -1;

                    if (w.locked)
                        skipped.push(moment(w.date).format('DD.MM.YYYY') + ": " + tr('day_locked'));

                    else if (haveAllDayAbsence)
                        skipped.push(moment(w.date).format('DD.MM.YYYY') + ": " + tr('day_full_day_absence'));

                    else {
                        srcEntries.forEach(e => {

                            // Entry has project, so we need to confirm that project is available on target date also
                            if (e.project) {
                                let projectAvailable = true;

                                if (e.project.begin_date && moment(w.date).isBefore(e.project.begin_date, 'day'))
                                    projectAvailable = false;

                                else if (e.project.end_date && moment(w.date).isAfter(e.project.end_date, 'day'))
                                    projectAvailable = false;

                                else if (e.project.ended && moment(w.date).isAfter(e.project.ended, 'day'))
                                    projectAvailable = false;

                                if (!projectAvailable) {
                                    skipped.push(moment(w.date).format('DD.MM.YYYY') + ": " + tr("project") + " '" + e.project.name + "' " + tr("not_available"));
                                    return false;
                                }
                            }

                            let copy = JSON.parse(JSON.stringify(e)); // Removes reference
                            copy.id = "copy" + copyId + "_" + e.id;
                            copy.date = w.date;
                            copy.status = "open";
                            
                            delete copy.detail.entry_id;
                            delete copy.approver_comment;
                            delete copy.created_at;
                            delete copy.updated_at;
                            delete copy.imported_at;

                            if (copy.detail.rows)
                                copy.detail.rows.map(r => {
                                    delete r.id;
                                    delete r.entry_id;
                                    return r;
                                });

                            if (copy.detail.files)
                                copy.detail.files.map(f => {
                                    f.file_id = "copy_" + f.file_id;
                                    return f;
                                });

                            if (haveEntries) {
                                skipped.push(moment(w.date).format('DD.MM.YYYY') + ": " + tr('day_contains_markups'));
                            }
                            else {
                                w.entries.push(copy);
                                unsavedChanges = true;
                            }
                        });
                    }
                }
                return w;
            });
        }
        

        this.setState({ 
            weekData: weekData,
            unsavedChanges: unsavedChanges
        });

        if( skipped.length > 0 )
            alert( tr('entry_copy_error') + "\n" + skipped.join( "\n" ) );

        //console.log('copied', weekData );
    }

    closeEntryModal()
    {
        this.setState({ entryEditShow: false, entryEditData:false });
    }

    descriptionIsRequired( data )
    {
        if( data.type === "hour" )
        {
            let required = false;
            data.detail.rows.forEach( row => {
                if( this.state.settings.hour_types.find( h => h.id == row.hour_type_id ).is_entry_description_required )
                    required = true;
            });
            return required;
        }
        else if( data.type === "travel" )
            return this.state.settings.vehicles.find( v => v.id == data.detail.vehicle_id ).is_entry_description_required;

        else if( data.type === "absence" )
            return this.state.settings.absences.find( a => a.id == data.detail.absence_id ).is_entry_description_required;

        return false;
    }

    validateEntryModal()
    {
        let data = { ...this.state.entryEditData };
        
        if( this.descriptionIsRequired( data ) && !data.description )
            return false;

        if( data.type === "hour" )
        {
            let hourRows = keyExists( data, "detail.rows", true, [] );

            // Check that all rows have hours available
            if( hourRows.findIndex( r => isNaN(parseFloat(r.hours)) || r.hours <= 0 ) != -1 )
                return false;

            if( this.state.settings.work_progress_in_timetracking_required && !data.done_estimate && data.project.user_add_progress )
                return false;

            // Total hours (notation hours NOT included)
            const totalHours = hourRows.filter( r => !this.hourTypeIsNotation( r.hour_type_id ) ).map( r => parseFloat( r.hours ) || 0 ).reduce( ( a, b ) => a + b, 0 );
            if( totalHours <= 0 || totalHours > 24 )
                return false;

            //Check that all installations have enough balance in project storage
            if (data.installations && this.state.project_storage_check_balance_strict) {
                for(let i = 0; i < data.installations.length; i++) {

                    if(((data.installations[i].value !== "" ? data.installations[i].value : 0) > parseFloat(data.installations[i].component_balance_free)) || ((data.installations[i].value !== "" ? data.installations[i].value : 0) === 0 && parseFloat(data.installations[i].component_balance_free) === 0)) {
                        return false
                    }
                }
            }

            // Check null values in installations
            if (data.installations) {
                for (let installation of data.installations) {
                    if (installation.value !== "0" && !installation.value) {
                        return false;
                    }
                }
            }

            // Check if customer work number is required for project
            if (data.project && data.project.customer_work_number_required && !data.customer_work_number) {
                return false;
            }
        }

        else if( data.type === "travel" )
        {
            // Travel route is required
            if( !data.detail.route )
                return false;

            // if ( data.detail.distance !== null && !Number.isInteger(parseFloat(data.detail.distance)) )
            //     return false;

            if (data.project && data.project.customer_work_number_required && !data.customer_work_number) {
                return false;
            }
            if (!data.detail.hours && parseFloat(data.detail.hours) > 24)
                return false;
            
            if (!Boolean(Number(data.detail.hours)) && !Boolean(Number(data.detail.distance)))
                return false;

        }

        else if( data.type === "expense" )
        {
            // If event is checked then even name is required
            if( data.event_checked && !data.detail.event )
                return false;

            // Check that all rows have description and cost filled
            if( keyExists( data, "detail.rows", true, [] ).findIndex( r => ( !r.description || r.cost <= 0 ) ) != -1 )
                return false;

            if( keyExists( data, "detail.files", true, [] ).length == 0 )
                return false;
        }

        else if( data.type === "absence" )
        {
            if( !keyExists( data, "detail.is_all_day", true ) )
            {
                const hours = parseFloat( keyExists( data, "detail.hours", true, 0 ) ) || 0;
                if( hours <= 0 || hours > 24 )
                    return false;
            }

            const absenceId = keyExists( data, "detail.absence_id", true );
            const absence = keyExists( this.state.settings, "absences", true, [] ).find( a => a.id == absenceId );

            if( absence.holiday_usage && !keyExists( data, "detail.is_all_day", true) )
                return false;
        }

        else if( data.type === "allowance" )
        {
            // No need for validation at the moment
        }

        /*
        // Test for required additional fields 
        const requiredMissing = ( f ) => {
            if( f.field.is_required )
            {
                if( data.type == "hour" && !f.field.show_in_hours ||
                    data.type == "travel" && !f.field.show_in_travels ||
                    data.type == "expense" && !f.field.show_in_expenses )
                    return false;

                if( !data.additional_values )
                    return true;

                return ( data.additional_values[ f.field_id ] ? false : true );
            }
            return false;
        };
        if( keyExists( data, "project.additional_fields", true, [] ).findIndex( requiredMissing ) != -1 )
            return false;
        */
        
        return true;
    }

    applyEntryModal()
    {

        let data ={ ...this.state.entryEditData };
        

        // Make sure long description field is not saved if user have first written
        // long description and afterwards unchecked the long description switch.
        if( data.type === "hour" && !data.description_long_checked )
            data.description_long = null;
 
        // SPECIAL CASE: When approver have rejected allowance entry and user have 
        // removed this entry it will be marked as misentry. After that if user adds
        // allowance entry again it would be in misentry mode without this fix!
        if( data.type === "allowance" )
            data.is_misentry = false;


        if (data.type === "travel") {
            if (data.detail.distance == null || data.detail.distance === "") {
                data.detail.distance = 0;
            }
        }

        let weekData = this.state.weekData.slice();
        const dayIndex = weekData.findIndex( d => d.date === data.date );

        if( dayIndex == -1 )
            return false;
        

        // Fill up automatically calculated time for each installation or 
        // chargeables which does NOT have time set
        if( data.type === "hour" && this.hourEntryRef )
        {
            const automaticTime = this.hourEntryRef.current.calculateAutomaticTime();
            if (data.installations && data.installations.length > 0) {
                data.installations.map( i => {
                    if( i.time == 0 || !i.time )
                        i.time = automaticTime;
                    return i;
                });    
            }
            if (data.installations_from_storage && data.installations_from_storage.length > 0) {
                data.installations_from_storage.map(i => {
                    if (i.time == 0 || !i.time)
                        i.time = automaticTime;
                    return i;
                });
            }
            if (data.chargeables && data.chargeables.length > 0) {
                data.chargeables.map( c => {
                    if( c.time == 0 || !c.time )
                        c.time = automaticTime;
                    return c;
                });
            }
            if (data.billable_from_storage && data.billable_from_storage.length > 0) {
                data.billable_from_storage.map(c => {
                    if (c.time == 0 || !c.time)
                        c.time = automaticTime;
                    return c;
                });
            }
            
            const description_to_billable_is_mandatory = this.props.apOrderSettings?.timetracking_description_to_billable_is_mandatory;

            if (description_to_billable_is_mandatory) {
                data.description_to_billable_dispatch_ref = true;
            }

        }

        // If all day absence, clear all other entries from the day first
        if( data.type === "absence" && data.detail.is_all_day )
        {
            // Entry should not clear itself
            const entriesToRemove = weekData[ dayIndex ].entries.filter( e => e.id !== data.id );
            this.markEntryAsRemoved( entriesToRemove.map( e => e.id ) );
            weekData[ dayIndex ].entries = [];
            weekData[ dayIndex ].emptyProjects = [];
        }

        if( data.type === "allowance") {
            const foundAllowance = this.state.settings.allowances.find( a => a.id == data.detail.allowance_id );
            data.detail.compensation = foundAllowance ? foundAllowance.compensation : 0;
        }
        
        const entryIndex = weekData[ dayIndex ].entries.findIndex( e => e.id === data.id );
        
        // Edit old entry 
        if( entryIndex != -1 )
            weekData[ dayIndex ].entries[ entryIndex ] = data;
        
        // Add new entry
        else 
            weekData[ dayIndex ].entries.push( data );

        // Remove possible empty project holder
        if( weekData[ dayIndex ].emptyProjects )
        {
            const projectIndex = weekData[ dayIndex ].emptyProjects.findIndex( p => p.id == data.project_id );
            if( projectIndex > -1 )
                weekData[ dayIndex ].emptyProjects.splice( projectIndex, 1 );
        }

        this.setState({ 
            weekData: weekData,
            unsavedChanges: true
        });
        this.closeEntryModal();
    }

    handleEntryChange( e, customName = false )
    {
        const name = customName || e.target.name;
        let value = e.target.value;

        //console.log( 'Handle change', name, value );

        let entryEditData = { ...this.state.entryEditData };

        // Bugfix for changing project work type
        if( name == 'detail.project_work_id' )
            entryEditData.detail.projectwork = entryEditData.project.works.find( w => w.id == value );
        
        if( name == 'done_estimate'  && value>100 )
            value=100;   

        if( name == 'done_estimate'  && value<0 )
            value=0;   

        // name starts with installations and ends with time
        if (name.startsWith('installations') && name.endsWith('time')) {
            // calculate minutes from entry hours
            const minutes = (entryEditData?.detail?.rows?.reduce((acc, row) => acc + Number(row.hours), 0) || 0) * 60;
            if (minutes < value) {
                value = minutes.toFixed(0);
            }
        }

        entryEditData = setNested( entryEditData, name, value );
        this.setState({ entryEditData });
    }

    calculateAllowancePerProject()
    {
        let sendErrorMessage = false
        let errorDate = null
        const formattedWeek = this.state.weekData.map((day) => {
            if (day.locked) return day
            if (day.entries.length === 0) return day

            let allowanceDayHasProject = false
            let allowanceEntryIndex = null
            const allowanceDay = JSON.parse(JSON.stringify(day))

            //go through days entries and look for allowance
            for (let i = 0; i < day.entries.length; i++) {
                if (day.entries[i].type === 'allowance') {
                    allowanceEntryIndex = i

                    //if day has allowance, loop again and look for  project codes
                    for (let j = 0; j < day.entries.length; j++) {
                        if (day.entries[j].project_id) {
                            allowanceDayHasProject = true
                            break
                        }
                    }
                }
            }

            //if day is missing a project abort rest and init for error message
            if (!allowanceDayHasProject) {
                //sendErrorMessage = true
                //errorDate = day.date
                return day
                
            }

            if (allowanceEntryIndex === null) {
                return day
            }

            //calculate allowance share
            if (allowanceDayHasProject) {
                const projectIds = []

                allowanceDay.entries.forEach((entry) => {
                    const entryExists = entry.project
                    let projectHasId = null
                    if (entryExists) {
                        projectHasId = entryExists.id
                    }

                    if (entry.project_id || projectHasId) {
                        let hadEntry = false
                        for (let i = 0; i < projectIds.length; i++) {                 
                            if (projectIds[i].id === entry.project_id || projectIds[i].id === entry.project.id) {
                                let daysHours = 0

                                if (entry.type === 'travel') {
                                    daysHours += Number(entry.detail.hours)
                                }

                                if (entry.type === 'hour') {
                                    entry.detail.rows.forEach((row) => {
                                        daysHours += Number(row.hours)
                                    })
                                }

                                projectIds[i].hours += daysHours
                                
                                hadEntry = true
                                break
                            }
                        }

                        if (!hadEntry) {
                            let daysHours = 0
                            let isNew = false

                            if (entry.type === 'travel') {
                                if (typeof entry.id === 'string') {
                                    isNew = true
                                }

                                daysHours += Number(entry.detail.hours)
                            }

                            if (entry.type === 'hour') {
                                if (typeof entry.id === 'string') {
                                    isNew = true
                                }

                                entry.detail.rows.forEach((row) => {
                                    daysHours += Number(row.hours)
                                })
                            }

                            projectIds.push({
                                id: entry.project_id,
                                entryId: entry.id, 
                                hours: daysHours, 
                                new: isNew, 
                                date: entry.date})
                            
                        }
                    }
                })

                let total = 0
                projectIds.forEach(entry => {
                    total += entry.hours
                })

                const oldAllowanceEntry = allowanceDay.entries.splice(allowanceEntryIndex, 1)
                let lastNumber = null
                for (let i = allowanceDay.entries.length - 1; i >= 0; i--) {
                    if (typeof allowanceDay.entries[i].id === 'string') {
                        lastNumber = Number(allowanceDay.entries[i].id.replace('new', '')) + 1
                        break
                    }
                }

                if (!lastNumber) {
                    lastNumber = 1
                }

                if (typeof oldAllowanceEntry[0].id === 'string') {

                    projectIds.forEach((entry) => {
                        const splitAllowanceEntry = JSON.parse(JSON.stringify(oldAllowanceEntry[0]))
                        splitAllowanceEntry.detail.allowance_share = entry.hours / total
                        splitAllowanceEntry.project_id = entry.id
                        splitAllowanceEntry.id = `new${lastNumber}`
                        lastNumber++
                        
                        allowanceDay.entries.push(splitAllowanceEntry)
                    })
                } else {
                    if (oldAllowanceEntry[0].temp_projectId) {
                        const singleAllowanceEntry = JSON.parse(JSON.stringify(oldAllowanceEntry[0]))
                        singleAllowanceEntry.project_id = singleAllowanceEntry.temp_projectId
                        delete singleAllowanceEntry.temp_projectId

                        let containsNew = false
                        projectIds.forEach((project) => {
                            if (project.new) {
                                containsNew = true
                            }
                        })

                        if (!containsNew) {
                            allowanceDay.entries.push(singleAllowanceEntry)
                        } else {
                            projectIds.forEach((project) => {
                                if (project.id === singleAllowanceEntry.project_id) {
                                    singleAllowanceEntry.detail.amount = project.hours / total
                                }
                            })

                            allowanceDay.entries.push(singleAllowanceEntry)

                            projectIds.forEach((project) => {
                                if (project.new) {
                                    const newAllowance = {
                                        date: project.date,
                                        description: oldAllowanceEntry[0].description,
                                        detail: {
                                            allowance_id: oldAllowanceEntry[0].detail.allowance_id,
                                            allowance_share: project.hours / total,
                                            start_time: oldAllowanceEntry[0].detail.start_time,
                                            end_time: oldAllowanceEntry[0].detail.end_time
                                        },
                                        id: `new${lastNumber}`,
                                        is_misentry: false,
                                        project: null,
                                        project_id: project.id,
                                        status: 'open',
                                        type: 'allowance'
                                    }
    
                                    allowanceDay.entries.push(newAllowance)
                                    lastNumber++
                                }
                            })
                        }    
                    }

                    if (oldAllowanceEntry[0].originalAllowances && oldAllowanceEntry[0].originalAllowances.length > 0) {
                        const updatedAllowanceEntries = JSON.parse(JSON.stringify(oldAllowanceEntry[0].originalAllowances))

                        projectIds.forEach((project) => {
                            updatedAllowanceEntries.forEach((entry) => {
                                if (project.id === entry.project_id) {
                                    entry.total_allowance = project.hours / total
                                    entry.detail.amount = project.hours / total

                                    entry.description = oldAllowanceEntry[0].description
                                    entry.detail.allowance_id = oldAllowanceEntry[0].detail.allowance_id
                                    entry.detail.compensation = oldAllowanceEntry[0].detail.compensation
                                    entry.detail.start_time = oldAllowanceEntry[0].detail.start_time
                                    entry.detail.end_time = oldAllowanceEntry[0].detail.end_time
                                    entry.detail.salary_category = oldAllowanceEntry[0].detail.salary_category
                                }
                            })
                        })

                        allowanceDay.entries = allowanceDay.entries.concat(updatedAllowanceEntries)

                        projectIds.forEach((project) => {
                            if (project.new) {
                                const newAllowance = {
                                    date: project.date,
                                    description: oldAllowanceEntry[0].description,
                                    detail: {
                                        allowance_id: oldAllowanceEntry[0].detail.allowance_id,
                                        allowance_share: project.hours / total,
                                        start_time: oldAllowanceEntry[0].detail.start_time,
                                        end_time: oldAllowanceEntry[0].detail.end_time
                                    },
                                    id: `new${lastNumber}`,
                                    is_misentry: false,
                                    project: null,
                                    project_id: project.id,
                                    status: 'open',
                                    type: 'allowance'
                                }

                                allowanceDay.entries.push(newAllowance)
                                lastNumber++
                            }
                        })
                    }
                }

                return allowanceDay
            }
        })

        /*
        if (sendErrorMessage) {
            //error handler
            return false
        }
        */
        

        this.setState({
            weekData: formattedWeek
        })
        
        return true
    }

    parseAllowanceRemoval()
    {
        this.state.weekDataOriginal.forEach((day) => {
            day.entries.forEach((entry) => {
                this.state.removedEntries.forEach((removedId) => {
                    if (entry.id === removedId && entry.type === 'allowance') {

                        const allowanceIds = []
                        
                        if (entry.originalAllowances) {
                            for (let i = 1; i < entry.originalAllowances.length; i++) {
                                allowanceIds.push(entry.originalAllowances[i].id)
                            }

                            this.markEntryAsRemoved(allowanceIds)
                        }
                    }
                })
            })
        })

        this.state.weekDataOriginal.forEach(day => {
            day.entries.forEach(entry => {
                const foundId = this.state.removedEntries.find(id => {
                    if (entry.id === id) return id
                })

                const foundEntry = day.entries.find(dayEntry => {
                    if (dayEntry.id === foundId && dayEntry.type !== 'allowance') return dayEntry
                })

                if (foundEntry) {
                    const allowanceId = day.entries.find(secondDayEntry => {
                        if (secondDayEntry.type === 'allowance' && secondDayEntry.originalAllowances) {
                            const id = secondDayEntry.originalAllowances.find(allowanceEntry => {
                                if (allowanceEntry.project_id === foundEntry.project_id) return allowanceEntry.id
                            })

                            if (id) {
                                return id
                            }
                        }
                    })

                    if (allowanceId) {
                        this.markEntryAsRemoved(allowanceId.id)

                        //remove entry from original allowances
                        const weekData = this.state.weekData

                        weekData.forEach(day => {
                            day.entries.map(entry => {
                                if (entry.type === 'allowance' && entry.originalAllowances) {
                                    const updatedAllowances = entry.originalAllowances.filter(allowance => {
                                        if (allowance.id !== allowanceId.id) return allowance
                                    })

                                    entry.originalAllowances = updatedAllowances
                                    return entry
                                }

                                return entry
                            })

                            return day
                        })

                        this.setState({weekData: weekData})
                    }
                }
            }) 


            //Remove stray allowances that aren't attached to any project
            const parentAllowanceEntry = day.entries.find((entry) => entry.type === 'allowance')
            
            if (parentAllowanceEntry) {
                const otherEntries = day.entries.filter((entry) => entry.type !== 'allowance')
            
                const uniqueAllowanceProjects = _.uniqBy(parentAllowanceEntry.originalAllowances, 'project_id')
                const uniqueOtherProjects = _.uniqBy(otherEntries, 'project_id').map((entry) => entry.project_id)
        
                if (uniqueAllowanceProjects.length > uniqueOtherProjects.length) {
                    uniqueAllowanceProjects.forEach((allowanceEntry) => {
                        if (!uniqueOtherProjects.includes(allowanceEntry.project_id)) {
                            this.markEntryAsRemoved(allowanceEntry.id)
                        }
                    })
                }
            }
            
        })
    }

    async save()
    {
        await this.parseAllowanceRemoval()
        const allowanceCheck = await this.calculateAllowancePerProject()

        //prevent saving if allowance days has no entries
        if (!allowanceCheck) {
            return
        }

        //console.log('saving...', this.state.weekData );
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'timetracking/save',
            data: { 
                week: this.state.weekData,
                removed: this.state.removedEntries,
            },
        }).then(( response ) => {

            // const monday = moment().year(this.state.selectedYear).week(this.state.selectedWeek).startOf("week"); RIKKI
            
            //hotfix
            let monday = moment().year(this.state.selectedYear).week(this.state.selectedWeek).startOf("week");
            // console.log(moment().year(this.state.selectedYear).week(this.state.selectedWeek));
            if (this.state.selectedYear == 2022 && this.state.selectedWeek == 52) {

                monday = moment("2022-12-26");
            }
            // console.log(monday, this.state.selectedYear, this.state.selectedWeek);
            let days = [];
            for (let i = -7; i < 7; i++) {
                days.push( monday.clone().add(i, 'd').format('YYYY-MM-DD') );
            }
            //console.log( 'Save DONE!', response );
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{tr('saved')}</strong>,
            });

            this.getEntries( days, true );

        }).catch((error) => {
            console.error(error);
            this.setState({ loading: false });
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{ tr('save_error') }</strong>,
            });
        });
    }

    send()
    {
        //console.log('send', this.state.weekData, this.state.removedEntries );

        //su=0, ma=1, ti=2, ke=3, to=4, pe=5, la=6
        let sendDates = [];
        const now = moment();

        const isFridayToSunday = now.day()>=5;
        this.state.weekData.forEach(w => {
            let dateNumber = moment(w.date).day();
            
            if (!w.locked) {
                if (w.date < now.format()) {
                    sendDates.push(w.date);
                }
                else {
                    if (isFridayToSunday) {
                        //jos on perjantai ja viikonloppu halutaan mukaan
                        if (dateNumber%6== 0) {
                            sendDates.push(w.date);
                        }
                    }
                }
            }
        });

        this.setState({ 
            sendModalShow: true,
            sendDates: sendDates,
        });

    }

    toggleSendDate( date )
    {
        let sendDates = this.state.sendDates.slice();
        //console.log('toggle', date, sendDates );
        const index = sendDates.indexOf( date );
        if( index > -1 )
            sendDates.splice( index, 1 );
        else 
            sendDates.push( date );

        this.setState({ sendDates });
    }

    sendEntries()
    {
        //console.log('send', this.state.sendDates );
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'timetracking/send',
            data: { dates: this.state.sendDates },
        }).then(( response ) => {
            
            this.getEntries( this.state.sendDates, true );
            this.closeSendModal();

            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('entries_sent') }</strong>,
            });
     
        }).catch((error) => {
            // console.error(error);
            this.setState({ loading: false });
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{`${tr('save_error')} (${error})`}</strong>,
            });
        });
    }

    closeSendModal()
    {
        this.setState({ sendModalShow: false });
    }

    getProjectPath( project )
    {
        let path = [];
        if( project.ancestors.length > 0 )
            project.ancestors.forEach( a => path.push( a.name ) );
        path.push( project.name );
        return path;
    }
    /*
    additionalFieldsRenderer( type, entry )
    {   
        const projectAddFields = keyExists( entry, "project.additional_fields", true );
    
        if( !projectAddFields )
            return false;

        let fields = [];
        projectAddFields.forEach( a => {
            if( ( type === "hour" && a.field.show_in_hours ) ||
                ( type === "travel" && a.field.show_in_travels ) ||
                ( type === "expense" && a.field.show_in_expenses ) )
            {
                let row = a.field;
                row.value = a.value || ''; 
                fields.push( a.field );
            }
        });

        if( fields.length == 0 )
            return false;

        return (
            <div className="apFormGroup">
                <h6>Projektikohtaiset lisätiedot</h6>
                { fields.map( ( f, index ) => {
                    const value = keyExists( entry, "additional_values." + f.id, true, '' );
                    return (
                        <ApInputStack gap="0" key={ index }>
                            <ApAddon noRightBorder width="50%" className="additionalFieldLabel">
                                { f.name }
                                { f.description && 
                                    <ApTooltip text={ f.description } multiline={ f.description.length > 70 }>
                                        <SvgIcon icon="info-circle" type="solid" />
                                    </ApTooltip>
                                }
                            </ApAddon>
                            <ApInput 
                                type={ f.type == "number" ? "number" : "text" } 
                                id={ "additonal" + index } 
                                name={ "additonal" + index }
                                value={ value }
                                align={ f.type == "number" ? "right" : "left" }
                                onChange={ ( e ) => this.handleEntryChange( e, "additional_values." + f.id ) }
                                validationState={ f.is_required && !value ? "error" : "" }
                            />
                            { f.unit && 
                                <ApAddon noLeftBorder width="100">{ f.unit }</ApAddon>
                            }
                        </ApInputStack>
                    )
                })}

            </div>
        );
    }
    */
    renderFooterTooltip( type, summary )
    {
        const round = ( value ) => {
            return Math.round( value * 100 ) / 100;
        };

        switch( type )
        {
            case "hours":
                if( summary.week.hours.count == 0 && summary.week.absences.count == 0 )
                    return false;

                return (
                    <div className={ type }>
                        <strong>{ tr('weekly_hours') }:</strong><br />
                        { tr('normal_hours') }: { round( summary.week.hours.regular ) } h<br />
                        { tr('overtime_hours') }: { round( summary.week.hours.overtime ) } h<br />
                        { tr('fees_supplements') }: { round( summary.week.hours.notation ) } h<br />
                        { tr('travel_hours') }: { round( summary.week.travels.hours ) } h<br />
                        { tr('absences') }: { round( summary.week.absences.hours ) } h<br />
                        {keyExists(this.state, "settings.user.flex_time_allowed", true, false) && 
                            <>
                                { tr('flexitime_change') }: { round( summary.week.hours.flexi ) } h<br />
                            </>
                        }

                        { summary.week.travels.count != 0 &&
                            <div className="showUnder700">
                                <br />
                                <strong>{ tr('weekly_travels') }</strong><br />
                                { tr('kilometers') }: { round( summary.week.travels.distance ) } h<br />
                                { tr('travel_hours') }: { round( summary.week.travels.hours ) } h<br />
                                { tr('compensations') }: { formatMoney( summary.week.travels.compensation ) } { auth.getCurrencySign() }
                            </div>
                        }

                        { ( summary.week.expenses.count != 0 || summary.week.allowances.count != 0 ) &&
                            <div className="showUnder800">
                                <br />
                                <strong>{ tr('weekly_compensations') }</strong><br />
                                { tr('expense_compensations') }: { summary.week.expenses.count } kpl<br />
                                { tr('expense_compensation_sum') }: { formatMoney( summary.week.expenses.costs ) } { auth.getCurrencySign() }<br />
                                { tr('daily_allowances') }: { formatMoney( summary.week.allowances.compensation ) } { auth.getCurrencySign() } <small>({ summary.week.allowances.count } kpl)</small>
                            </div>
                        }

                    </div>
                );

            case "travel":
                if( summary.week.travels.count == 0 )
                    return false;

                return (
                    <div className={ type }>
                        <strong>{ tr('weekly_travels') }</strong><br />
                        { tr('kilometers') }: { round( summary.week.travels.distance ) } km<br />
                        { tr('travel_hours') }: { round( summary.week.travels.hours ) } h<br />
                        { tr('compensations') }: { formatMoney( summary.week.travels.compensation ) } { auth.getCurrencySign() }
                    </div>
                );
            case "compensation":
                if( summary.week.expenses.count == 0 && summary.week.allowances.count == 0 )
                    return false;

                return (
                    <div className={ type }>
                        <strong>{ tr('weekly_compensations') }</strong><br />
                        { tr('expense_compensations') }: { summary.week.expenses.count } kpl<br />
                        { tr('expense_compensation_sum') }: { formatMoney( summary.week.expenses.costs ) } { auth.getCurrencySign() }<br />
                        { tr('daily_allowances') }: { formatMoney( summary.week.allowances.compensation ) } { auth.getCurrencySign() } <small>({ summary.week.allowances.count } kpl)</small>
                    </div>
                );
            case "errors":
                if( summary.errors.length == 0 )
                    return false;

                const dailyErrors = groupify( summary.errors, "date" );
                return (
                    <div className={ type }>

                        { this.state.unsavedChanges &&
                            <div className="unsavedChanges">
                                <SvgIcon icon="exclamation-circle" type="solid" />
                                { tr('not_yet_saved') }
                            </div>
                        }

                        { Object.keys( dailyErrors ).map( date => {
                            return (
                                <div className="day" key={ date }>
                                    <strong>{ moment( date ).locale(currentLang()).format('D.M dddd') }:</strong><br />
                                    { dailyErrors[ date ].map( ( e, index ) => {
                                       
                                        return (
                                            <div className={ e.type } key={ index }>
                                                <SvgIcon icon="exclamation-triangle" type="solid" />                                             
                                                <span>{ e.text }</span>
                                               
                                             

                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                );
            default: break;
        }
        return false;
    }

    renderFooter( summary )
    {
        const round = ( value ) => {
            return Math.round( value * 100 ) / 100;
        };

        const haveFlexitime = keyExists( this.state, "settings.user.flex_time_allowed", true, false );
        const hours = round( summary.week.hours.regular + summary.week.hours.overtime + summary.week.travels.hours + summary.week.absences.hours );
        const travel = round( summary.week.travels.distance );
        const money = summary.week.expenses.costs + summary.week.allowances.compensation;
        const allLocked = this.state.weekData.every(day => day.locked);

        const errorTypes = groupify( summary.errors, "type" );
        let className = "success";
        let icon = "check";
        let tooltip = false;

        if( summary.errors.length > 0 )
        {
            className = ( "error" in errorTypes ) ? "error" : "warning";
            icon = "exclamation-triangle";
            tooltip = this.renderFooterTooltip( "errors", summary );
        }
        else if( this.state.unsavedChanges )
        {
            className = "warning";
            icon = "exclamation-circle";
            tooltip = tr('not_yet_saved');
        }

        return (
            <div className="apBox customFooter">

                <ApTooltip text={ this.renderFooterTooltip( "hours", summary ) } position="topleft">
                    <div className={ "hideUnder600 stat" + ( hours == 0 ? " empty" : "" ) }>
                        <SvgIcon icon="clock" type="solid" />
                        { hours } h 
                        
                        { haveFlexitime &&
                            <span className={ "hideUnder650 flexi" + ( summary.week.hours.flexi > 0 ? " plus" : summary.week.hours.flexi < 0 ? " minus" : "" ) }>
                                { summary.week.hours.flexi > 0 
                                    ? <span>+{Number(Number(summary.week.hours.flexi).toFixed(2)) }</span>
                                    : Number(Number(summary.week.hours.flexi).toFixed(2))
                                } h
                            </span>
                        }

                    </div>
                </ApTooltip>

                <ApTooltip text={ this.renderFooterTooltip( "travel", summary ) } position="topleft">
                    <div className={ "hideUnder700 stat" + ( travel == 0 ? " empty" : "" ) }>
                        <SvgIcon icon="car" type="solid" />
                        { travel } km
                    </div>
                </ApTooltip>

                <ApTooltip text={ this.renderFooterTooltip( "compensation", summary ) } position="topleft">
                    <div className={ "hideUnder800 stat" + ( money == 0 ? " empty" : "" ) }>
                        <SvgIcon icon="money-bill-wave" type="solid" />
                        { formatMoney( money ) } { auth.getCurrencySign() }
                    </div>
                </ApTooltip>

                <div className="apButtonGroup">

                    <ApTooltip text={ tooltip }>
                        <div className={ "hideUnder600 stat " + className }>
                            { summary.errors.length  > 0 &&
                                <span className="apBadge dark">{ summary.errors.length  }</span>
                            }
                            <SvgIcon icon={ icon } type="solid" />
                        </div>
                    </ApTooltip>

                    <ApButton className={ "save" + ( this.state.unsavedChanges && !this.state.loading ? " highlight" : "" ) } color="blue" onClick={ this.save } disabled={ !this.state.unsavedChanges || this.state.loading } loading={ this.state.loading }>
                        <SvgIcon icon="save" type="solid" />
                        { tr('save') }
                    </ApButton>

                    <div className="divider"></div>

                    <ApButton className="send" color="green" onClick={ this.send } disabled={ this.state.unsavedChanges || this.state.loading || allLocked } loading={ this.state.loading }>
                        <SvgIcon icon="paper-plane" type="solid" />
                        { `${tr('send')}...` }
                    </ApButton>
                </div>

            </div>
        );
    }
    dayNotHaveEntry(date) {
        if (date) {
            const week = this.state.weekData;
            const day = week.find(day => day.date == date);
            if (day.entries.length > 0) {
                let onlyAllowances = true;
                for (let entry of day.entries) {
                    if (entry.type == "allowance" || entry.type == "absence") {
                        
                    }
                    else {
                        onlyAllowances = false;
                    }
                    
                }
                return onlyAllowances;
                
            }
            else {
                return true;
            }
            
        }
        return false
    }

    render()
    {
        const summary = this.getWeekSummary();
        const haveFlexitime = keyExists( this.state, "settings.user.flex_time_allowed", true, false );
        let selectedFlexChange = 0;
        let selectedHaveEnoughHours = true;
        //console.log(this.state);
        return (
            <div id="pageTimetrackingEntry">

                <ApFormPage 
                    unsaved={ this.state.unsavedChanges }
                    className="timetrackingEntryForm"
                    customFooter={ () => this.renderFooter( summary ) }
                >
                    <div className="apBox">
                        <div className="apBoxHeader">
                            <h1>{ tr('own_working_time') }</h1>
                            <p>{ tr('own_working_time_info') }</p>
                        </div>
                    
                        <WeekBrowser 
                            year={ parseInt( this.state.selectedYear, 10 ) } 
                            week={ parseInt( this.state.selectedWeek, 10 ) }
                            onChange={ this.changeWeek }
                            disabled={ this.state.unsavedChanges }
                            startYear= { parseInt( this.state.selectedYear-5 ) }
                            endYear= { parseInt( new Date().getFullYear()+1 ) }
                        /> 
                    </div>

                    <div className="days">
                    	{ this.state.weekData.map( day => 
                    		<WeekDay
                                key={day.date}
                                loading={this.state.loading}
                                data={day}
                                summary={summary}
                                settings={this.state.settings}
                                getNextId={this.getNextNewEntryId}
                                onEditEntry={this.editEntry}
                                onRemoveEntry={this.removeEntry}
                                onClearDate={(...params) => this.confirm('clearDate', params)}
                                onCopyDate={this.copyDate}
                                copyFromLastWeekAllowed={true}
                                onAddProject={this.addProject}
                                onRemoveProject={(...params) => this.confirm('removeProject', params)}
                                userHasHoursDaily={keyExists(this.state, "settings.user.hours_daily", true)}
	                        />
                    	)}
                    </div>

                    <div className="showUnder600">
                        <hr />
                        <div className="mobileWeekSummary">
                            { this.renderFooterTooltip( "hours", summary ) }
                        </div>
                    </div>

                </ApFormPage>

                <ApConfirm
                    show={ this.state.confirm.show }
                    header={ this.state.confirm.header || tr('confirm_q') }
                    body={ this.state.confirm.body || undefined }
                    onConfirm={ this.state.confirm.onConfirm }
                    onClose={ this.closeConfirm }
                />

                <ApModal
                    className="sendModal narrow"
                    show={ this.state.sendModalShow }
                    handleClose={ this.closeSendModal }
                    closeFromBg
                    header={ 
                        <div className="padding">
                            <h3>{ tr('send_entries') }</h3>
                        </div>
                    }
                    body={ 
                        <div className={window.innerWidth < 350 ? "" : "padding"}>
                            
                            <div className="selectDates">
                                { this.state.weekData.map( w => {

                                    const submitted = ( 'is_unlocked' in w && !w.is_unlocked );
                                    const selected = this.state.sendDates.indexOf( w.date ) != -1;
                                    
                                    const sendableEntries = w.entries.filter( e => [ "open", "rejected" ].indexOf( e.status ) != -1 ).length;
                                    const fixCount = w.entries.filter( e => e.status == "rejected" ).length;

                                    let flexi = summary.days[ w.date ].hours.flexi; 
                                    const notEnoughHours = ( !haveFlexitime && flexi < 0 );
                                    
                                    // Because user does NOT have flexi time, we can clear 
                                    // it out so it wont affect to anything
                                    if( !haveFlexitime )
                                        flexi = 0;

                                    let classes = [ "sendDate" ];

                                    if( submitted )
                                        classes.push( "submitted" );

                                    if( selected )
                                    {
                                        classes.push( "selected" );

                                        if( notEnoughHours )
                                            selectedHaveEnoughHours = false;

                                        selectedFlexChange += flexi;
                                    }

                                    if( w.locked )
                                        classes.push( "locked" );

                                    // Get total hours (regular + overtime + travel hours )
                                    const hours = summary.days[ w.date ].hours.regular + 
                                        summary.days[ w.date ].hours.overtime + 
                                        summary.days[ w.date ].travels.hours; 

                                    return (
                                        <label key={ w.date } className={ classes.join( " " ) } htmlFor={ "send-date-switch" + w.date }>
                                            <ApSwitch
                                                small
                                                inline
                                                id={ "send-date-switch" + w.date }
                                                on={ selected }
                                                disabled={ w.locked || this.state.loading }
                                                onChange={ () => this.toggleSendDate( w.date ) }
                                            />
                                            <div className="date">
                                                <strong>{ moment( w.date ).locale(currentLang()).format( window.innerWidth < 350 ? 'D.M' : 'D.M.YYYY' ) }</strong> - { moment( w.date ).locale(currentLang()).format( window.innerWidth < 400 ? 'ddd' : 'dddd' ) }
                                            </div>
                                            { ( hours > 0 || flexi != 0 ) &&
                                                <div className="hours">
                                                    { roundToDecimals(hours,3) } h
                                                    {flexi != 0 && <span className={"flexi" + (flexi > 0 ? " plus" : " minus")}>{flexi > 0 ? "+" + roundToDecimals(flexi, 3) : roundToDecimals(flexi, 3) } h</span> }
                                                </div>
                                            }

                                            { notEnoughHours && !submitted && 
                                                <SvgIcon className="warning" icon="exclamation-triangle" type="solid" />
                                            }

                                            { sendableEntries > 0 &&
                                                <div className="entries">
                                                    { pluralizeCount( sendableEntries, tr('pluralize_entry'), tr('pluralize_entries') ) }
                                                    { fixCount > 0 &&
                                                        <small> ({ pluralizeCount( fixCount, tr('fix'), tr('fixes') ) })</small>
                                                    }
                                                </div>
                                            }
                                            { sendableEntries == 0 && !submitted &&
                                                <div className="entries dayoff">
                                                    <small>{ flexi < 0 ? tr('flexi_leave') : tr('day_off') }</small>
                                                </div>
                                            }
                                            { submitted &&
                                                <div className="entries">{ tr('sent') }</div>
                                            }
                                        </label>
                                    );
                                })}
                            </div>

                            <Collapse in={ selectedFlexChange < 0 }>
                                <div>
                                    <div className={ "apFormGroup" + ( this.state.addFlexiUsage ? " success" : ( selectedFlexChange < 0 && !this.state.addFlexiUsage ? " error" : "" ) ) }>
                                        <div className="apSwitchBlock">
                                            <label htmlFor="add-flexi-usage-switch" className="info">
                                                {tr('flexitime_accept')} <span className="flexi minus">{roundToDecimals(selectedFlexChange,3)  } h</span>
                                                <small>{ tr('regular_working_hours_note') }</small>
                                            </label>
                                            <ApSwitch
                                                id="add-flexi-usage-switch"
                                                on={ this.state.addFlexiUsage }
                                                onChange={ () => this.setState({ addFlexiUsage: !this.state.addFlexiUsage }) }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
        
                            <div className="apInfo small">
                                <SvgIcon icon="lock" type="solid" />
                                { tr('sent_days_locked') }
                            </div>

                            <Collapse in={ !selectedHaveEnoughHours }>
                                <div>
                                    <div className="apWarningMsg">
                                        <SvgIcon className="small-inline" icon="exclamation-triangle" type="solid" />
                                        { tr('regular_working_hours_selected_warning') }
                                    </div>
                                </div>
                            </Collapse>                         

                        </div>
                    }
                    footer={
                        <div className="padding">
                            
                            <ApButton className="applyButton" color="green" onClick={ this.sendEntries } disabled={ this.state.sendDates.length == 0 || this.state.loading || ( selectedFlexChange < 0 && !this.state.addFlexiUsage ) || !selectedHaveEnoughHours } loading={ this.state.loading }>
                                <SvgIcon icon="paper-plane" type="solid" />
                                { tr('send') } { this.state.sendDates.length == 7 ? tr('week') : pluralizeCount( this.state.sendDates.length, tr('day'), tr('days') ) }
                            </ApButton>

                            <ApButton className="cancelButton" onClick={ this.closeSendModal } disabled={ this.state.loading }>
                                <SvgIcon icon="times" type="solid" />
                                { tr('cancel') }
                            </ApButton>

                        </div>
                    }
                />

                <ApModal
                    className="entryEdit narrow"
                    show={ this.state.entryEditShow }
                    handleClose={ this.closeEntryModal }
                    closeFromBg
                    header={ 
                        <div className="padding">
                            <h3>{ moment( this.state.entryEditData.date ).locale(currentLang()).format( 'DD.MM.YYYY - dddd' ) }</h3>
                            <p>
                                { keyExists( this.state, "entryEditData.project.name" ) && 
                                    <ApPath icon="folder" items={ this.getProjectPath( this.state.entryEditData.project ) } boldLast />
                                }
                                { !keyExists( this.state, "entryEditData.project.name" ) && 
                                    <span>
                                        { this.state.entryEditData.type === "hour" && tr('entry_info') }
                                        { this.state.entryEditData.type === "travel" && tr('travel_info') }
                                        { this.state.entryEditData.type === "expense" && tr('expense_info') }
                                        { this.state.entryEditData.type === "absence" && tr('absence_info') }
                                        { this.state.entryEditData.type === "allowance" && tr('daily_allowance_info') }
                                    </span>
                                }
                            </p>
                        </div>
                    }
                    body={ 
                        <div className="padding">
                            
                            { this.state.entryEditData.status == "rejected" &&
                                <div className="apErrorMsg rejectMsg">
                                    {/* TODO (layout etc.) */}
                                    { keyExists( this.state, "entryEditData.status_changed_at" ) && 
                                        <div className="timestamp">{ moment( this.state.entryEditData.status_changed_at ).locale(currentLang()).format('LLL') }</div>
                                    }
                                    { keyExists( this.state, "entryEditData.approver" ) &&
                                        <div className="approver">{ this.state.entryEditData.approver.name }</div>
                                    }
                                    <strong>{ tr('entry_rejected') }</strong> 
                                    { keyExists( this.state, "entryEditData.approver_comment" ) &&
                                        <p>{ (this.state.entryEditData.approver_comment !=null ? (this.state.entryEditData?.approver_user_person_detail?.full_name ?? tr('comment')) + " : " + this.state.entryEditData.approver_comment  : this.state.entryEditData?.approver_user_person_detail?.full_name)    }</p>
                                    }
                                  
                                </div>
                            }

                            { this.state.entryEditData.type === "hour" &&
                                <HourEntry
                                    project_storage_check_balance_strict={this.state.project_storage_check_balance_strict}
                                    projectStorageEnabled={this.state.projectStorageEnabled}
                                    ref={ this.hourEntryRef }
                                    hourTypes={ this.state.settings.hour_types }
                                    data={ this.state.entryEditData }
                                    onChange={ this.handleEntryChange }
                                    //addFieldsRenderer={ this.additionalFieldsRenderer }
                                    isNotationFunc={ this.hourTypeIsNotation }
                                    showRemainingHours={this.state.settings && this.state.settings.show_remaining_hours}
                                    showRemainingHoursOnlySelected = {this.state.settings && this.state.settings.remaining_hours_show_selected_projects}
                                    weekData={this.state.weekData}
                                    entryEditShow={this.state.entryEditShow}
                                    apOrderSettings={this.props.apOrderSettings}
                                />
                            }
                            { this.state.entryEditData.type === "travel" &&
                                <TravelEntry
                                    vehicles={ this.state.settings.vehicles }
                                    data={ this.state.entryEditData }
                                    onChange={ this.handleEntryChange }
                                    //addFieldsRenderer={ this.additionalFieldsRenderer }
                                    weekData={this.state.weekData}
                                    entryEditShow={this.state.entryEditShow}
                                />
                            }
                            { this.state.entryEditData.type === "expense" &&
                                <ExpenseEntry
                                    currencies={ this.state.settings.currencies }
                                    paymentCards={ this.state.settings.payment_cards }
                                    data={ this.state.entryEditData }
                                    onChange={ this.handleEntryChange }
                                    //addFieldsRenderer={ this.additionalFieldsRenderer }
                                />
                            }
                            { this.state.entryEditData.type === "absence" &&
                                <AbsenceEntry
                                    absences={ this.state.settings.absences }
                                    user={ this.state.settings.user }
                                    data={ this.state.entryEditData }
                                    onChange={ this.handleEntryChange }
                                    hoursDaily={ keyExists( this.state, "settings.user.hours_daily", true ) }
                                    dayEntries={ this.getDayEntries( this.state.entryEditData.date ) }
                                />
                            }
                            { this.state.entryEditData.type === "allowance" &&
                                <AllowanceEntry
                                    allowances={ this.state.settings.allowances }
                                    data={ this.state.entryEditData }
                                    onChange={this.handleEntryChange}
                                    dayNotHaveEntry={this.dayNotHaveEntry(this.state.entryEditData.date)}
                                />
                            }

                        </div>
                    }
                    footer={
                        <div className="padding">
                            
                            <ApButton className="applyButton" color="green" onClick={ this.applyEntryModal } disabled={ !this.validateEntryModal() }>
                                <SvgIcon icon="check" type="solid" />
                                { tr('continue') }
                            </ApButton>

                            <ApButton className="cancelButton" onClick={ this.closeEntryModal }>
                                <SvgIcon icon="times" type="solid" />
                                { tr('cancel') }
                            </ApButton>

                        </div>
                    }
                />

            </div>
        );
    }
}

const mapStateToProps = state => ({
    apTimetrackingSettings: state.apTimetrackingSettings,
    apOrderSettings: state.apOrderSettings,
});

const mapDispatchToProps = dispatch => ({
    dayIsLocked: ( locked ) => {
        dispatch({
            type: 'DAY_IS_LOCKED',
            payload: locked
        });
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(TimetrackingEntry);
