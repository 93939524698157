/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import api from 'services/Api/Api.js';

import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import { ApInput } from 'common/ApInput/ApInput.js';
// import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';
import File from 'common/File/File.js';

import { keyExists, 
         validateDate, 
         timeSince, 
         readableFileSize, 
         dateInputToSql,
         sqlToDateInput,
         tr,
         capitalize,
         currentLang } from  'services/Helpers/Helpers.js';

import { Collapse } from 'react-bootstrap';

export default class TimetrackingPayrollBatches extends React.Component {

	constructor( props ) 
	{
		super(props);
        this.state = {
            loading: false,
            error: false,
            batches: [],

            detailsOpen: false,
            details: null,

            confirmDeleteIds: false,

        };
        autoBind(this);
	}

    componentDidMount() {

        // Get ID from URL parameter
        const id = parseInt( this.props.match.params.id, 10 );
        this.getBatches( id );
    }

    getBatches( selectedId )
    {
        this.setState({ loading: true, error: false });
        api({
            method: 'get',
            url: 'timetracking/payroll/batches',
        }).then(( response ) => {

            //console.log('BATCHES', response );
            
            const batches = response.map( r => {
                if( r.payment_date )
                    r.payment_date = sqlToDateInput( r.payment_date );
                return r;
            });

            this.setState({
                loading: false,
                batches: batches,
            }, () => this.openDetails( selectedId ) );

        }).catch( ( error ) => {
            console.log( 'getBatches ERROR', error );
            this.setState({ 
                loading: false, 
                error: keyExists( error, "response.data.message", true, "Odottamaton virhe" ) || "VIRHE"
            });
        });
    }

    markAsConfirmed( indexes, confirmed = true ) {
        //console.log('markAsConfirmed', indexes, confirmed );

        let ids = [];
        indexes.forEach( i => ids.push( this.state.batches[ i ].id ) );

        this.confirmBatches({
            ids: ids,
            confirm: confirmed,
        }, this.getBatches ); 
    }

    confirmBatches( data, afterFunc = false )
    {
        //console.log('saving...', data );
        this.setState({ loading: true, error: false });
        api({
            method: 'post',
            url: 'timetracking/payroll/batches/confirm',
            data: data,
        }).then(( response ) => {

            //console.log('confirmBatches', response );

            if( typeof( afterFunc ) === "function" )
                afterFunc();

        }).catch( ( error ) => {
            console.log( 'confirmBatches ERROR', error );
            this.setState({ 
                loading: false, 
                error: keyExists( error, "response.data.message", true, "Odottamaton virhe" ) || "VIRHE"
            });
        });  
    }

    confirmDelete( indexes ) 
    {
        let ids = [];
        indexes.forEach( i => ids.push( this.state.batches[ i ].id ) );

        if( ids.length > 0 )
            this.setState({ confirmDeleteIds: ids });
    }

    deleteBatches()
    {
        //console.log('deleting...', this.state.confirmDeleteIds );
        this.setState({ loading: true, error: false });
        api({
            method: 'post',
            url: 'timetracking/payroll/batches/delete',
            data: { ids: this.state.confirmDeleteIds },
        }).then(( response ) => {

            //console.log('deleteBatches', response );
            this.setState({ confirmDeleteIds: false });
            this.getBatches();

        }).catch( ( error ) => {
            console.log( 'deleteBatches ERROR', error );
            this.setState({ 
                loading: false, 
                error: keyExists( error, "response.data.message", true, "Odottamaton virhe" ) || "VIRHE"
            });
        });  
    }

    handleDetailChange( e )
    {
        let details = { ...this.state.details };
        details[ e.target.name ] = e.target.value;
        this.setState({ details });
    }

    toggleDetailSwitch( e )
    {
        const currentValue = keyExists( this.state, "details.confirmed_at", true );

        let details = { ...this.state.details };
        details[ "confirmed_at" ] = !currentValue;
        this.setState({ details });
    }

    openDetails( id )
    {
        const details = this.state.batches.find( b => b.id == id );
        if( details )
        {
            this.setState({ 
                detailsOpen: true,
                details: details,
            });

            this.props.history.replace( '/timetracking/payroll/batches/' + id ); 
        }
        else 
            console.log('WARNING: batch ID (' + id + ') not found!' )
    }

    closeModal()
    {
        this.setState({ detailsOpen: false });
    }

    saveDetails()
    {
        this.confirmBatches({
            ids: [ this.state.details.id ],
            confirm: this.state.details.confirmed_at,
            payment_date: dateInputToSql( this.state.details.payment_date ),
        }, () => {
            this.getBatches();
            this.closeModal();
        });       
    }

    render() 
    {
         return (
            <div id="pageTimetrackingPayroll">
                <div className="pageBackButton" onClick={ () => window.emitter.emit( 'goTo', { pathname: '/timetracking/payroll' } ) }>
                    <SvgIcon icon="arrow-left" type="solid" size="small" />
                    { tr('payroll') }
                </div>
                <div className="apBox">
                    
                    <div className="apBoxHeader">
                        <h1>{ tr('payroll_batches') }</h1>
                        <p>{ tr('payroll_batches_info') }</p>
                    </div>
                        
                    <div className="padding">

                        <ApReactTable 
                            columns={[
                                {
                                    Header: tr('name'),
                                    accessor: 'name',
                                    width: 200,
                                },{
                                    Header: tr('format'),
                                    accessor: 'format',
                                    customizable: true,
                                },{
                                    Header: tr('salary_rows'),
                                    accessor: 'rows',
                                    customizable: true,
                                },{
                                    Header: tr('created_at'),
                                    accessor: 'created_at',
                                    Cell: ( row ) => timeSince( row.original.created_at ),
                                },{
                                    Header: tr('entries_included'),
                                    customizable: true,
                                    columns: [
                                        {
                                            id: "users",
                                            Header: tr('persons'),
                                            customizable: true,
                                            Cell: ( row ) => {
                                                // Get unique users from entries
                                                return [ ...new Set( row.original.entries.map( r => r.entry.user_id ) ) ].length;
                                            }
                                        },{
                                            id: "date_start",
                                            Header: tr('from'),
                                            customizable: true,
                                            Cell: ( row ) => {
                                                let dates = row.original.entries.map( r => r.entry.date ).sort( ( a, b ) => {
                                                    return moment( a, "YYYY-MM-DD" ).diff( moment( b, "YYYY-MM-DD" ) );
                                                });
                                                return moment( dates[0], "YYYY-MM-DD" ).format( "DD.MM.YYYY" );
                                            }
                                        },{
                                            id: "date_end",
                                            Header: tr('ending'),
                                            customizable: true,
                                            Cell: ( row ) => {
                                                let dates = row.original.entries.map( r => r.entry.date ).sort( ( a, b ) => {
                                                    return moment( b, "YYYY-MM-DD" ).diff( moment( a, "YYYY-MM-DD" ) );
                                                });
                                                return moment( dates[0], "YYYY-MM-DD" ).format( "DD.MM.YYYY" );
                                            }
                                        },{
                                            id: "entries",
                                            Header: tr('amount'),
                                            customizable: true,
                                            Cell: ( row ) => {
                                                return row.original.entries.length;
                                            }
                                        }
                                    ]
                                },{
                                    Header: tr('transfer_successful'),
                                    accessor: 'confirmed_at',
                                    Cell: ( row ) => {
                                        const label = ( row.original.confirmed_at ? timeSince( row.original.confirmed_at ) : false );
                                        return (
                                            <div className={ "apStatusBox" + ( label ? " success" : "" ) }>{ label || "Ei merkintää" }</div>
                                        );
                                    }
                                }
                            ]}
                            data={ this.state.batches || [] }
                            loading={ this.state.loading }
                            minRows={ 10 }
                            multiselect={[
                                { 
                                    icon: "check", 
                                    label: tr('mark_successful'), 
                                    action: ( indexes ) => this.markAsConfirmed( indexes ), 
                                    closeAfter: true,
                                    unselectAfter: true,
                                },
                                { 
                                    icon: "times", 
                                    label: tr('undo_success_mark_up'), 
                                    action: ( indexes ) => this.markAsConfirmed( indexes, false ), 
                                    closeAfter: true,
                                    unselectAfter: true,
                                },
                                { 
                                    divider: true 
                                },
                                { 
                                    icon: "trash-alt", 
                                    label: tr('delete_selected'), 
                                    action: ( indexes ) => this.confirmDelete( indexes ), 
                                    closeAfter: true, 
                                    unselectAfter: true,
                                },
                            ]}
                            rowActions={ (row) => 
                                <div className="apSimpleButton" onClick={ () => this.openDetails( row.id ) }><SvgIcon icon="arrow-right" type="solid" /></div>
                            }
                        />
                    
                    </div>

                </div>

                <ApModal
                    className="editModal narrow"
                    show={ this.state.detailsOpen }
                    handleClose={ this.closeModal }
                    closeFromBg
                    header={ 
                        <div className="padding">
                            <h3>{ tr('payroll_batch_info') }</h3>
                        </div>
                    }
                    body={ 
                        <div className="padding">
                            
                            <h5>{ keyExists( this.state, "details.name", true, tr('anonymous') ) }</h5>
                            <p>{ tr('payroll_batch_created') } { moment( keyExists( this.state, "details.created_at", true ) ).locale(currentLang()).format('LLL') }</p>

                            <div className={"apFormGroup activeSwitch" + ( keyExists( this.state, "details.confirmed_at", true ) ? " success" : "" ) }>
                                <div className="apSwitchBlock">
                                    <label htmlFor="payroll-batch-is-confirmed-switch" className="info">
                                        { tr('transfer_to_payroll_system_successful') }
                                        <small>{ tr('select_this_external_payroll') }</small>
                                    </label>
                                    <ApSwitch
                                        id="payroll-batch-is-confirmed-switch"
                                        on={ keyExists( this.state, "details.confirmed_at", true ) }
                                        onChange={ this.toggleDetailSwitch }
                                    />
                                </div>
                                <Collapse in={ keyExists( this.state, "details.confirmed_at", true ) }>
                                    <div>
                                        <hr />

                                        <ApInput 
                                            type="date" 
                                            id="payment_date" 
                                            name="payment_date" 
                                            label={ capitalize(tr('date_of_payment')) }
                                            value={ keyExists( this.state, "details.payment_date", true, '' ) }
                                            onChange={ this.handleDetailChange }
                                            validationState={ validateDate( keyExists( this.state, "details.payment_date", true, '' ), true ) ? "" : "error" }
                                            tooltip={ validateDate( keyExists( this.state, "details.payment_date", true, '' ), true ) ? "" : "Virheellinen päivämäärä" }
                                            clearable
                                        />

                                        <div className="apInfo small">
                                            <SvgIcon icon="info-circle" type="solid" />
                                            { tr('payroll_date_note') }
                                        </div>
                                    </div>
                                </Collapse>
                            </div>

                            <div className="file">

                                <SvgIcon icon="file-code" type="solid" />

                                <span>{ tr('transfer_file') }:</span><br />
                                <strong>{ keyExists( this.state, "details.file.original_name", true, tr('anonymous') ) }</strong><br />
                                <small>{ readableFileSize( keyExists( this.state, "details.file.size", true ) ) }</small>

                                <File fileId={ keyExists( this.state, "details.file_id", true ) }>
                                    <ApButton color="white" size="small">
                                        <SvgIcon icon="download" type="solid" />
                                        { tr('download_transfer_file') }
                                    </ApButton>
                                </File>

                            </div>

                            {tr('timetracking_entries_count')}: {keyExists(this.state, "details.entries_count", true, '') } kpl<br />
                            {tr('persons_amount')}: {keyExists(this.state, "details.user_count", true, '') } kpl<br />
                  
                            
                        </div>
                    }
                    footer={
                        <div className="padding">
                            
                            <ApButton className="createButton" color="blue" onClick={ this.saveDetails } disabled={ this.state.loading } loading={ this.state.loading }>
                                <SvgIcon icon="save" type="solid" />
                                { tr('save') }
                            </ApButton>

                            <ApButton className="cancelButton" onClick={ this.closeModal } disabled={ this.state.loading }>
                                <SvgIcon icon="times" type="solid" />
                                { tr('cancel') }
                            </ApButton>

                            { this.state.error && 
                                <div className="apErrorMsg">{ this.state.error }</div>
                            }

                        </div>
                    }
                />

                <ApConfirm
                    show={ this.state.confirmDeleteIds }
                    onClose={ () => this.setState({ confirmDeleteIds: false }) }
                    onConfirm={ this.deleteBatches }
                    header={ tr('confirm_delete') }
                    body={ 
                        <div>
                            <p>
                                <strong>{ tr('delete_sure') } { this.state.confirmDeleteIds.length === 1 ? ` ${tr('payroll_batch_one')}` : this.state.confirmDeleteIds.length + ` ${tr('payroll_batch_many')}` }?</strong>
                            </p>
                            <p>{ tr('delete_payroll_batch_note') }</p>
                        </div>
                    }
                />

            </div>
        );
    }
}
